import {getAxios} from './setAuthorizationToken';
import AppConfig from '../config';
import { FurnitureTypes } from './ActionType';

const API_BASE_URL = AppConfig.API_BASE_URL;


export const getFurnitureListing = (searchText, zipcode, groupid) => {
    return function(dispatch) {
        let url = `${API_BASE_URL}/api/CustomerPortal/GetMaterialList`;
        const params = [];
        if (searchText) {
            params.push(`SearchTxt=${searchText}`)
        }
        if (zipcode) {
            params.push(`ZipCode=${zipcode}`)
        }
        if (groupid) {
            params.push(`matgrp_code=${groupid}`)
        }
        if (params.length > 0) {
            url =  `${url}?${params.join('&')}`
        }
        return getAxios(dispatch).get(url)
            .then((res) => {
                if (res.data && res.data.materialList) {
                    dispatch({ type: FurnitureTypes.SET_FURNITURE_LIST, payload: res.data.materialList })
                } else {
                    dispatch({ type: FurnitureTypes.SET_FURNITURE_LIST, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', error: true, message: 'Getting Furniture List failed.' })
            });
    }
}

export const getOfferFurnitureListing = (zipcode) => {
    return function(dispatch) {
        let url = `${API_BASE_URL}/api/CustomerPortal/GetOfferMaterialList`;
        if (zipcode) {
            url = url + `?ZipCode=${zipcode}`
        }
        return getAxios(dispatch).get(url)
            .then((res) => {
                if (res.data && res.data.materialList) {
                    dispatch({ type: FurnitureTypes.SET_OFFER_FURNITURE_LIST, payload: res.data.materialList })
                } else {
                  dispatch({ type: FurnitureTypes.SET_OFFER_FURNITURE_LIST, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', error: true, message: 'Getting Furniture List failed.' })
            });
    }
}

export const getAlternateProducts = (matCode, quantity) => {
    return function(dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetAlternateMaterialList?mat_code=${matCode}&Qty=${quantity}`)
            .then((res) => {
                if (res.data && res.data.materialList) {
                    dispatch({ type: FurnitureTypes.SET_ALTERNATE_PRODUCTS, payload: res.data.materialList })
                } else {
                  dispatch({ type: FurnitureTypes.SET_ALTERNATE_PRODUCTS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', error: true, message: 'Getting associated items failed.' })
            });
    }
}

export const getMyInventory = () => {
    return function(dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetMaterialInventoryList`)
            .then((res) => {
                if (res.data && res.data.materialList) {
                    dispatch({ type: FurnitureTypes.SET_MY_INVENTORY, payload: res.data.materialList })
                } else {
                  dispatch({ type: FurnitureTypes.SET_MY_INVENTORY, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', error: true, message: 'Getting inventory failed.' })
            });
    }
}
export const manageCOOP = (obj) => {
    return function(dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/MyInventoryCOOP`,obj)
            .then((res) => {
                if (res.data && res.data.id) {
                    dispatch(getMyInventory());
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', error: true, message: 'Getting inventory failed.' })
            });
    }
}
export const getFurnitureSubGroups = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetSubGroupList`)
            .then((res) => {
                if (res.data && res.data.subGroupList) {
                    dispatch({ type: FurnitureTypes.SET_FURNITURE_SUB_GROUPS, payload: res.data.subGroupList })
                } else {
                    dispatch({ type: FurnitureTypes.SET_FURNITURE_SUB_GROUPS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', error: true, message: 'Getting Furniture Subgroups failed.' })
            });
    }
}


export const GetRecommendMaterialInfo = (itemid) => {
    return getAxios().get(`${API_BASE_URL}/api/CustomerPortal/GetRecommendMaterialInfo?matCode=${itemid}`)
            .then((res) => {
                if (res.data && res.data.materialList) {
                    return res.data.materialList;
                } else {
                    return undefined;
                }
            })
            .catch((err) => {
                return undefined;
            });
}

export const getFurnitureInfo = (itemid) => {
    return getAxios().get(`${API_BASE_URL}/api/CustomerPortal/GetMaterialInfo?mat_code=${itemid}`)
            .then((res) => {
                if (res.data && res.data.materialList) {
                    return res.data.materialList;
                } else {
                    return undefined;
                }
            })
            .catch((err) => {
                return undefined;
            });
}

export const getFurnitureGroups = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetGroupList`)
            .then((res) => {
                if (res.data && res.data.groupList) {
                    dispatch({ type: FurnitureTypes.SET_FURNITURE_GROUPS, payload: res.data.groupList })
                } else {
                    dispatch({ type: FurnitureTypes.SET_FURNITURE_GROUPS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', error: true, message: 'Getting Furniture groups failed.' })
            });
    }
}

export const setProductAvailability = (data) => {
    return function (dispatch) {
        return dispatch({ type: FurnitureTypes.SET_AVAILABLE_TYPE, payload: data })
    }
}

export const resetAllFilters = () => {
    return function (dispatch) {
        return dispatch({ type: FurnitureTypes.RESET_FILTERS })
    }
}

export const addSubGroupFilter = (data) => {
    return function (dispatch) {
        return dispatch({ type: FurnitureTypes.ADD_SUB_GROUP, payload: data })
    }
}