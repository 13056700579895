import React from 'react';
import './Header.scss';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, BackTop } from 'antd';
import './Footer.scss'
import { connect } from 'react-redux';
import ModalBox from '../Components/ModalBox/ModalBox';
import TermsandCondition from '../Screens/TermsandCondition/TermsandCondition';
import { getFurnitureListing, getFurnitureSubGroups, getFurnitureGroups } from '../Actions/FurnitureActions';

class Footer extends React.Component {

    constructor() {
        super();
        this.state = {
            termsandcondition: false,
        };
    };

    onCancel = () => {
        this.setState({ termsandcondition: false });
    };

    componentDidMount() {
        this.props.dispatch(getFurnitureGroups());
    };

    getProductDetails = (id, name) => {
        this.props.dispatch(getFurnitureSubGroups());
        this.props.dispatch(getFurnitureListing(null, this.props.userLocation ? this.props.userLocation.ziP_CODE : null, id));
        this.props.history.push(`/furniture-listing/room/${id}/${name}`);
        window.scrollTo(0, 0);
    };

    render() {

        const { furnitureGroups } = this.props;

        return (
            <React.Fragment>

                <div className="footer">
                    <div className="container mx-auto">
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="social-media">
                                    <h3>Follow Us</h3>
                                    <ul>
                                        <li><a target="_blank" href="https://www.facebook.com/StagingDepotCharlotte"><i className="flaticon-facebook-3" /></a></li>
                                        <li><Link to="/"><i className="flaticon-instagram" /></Link></li>
                                        <li><Link to="/"><i className="flaticon-twitter" /></Link></li>
                                        <li><Link to="/"><i className="flaticon-youtube-1" /></Link></li>
                                    </ul>
                                </div>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="quick-links">
                                    <h3>Rent</h3>
                                    <ul style={{ display: 'inline-block' }}>
                                        {/* <li><Link to="/furniture">Furniture</Link></li>
                                        <li><Link to="/bundles">Bundles</Link></li>
                                        <li><Link>Living Room</Link></li>
                                        <li><Link>Bed Room</Link></li>
                                        <li><Link>Dining Room</Link></li>
                                        <li><Link>Kid’s Room</Link></li>
                                        <li><Link>Kitchen</Link></li> */}

                                        {
                                            furnitureGroups && furnitureGroups.filter(x => x.grouP_ID === "1").map((group, index) =>
                                                <li key={`grps-${index}`} onClick={() => this.getProductDetails(group.matgrouP_CODE, group.description)} style={{ cursor: 'pointer', marginBottom: 10, fontSize: 15 }}>{group.description}</li>
                                            )}
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="quick-links">
                                    <h3>Help</h3>
                                    <ul>
                                        <li><Link to="/home#how-it-works">How it works</Link></li>
                                        <li><Link to="/home#testimonials">Testimonials</Link></li>
                                        <li><Link to="/FAQs">FAQ’s</Link></li>
                                        <li><Link to="/return-policy">Return Policy</Link></li>
                                        <li><Link to="/ContactUs">Contact us</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="quick-links">
                                    <h3>About</h3>
                                    <ul>
                                        <li><Link to="/about-us">About us</Link></li>
                                        <li><Link to="/Career">Careers</Link></li>
                                        <li><Link to="/terms-and-conditions">Terms&nbsp;&&nbsp;Conditions</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col className="copyright-text" style={{ textAlign: 'center' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>© 2019-{(new Date()).getFullYear()} Staging Depot, LLC. All Rights Reserved.</Col>
                        </Row>
                    </div>
                </div>
                <BackTop />
            </React.Fragment>
        );
    };
};

function mapStateToProps(state) {
    return {
        userLocation: state.users.userLocation,
        furnitureGroups: state.furniture.furnitureGroups
    };
};

export default connect(mapStateToProps)(withRouter(Footer));