import { Button, Col, Row } from 'antd';
import './SigninSignup.scss';
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'


export default function NewTestimonial(props) {
    const [convertedText, setConvertedText] = useState("");
    return (
        <React.Fragment>
            <div className="signin-singup">
                <Row gutter={[12, 20]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <h1 style={{ color: '#522e6c'}}>Write your feedback here</h1>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <ReactQuill
                            theme='snow'
                            value={convertedText}
                            onChange={setConvertedText}
                            style={{minHeight: '400px'}}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="action-button" style={{ textAlign: 'right' }}>
                            <Button type="secondary" onClick={() => props.handleClose()}>Cancel</Button>
                            <Button type="primary" disabled={!convertedText} onClick={() => props.handleSave(convertedText)}>Save</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}