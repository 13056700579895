import axios from 'axios';

let lastHitTime = (new Date()).getTime();

let counter = 0;
let BearerToken = '';
export function setAuthorizationToken(token) {
  if (token) {
    BearerToken =  token;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
  if (!counter)
  activateRefreshToken();
}

export function getAxios(dispatch) {
  const context = sessionStorage.getItem('itop-user-context');
  const currentTime = (new Date()).getTime();
  const timeDiff = currentTime - lastHitTime;
  if (timeDiff > 5 * 60 * 60 * 1000) {
    window.location.reload();
  }
  lastHitTime = currentTime;
  axios.interceptors.response.use(response => {
      return response;
  }, error => {
    if (error.response.status === 401) {
      if(dispatch) {
        dispatch({ type: 'SESSION_TIMEOUT', payload: true })
      }
    }
    return error;
  });
  if (context) {
    const jsonContext = JSON.parse(context);
    axios.defaults.headers.common.Authorization = `Bearer ${jsonContext.token}`;
    return axios;
  } else {
    return axios;
  }
}

function activateRefreshToken() {
  ++counter;
  if (sessionStorage.getItem('itop-user-context')) {
    setInterval(() => {
      const context = sessionStorage.getItem('itop-user-context') ? JSON.parse(sessionStorage.getItem('itop-user-context')) : undefined;
      if (context) {
        axios.post("https://stagingdepot-securityapi-qa.impacttopline.com/api/auth/refreshtoken", { token: context.token, refreshToken: context.refreshToken })
        .then(response => {
          BearerToken = response.data.token;
          axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
          sessionStorage.setItem('itop-user-context', JSON.stringify(response.data));
        })
        .catch(error => {
          // console.log(error);
          // delete axios.defaults.headers.common.Authorization;
          // sessionStorage.removeItem('itop-user-context');
          // sessionStorage.removeItem('itop-profile');
          //window.location.reload();
        })
      }
    }, 1000*60);
  }
}

export default axios;