import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

class SiderMenu extends React.Component {

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <div className="cart-side-navigation">
                    <ul>
                        <li>
                            <NavLink to="/live-orders" exact activeClassName="active">Live Orders</NavLink>
                        </li>
                        <li>
                            <NavLink to="/undelivered-orders" exact activeClassName="active">Undelivered Orders</NavLink>
                        </li>
                        <li>
                            <NavLink to="/order-history" exact activeClassName="active">Order History</NavLink>
                        </li>
                        <li>
                            <NavLink to="/open-orders" exact activeClassName="active">Draft Orders</NavLink>
                        </li>
                        <li>
                            <NavLink to="/my-inventory" exact activeClassName="active">My Inventory</NavLink>
                        </li>
                    </ul>
                </div>

            </React.Fragment >
        )
    }
}
export default withRouter(SiderMenu)