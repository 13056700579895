import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import './PopularProducts.scss';
import { Row, Col, Button } from 'antd';
import Slider from "react-slick";
import FurnitureItem from '../../../Components/Furniture/FurnitureItem';

const Productslidersettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                centerMode: false,
                slidesToShow: 3
            }
        },
        {
            breakpoint: 600,
            settings: {
                centerMode: false,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

class PopularProducts extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Row align="center">
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 18 }} xl={18} xxl={18}>
                <section className="popular-products">
                    <div className="section-title">
                        <h1>Popular Products</h1>
                    </div>

                    <div className="popular-products-container">
                        <Row align="center">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                <div className="caption">
                                    <p>Check out some popular items, or go to the rental page to shop by room or item type.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 20 }}>
                                <div className="view-all">
                                    {/* <Link onClick={() => this.props.history.push('/recently-viewed')}>View All</Link> */}
                                </div>
                                <div className="popularity-slider">
                                    {/* <!--Slider Item starts--> */}
                                    <Slider {...Productslidersettings}>
                                        {
                                            this.props.products.map(product => {
                                                return <FurnitureItem item={product} />
                                            })
                                        }
                                    </Slider>
                                    {/* <!--Slider Item ends--> */}
                                </div>

                            </Col>
                        </Row>
                    </div>
                </section>
                </Col>
                </Row>
            </React.Fragment>
        )
    }
}
export default withRouter(PopularProducts)