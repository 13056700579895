import React from 'react';

import { Provider } from 'react-redux';
import store from './store/store';

import Routes from './Routes/Routes';
import 'antd/dist/antd.css';
import './Style/global.scss';
import './Style/responsive.scss';
import './Style/slick.min.css';
import './Style/slick-theme.min.css';
import './assets/flaticon/flaticon.css'
export default class App extends React.Component {
  componentDidMount() {
    window.addEventListener('popstate', function (event) {
      window.scrollTo(0,0);
    });
  }
  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}