import React from 'react';
import { withRouter } from 'react-router';
import './Testimonials.scss';
import Slider from "react-slick";

const testimonalsettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

class Testimonials extends React.Component {

    render() {
        return (
            <React.Fragment>
                <section className="testimonials">
                    <div className="section-title">
                        <h1>Testimonials</h1>
                    </div>

                    <div className="testimonials-container">
                        <div className="testimonials-list">
                            <Slider {...testimonalsettings}>
                                {
                                    this.props.testimonials.map(item => 
                                        <div className="list">
                                            <div className="details">
                                                <div className="profile">
                                                    {/* <div className="image">
                                                        <img src={item.imagepath ? item.imagepath : "http://assets.stickpng.com/images/585e4bcdcb11b227491c3396.png"} alt="Sandra Rubio" />
                                                    </div> */}
                                                    <div className="profile-details">
                                                        <h4>{item.namE1}</h4>
                                                        {/* <p>Custom casa home stager </p> */}
                                                    </div>
                                                </div>
                                                <div className="customer-message">
                                                    <p dangerouslySetInnerHTML={{__html: item.tesT_DESC }}></p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>
                    </div>
                </section >
            </React.Fragment >
        )
    }
}
export default withRouter(Testimonials)