import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import './Listings.scss';
import { connect } from 'react-redux';
import { Row, Col, Image, Button } from 'antd';
import { updateItemsInWishList , updateRecentViewedDetails } from '../../../Actions/WishListActions'

class BundlesListing extends React.Component {

    addToWishList = () => {
        const model = {
            "matCode": this.props.item.maT_CODE,
        }

        this.props.dispatch(updateItemsInWishList(model))
    }

    handleOpenViewDetails = (item) => {
        this.props.history.push(`/bundles-detailed-page/${item.maT_CODE}/${item.maT_DESC}`)
        let model = {
            "matCode": item.maT_CODE
        }
        this.props.dispatch(updateRecentViewedDetails(model));
    }

    render() {

        const { allFilters } = this.props;
        return (
            <React.Fragment>
                <div className="property-listing">
                    <Row gutter={[12, 20]} className="property-details">
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <img src={this.props.item.imagePath ? this.props.item.imagePath : require('../../../assets/images/bundles/property-listings/furnished-room.webp')} alt={this.props.item.maT_DESC} />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <div className="heading">
                            <h3>{this.props.item.maT_DESC}</h3>
                            {
                                !this.props.item.showavailabilty && <span style={{ color: 'red'}}>Out of stock</span>
                            }
                            <p>{this.props.item.remark1}</p>
                            <Link className="make-favourite" onClick={this.addToWishList}><i className="fa fa-heart-o" /></Link>
                            </div>
                            <div className="gallery-images">
                                {
                                    this.props.item.bundleBOM && this.props.item.bundleBOM.map(mat => {
                                        return <Image src={mat.imagePath} alt={mat.maT_CODE} />  
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    {/* property details ends */}
                    <Row className="pricing-details">
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <div className="rent-details">Rent <span>: $ {this.props.item.monthlyrent}/mon</span></div>
                        </Col>
                        {/* <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <div className="shipping"><i class="fa fa-truck" /> ships in 4 days</div>
                        </Col> */}
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            {/* <Button type="secondary" onClick={() => this.props.history.push(`/bundles-detailed-page/${this.props.item.maT_CODE}/${this.props.item.maT_DESC}`) } >View Details</Button> */}
                            {/* <Button type="primary">Add To Cart</Button> */}
                            <Button type="secondary" onClick={() => this.handleOpenViewDetails(this.props.item)}>View Details</Button>
                        </Col>
                    </Row>
                </div>
            </React.Fragment >
        )
    }
}
// export default withRouter(BundlesListing);

function mapStateToProps(state) {
    return {
        allFilters: state.bundles
    }
}
export default connect(mapStateToProps)(withRouter(BundlesListing));