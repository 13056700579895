import { LoadingOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Tabs } from "antd";
import React from "react";
import { withRouter } from "react-router";
import InputBox from "../../Components/InputBox/InputBox";
import "../FurnitureListing/QuickView/QuickView.scss";
const { TabPane } = Tabs;

class SaleAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      verifyMe: props.customerInfo.isVerified ? false : true,
      companyName: '',
      companyAddress: '',
      contactName: '',
      email: '',
      mobile: '',
      realtorId: '',
      website: ''
    };
  }
  handleContinue = () => {
    this.props.placeOrder()
  }
  handleVerify = () => {
    const { 
      companyName,
      companyAddress,
      contactName,
      email,
      mobile,
      realtorId,
      website
    } = this.state;
    this.props.verifyUser({
      companyName,
      companyAddress,
      contactName,
      email,
      mobile,
      realtorId,
      website
    });
  }
  onChangeText = (value, type) => {
    this.setState({ [type]: value })
  }
  render() {
    return (
      <React.Fragment>
        <div className="quick-view-furniture">
          <div className="title">
            <h2>RENTAL AGREEMENT</h2>
            <div
              style={{
                height: "100%",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "3%",
              }}
            >
              <div
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "2%",
                }}
              >
                <p>Today’s Date: {new Date().toLocaleDateString()}</p>
                <h3>LESSEE INFORMATION:</h3>
                { this.props.customerDetails && 
                  <div className="list">
                    <div className="label"><h4>Name</h4></div>
                    <div className="content"><p>{this.props.tradeInfo.name1}</p></div>
                  </div>
                }
                { this.props.tradeInfo && this.props.customerInfo.isVerified && 
                  <div>
                      <div className="list">
                        <div className="label"><h4>Company Name</h4></div>
                        <div className="content"><p>{this.props.tradeInfo.companyName}</p></div>
                      </div>
                      <div className="list">
                          <div className="label"><h4>Company Address</h4></div>
                          <div className="content" style={{ verticalAlign: 'text-top', fontSize: 'small', padding: '1%', border: '1px solid #ccc' }}>
                              { this.props.tradeInfo.companyAddress1 && <div>{this.props.tradeInfo.companyAddress1}</div> }
                              { this.props.tradeInfo.companyAddress2 && <div>{this.props.tradeInfo.companyAddress2}</div> }
                              { <div>{this.props.tradeInfo.city}, {this.props.tradeInfo.regioN_STATE}&nbsp;{this.props.tradeInfo.pincode}</div> }
                          </div>
                      </div>
                    </div> 
                  }
                {
                  this.props.selectedAddress && 
                  <div className="list">
                    <div className="label"><h4>Staging Address</h4></div>
                    <div className="content" style={{ verticalAlign: 'text-top', fontSize: 'small', padding: '1%', border: '1px solid #ccc' }}>
                        { this.props.selectedAddress.address && <div>{this.props.selectedAddress.address}</div> }
                        { this.props.selectedAddress.finaL_ADDRESS && <div>{this.props.selectedAddress.finaL_ADDRESS}</div> }
                        { <div>{this.props.selectedAddress.city},&nbsp;{this.props.selectedAddress.regioN_STATE}&nbsp;{this.props.selectedAddress.pincode}</div> }
                    </div>
                  </div>
                }
                {
                  !this.props.selectedAddress && 
                  <div className="list">
                    <div className="label"><h4>Staging Type</h4></div>
                    <label>Self Pickup</label>
                  </div>
                }
                <br />
                <div className="list">
                  <div className="label"><h4>Delivery Date & Time</h4></div>
                  <div className="content"><p>{this.props.deliveryDate}</p></div>
                </div>
                <Divider />
                <p>
                  This Rental Agreement (this “Agreement”) is made as of the
                  Effective Date between Staging Depot, LLC (the “Lessor”) and
                  the Lessee. In consideration of the mutual covenants contained
                  herein and other good and valuable consideration, the receipt
                  and sufficiency of which is hereby acknowledged, the parties
                  agree as follows:
                </p>
                <ol>
                  <li>
                    Agreement. The Lessor agrees to lease to the Lessee, and the
                    Lessee agrees to lease from the Lessor, all furniture,
                    accessory items, and fees set out in invoices (the “Leased
                    Items”) between the Lessor and the Lessee from time to time
                    (the “Invoices”) subject to the terms and conditions set out
                    in this Agreement.
                  </li>
                  <li>
                    Term of Lease. The term of the lease between the Lessor and
                    Lessee of the Leased Items shall commence on the Delivery
                    Date and continue for a period of 1 month from the Delivery
                    Date (the “Initial Term”) and thereafter, shall
                    automatically renew for successive 1 month periods (each, a
                    “Renewal Term”) unless the Lessee provides the Lessor with
                    notice of non-renewal at least 3 business days prior to the
                    end of the then current term or unless this Agreement is
                    terminated earlier in accordance with the provisions hereof.
                    The Lessor shall issue an invoice to the Lessee for each
                    Renewal Term.
                  </li>
                  <li>
                    Payment. The Lessee agrees to pay to the Lessor all amounts
                    due and payable to the Lessor under this Agreement plus all
                    applicable taxes including, without limitation, (i) all
                    rental and other amounts set out in each Invoice
                    (collectively, the “Rental Amounts”), and (ii) all charges
                    and fees set out in this Agreement. The Rental Amount for
                    the Initial Term shall be due and payable to the Lessor on
                    the Effective Date (when the Order is booked on the Staging
                    Depot website) and the Rental Amounts for each Renewal Term
                    shall be due on the first day of each Renewal Term Any
                    additional charges and fees payable by the Lessee to the
                    Lessor pursuant to this Agreement shall be due upon receipt
                    of invoice. Except as otherwise provided in this Agreement,
                    all amounts paid to the Lessor under this Agreement are
                    non-refundable.
                  </li>
                  <li>
                    Payment Methods and Authorization. The Lessee hereby
                    authorizes the Lessor and/or its employees, officers and
                    agents to charge or deposit, as applicable, all amounts due
                    and payable to the Lessor under this Agreement plus all
                    applicable taxes to the Credit Card, all other credit or
                    debit cards, and/or payment methods provided by the Lessee
                    to the Lessor from time to time. This Section 5 shall
                    survive and not merge with the expiration or termination of
                    this Agreement. The Lessee shall be charged a $35.00
                    administrative fee for each check deposited by the Lessor
                    that returns as non-sufficient funds. The Lessee shall
                    either provide the Lessor with a replacement check in the
                    same amount within 1 business day of notice from the Lessor
                    of the returned non-sufficient funds check or provide the
                    Lessor with an alternate payment method.
                  </li>
                  <li>
                    Termination. The Lessee may terminate this Agreement by
                    providing the Lessor with prior written notice as follows:
                    <ul>
                      <li>
                        The Lessee may terminate this Agreement if the Lessee
                        provides the Lessor with written notice of termination
                        at least 5 business days prior to the Delivery Date. If
                        the Lessee terminates this Agreement as provided for in
                        this subsection (a), then all amounts paid by the Lessee
                        to the Lessor pursuant to this Agreement shall be
                        returned.
                      </li>
                      <li>
                        The Lessee may terminate this Agreement if the Lessee
                        provides the Lessor with written notice of termination
                        at least 3 to 4 business days prior to the Delivery
                        Date. If the Lessee terminates this Agreement in
                        accordance with this subsection (b), then the Lessee
                        shall be charged 20% of the Rental Amount for the
                        Initial Term plus applicable taxes.
                      </li>
                      <li>
                        The Lessee may terminate this Agreement if the Lessee
                        provides the Lessor with written notice of termination
                        at least 1 to 2 business days prior to the Delivery
                        Date. If the Lessee terminates this Agreement in
                        accordance with this subsection (c), then the Lessee
                        shall be charged 50% of the Rental Amount for the
                        Initial Term plus applicable taxes.
                      </li>
                      <li>
                        The Lessee shall not be entitled to any refund,
                        discount, or abatement of any sort if the Lessee
                        terminates this Agreement prior to the completion of the
                        Initial Term or any Renewal Term unless the Lessor in
                        its sole discretion agrees otherwise.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Delivery. The Lessor shall use its reasonable commercial
                    efforts to deliver the Leased Items to the Delivery Location
                    by the Delivery Date. The Lessee shall ensure that the
                    Lessor has access to the Delivery Location in order to
                    deliver the Leased Items. The Lessee must notify the Lessor
                    of any parking restrictions or special delivery instructions
                    at the Delivery Location prior to the Delivery Date. Any
                    parking tickets received by the Lessor as a result of the
                    Lessee’s failure to notify the Lessor of any such parking or
                    special delivery instructions shall be charged to the
                    Lessee. If Lessee or representative is not on premises with
                    access to staging location to provide direction on placement
                    of furniture at the designated time on day of delivery, the
                    rental period will still begin and Lessee will pay a $350
                    redelivery fee.
                  </li>
                  <li>
                    Inspection. The Lessee shall within 1 calendar day from the
                    receipt thereof inspect the Leased Items delivered. Unless
                    the Lessee within such period gives written notice to the
                    Lessor specifying any defect in or other proper objection to
                    the Leased Items, the Lessee agrees that it shall be
                    conclusively presumed as between the Lessor and the Lessee,
                    that the Lessee has fully inspected the Leased Items and
                    acknowledged that the Leased Items are in full compliance
                    with the terms of this Agreement, in good and proper
                    condition and repair, and that the Lessee is satisfied with
                    and has accepted the Leased Items in such condition and
                    repair. Upon reasonable notice to the Lessee, the Lessor
                    shall be entitled to access to the location of the Leased
                    Items in order to inspect the Leased Items.
                  </li>
                  <li>
                    Maintenance of Leased Items. The Lessee shall not alter or
                    disfigure any of the Leased Items. The Lessee shall prevent
                    the furnishings from being subjected to careless, unusual,
                    inappropriate or improper usage and shall use the
                    furnishings only for the purpose identified.
                  </li>
                  <li>
                    Return of Leased Items. On the expiration or termination of
                    this Agreement for any reason whatsoever, the Lessee shall
                    have the Leased Items ready for the Lessor in as good
                    condition as when delivered subject only to ordinary wear
                    and tear. Subject to Section 17, the Lessee shall be
                    responsible for any and all damage caused to the Leased
                    Items as determined by the Lessee and for all costs and
                    expenses incurred by the Lessor with respect to cleaning,
                    repairing and, if necessary replacing, such Leased Items.
                    The Lessee agrees that the cost for replacing any Leased
                    Item shall be 10 times the monthly rental rate of the Leased
                    Item (the “Replacement Cost”). At least 3 business days
                    prior to the end of the then current term, the Lessee and
                    the Lessor shall agree on a date and time for the Lessor to
                    pick up the Leased Items from the Lessee. If Lessee is not
                    on premises with access to our inventory at the designated
                    time on day of pickup, Lessee will be responsible for a $250
                    rescheduling fee and must be rescheduled within 3 days or
                    Lessee will be responsible for replacement cost.
                  </li>
                  <li>
                    Restricted Activities
                    <ul>
                      <li>
                        Removal from Delivery Location. The Lessee shall not
                        remove any of the Leased Items from the Delivery
                        Location. If the Lessee removes any of the Leased Items
                        from the Delivery Location without the Lessor’s written
                        consent, then the Lessee shall be charged a transfer fee
                        of twice the Rental Amount. The Lessee shall be
                        responsible for any and all damage caused to such Leased
                        Items as determined by the Lessor including all costs
                        and expenses incurred by the Lessor with respect to
                        cleaning and/or repairing such Leased Items, and the
                        Replacement Cost of any Leased Item that cannot be
                        cleaned or repaired.
                      </li>
                      <li>
                        Smoking. The Lessee shall not allow any smoking at any
                        location where the Leased Items are situated. The Lessee
                        shall be responsible for any and all damage caused to
                        such Leased Items as determined by the Lessor including
                        all costs and expenses incurred by the Lessor with
                        respect to cleaning and/or repairing such Leased Items,
                        and the Replacement Cost of any Leased Item that cannot
                        be cleaned or repaired. Any liability coverage purchased
                        will not cover this.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Stains. During the Initial Term and all Renewal Terms, the
                    Lessee shall be charged a cleaning fee of $100 per Leased
                    Item that has been stained. Stains include but are not
                    limited to: smoke stains/smell, food, grease, liquids etc.
                    If a stain cannot be removed, it will fall under irreparable
                    damage.
                  </li>
                  <li>
                    Default and Remedies. Each of the following shall be
                    considered an “Event of Default”:
                    <ul>
                      <li>
                        The Lessee defaults in respect of any payment hereunder;
                      </li>
                      <li>
                        The Lessee defaults in the performance of any other
                        covenant in this Agreement and such default continues
                        for ten (10) days after written notice thereof by
                        Lessor;
                      </li>
                      <li>
                        The Lessee files a petition in bankruptcy or is adjudged
                        a bankrupt, or a petition in bankruptcy is filed against
                        the Lessee;
                      </li>
                      <li>
                        The Lessee becomes insolvent, takes advantage of
                        legislation for creditor relief, has a receiver or
                        receiver-manager appointed in relation to its assets; or
                      </li>
                      <li>
                        The Lessee discontinues the Lessee’s business. Upon the
                        occurrence of an Event of Default, the Lessor shall have
                        the right to exercise any one or more of the following
                        remedies:
                      </li>
                      <li>
                        To declare all Rental Amounts hereunder immediately due
                        and payable as to any or all Leased Items, without
                        notice or demand to the Lessee;
                      </li>
                      <li>
                        To sue for and recover all Rental Amounts, and any other
                        payments, fees and charges, then accrued or thereafter
                        accruing, with respect to any or all of the Leased
                        Items;
                      </li>
                      <li>
                        To take possession of any or all of the Leased Items
                        without demand or notice, or legal process. The Lessee
                        hereby waives any and all damages occasioned by such
                        taking of possession. Any such taking of possession
                        shall not constitute a termination of this Agreement as
                        to any or all of the Leased Items unless the Lessor
                        expressly so notifies the Lessee in writing;
                      </li>
                      <li>
                        To terminate this Agreement as to any or all of the
                        Leased Items; and
                      </li>
                      <li>To pursue any other remedy at law or in equity.</li>
                    </ul>
                    <p>
                      Despite any such repossession or any other action which
                      the Lessor may take, the Lessee shall be and remain liable
                      for the full performance of all obligations on the part of
                      the Lessee to be performed under this Agreement.
                    </p>
                  </li>
                  <li>
                    Indemnity. The Lessee hereby agrees to indemnify the Lessor
                    and its directors, officers, employees and authorized
                    representatives against, and hold all of such parties
                    harmless from and against, any and all claims, actions,
                    suits, proceedings, costs, expenses, damages and
                    liabilities, including legal fees, arising out of, in
                    connection with, or resulting from the Lessee’s use,
                    operation, control, possession, and storage of the Leased
                    Items including, without limitation, any negligence of the
                    Lessee whether by commission or omission and any liability
                    for injury (or death) to persons or property caused by the
                    Lessee’s use, operation, control, possession, and storage of
                    the Leased Items. This Section 14 shall survive and not
                    merge with the expiration or termination of this Agreement.
                  </li>
                  <li>
                    Risk of Loss. The Lessor shall not in any way be responsible
                    or liable for any loss or damage to property, material or
                    equipment belonging to the Lessee, or any agents, employees,
                    suppliers or family members, guests or visitors, of the
                    Lessee, during the Initial Term and all Renewal Terms,
                    unless the Lessee witnessed such an event and reports it to
                    the Lessor immediately.
                  </li>
                  <li>
                    Ownership. The Lessor shall at all times retain ownership
                    and title of the Leased items. Lessee shall not encumber,
                    pledge, or permit any lien to be placed on the Leased Items,
                    and the Lessee shall give the Lessor immediate notice in the
                    event that any of the Leased Items are levied upon or are
                    about to be become liable to or is threatened with seizure.
                    The Lessee shall indemnify the Lessor against all loss or
                    damage caused by any such action.
                  </li>
                  <li>
                    No subletting / Assignment. The Lessee may not sublease or
                    otherwise assign, transfer or convey any interest it may
                    have in or to the Leased Items. The Lessee may not assign
                    this agreement without the prior written consent of the
                    Lessor. The Lessor may assign this Agreement without notice
                    to the Lessee. Subject to the foregoing, this Agreement
                    extends to the benefit of, and is binding upon, the
                    successors, heirs, executors and administrators, as the case
                    may be, of the parties.
                  </li>
                  <li>
                    Remedies Cumulative. All remedies of the Lessor under this
                    Agreement are cumulative and may, to the extent permitted by
                    law, be exercised concurrently or separately, and the
                    exercise of any one remedy shall not be deemed to be an
                    election of such remedy or to preclude the exercise of any
                    other remedy. No failure on the part of the Lessor to
                    exercise and no delay on the Lessor’s part in exercising any
                    remedy or right, shall operate as a waiver thereof; nor
                    shall any single or partial exercise by the Lessor of any
                    remedy or right hereunder preclude any other or further
                    exercise thereof or the exercise of any other remedy or
                    right. If any term or provision of this Agreement is
                    determined to be invalid, it shall not affect the validity
                    and enforcement of all remaining terms and provisions of
                    this Agreement.
                  </li>
                  <li>
                    Release of Personal Information. Lessee hereby agrees to the
                    Lessor’s collection, use and disclosure of the Lessee’s
                    personal information including, without limitation, the
                    Lessee’s name, address, email address, phone number, credit
                    card and other financial information and the disclosure of
                    such personal information to third parties such as payment
                    service providers, delivery service providers, and such
                    other third parties that the Lessor determines, in its sole
                    discretion, are necessary to perform the Lessor’s
                    obligations hereunder and to exercise the Lessor’s rights
                    under this Agreement and at law.
                  </li>
                  <li>
                    Limitation of Liability. The Lessee agrees that in no event
                    shall the total liability of Lessor, its directors,
                    officers, employees, agents, contractors and/or suppliers
                    under this Agreement exceed the Rental Amounts received by
                    the Lessor from The Lessee.
                  </li>
                  <li>
                    Entire Agreement. This Agreement together with all Invoices
                    constitutes the entire agreement between the parties hereto
                    and supersedes all other understandings, agreements,
                    negotiations and discussions, written or oral, made by the
                    parties hereto with respect to the subject matter hereof.
                  </li>
                  <li>
                    Severability. If any provision of this Agreement is
                    determined by a court of competent jurisdiction to be
                    illegal, invalid or unenforceable, it will be ineffective
                    only to the extent of its illegality, invalidity or
                    unenforceability without affecting the validity or the
                    enforceability of the remaining provisions of this
                    Agreement.
                  </li>
                  <li>
                    Counterparts and Execution. This Agreement may be executed
                    in counterparts, each of which when so executed shall be
                    deemed to be an original, and such counterparts together
                    shall constitute one and the same agreement and,
                    notwithstanding their date of execution, shall be deemed to
                    be executed as of the date first written above. The
                    signature of any party hereto may be evidenced by
                    eSignature, a facsimile or by electronic transmission in
                    portable document format (PDF) or tagged image file format
                    (TIFF) of the execution page of this Agreement bearing such
                    signature (which shall be deemed an original for all
                    purposes hereunder).
                  </li>
                  <li>
                    Governing Law. This Agreement shall be governed by and
                    construed in accordance with the laws of the State of North
                    Carolina, without regard to conflict of laws principles.
                  </li>
                  <li>
                    Notices. Any notice or other communication required or
                    permitted to be given hereunder shall be in writing and
                    shall be delivered in person, transmitted by email or other
                    means of recorded electronic communication, or sent by
                    courier or registered mail, charges prepaid, addressed as
                    follows: 
                    <p><b>If to the Lessor:</b> 
                        <p>Staging Depot, LLC 305 Link Street Rockwell, NC 28138.</p>
                        <p>Attention: Manager</p>
                        <p>Email: brian@stagingdepot.com</p> 
                    </p>
                    <p><b>If to the Lessee:</b>
                        <p>Address: As shown on Page 1 of this Agreement</p>
                        <p>Email: As shown on Page 1 of this Agreement</p>
                    </p>
                  </li>
                  <li>
                    Attorney Fees. In any proceeding brought to enforce this
                    Agreement or to determine the rights of the Parties under
                    this Agreement, the prevailing Party shall be entitled to
                    collect, in addition to any judgment awarded by a court, all
                    costs and expenses incurred in connection with such a
                    lawsuit, including attorneys’ fees, costs and expenses of
                    any appeal of a judgment. The term “proceeding” shall mean
                    and include arbitration, administrative, bankruptcy and
                    judicial proceedings including appeals. IN WITNESS WHEREOF
                    this Agreement has been executed by the parties hereto as of
                    the date first written above. 
                    <h3>STAGING DEPOT, LLC </h3>
                    <h3>Name: Brian Touchton</h3> 
                    I have authority to bind the corporation. 
                    <br />
                    <br />
                    {this.props.tradeInfo && this.props.customerInfo.isVerified && <h3>Company Name:{" "}{this.props.tradeInfo.companyName}</h3>}
                    <h3>Name: {this.props.tradeInfo.name1}</h3>
                    If a corporation I have authority to bind the corporation.
                  </li>{" "}
                </ol>{" "}
              </div>
              <Divider />
              <div className="card-container">
              <div>
                    <label>
                      By clicking on the 'I Agree' checkbox, you confirm that you
                      understand and accept our lease terms
                    </label>
                    <br />
                    <label>
                      <Checkbox
                        checked={this.state.checked}
                        onChange={(e) => {
                          this.setState({ checked: e.target.checked });
                        }}
                      />
                      &nbsp;I Agree
                    </label>
                    <div>
                      {
                        this.props.selectedAddress === undefined ? 
                        <div style={{ color : 'red', display : 'flex', justifyContent : 'center'}}>Delivery address not selected. Please select and proceed. </div>
                        : 
                        ""
                      }
                    </div>
                  <div className="action-button">
                    {
                      this.props.placingOrder ? 
                        <LoadingOutlined />
                      :
                      <Button
                        disabled={!this.state.checked || (this.props.selectedAddress === undefined)}
                        onClick={() => this.handleContinue()}
                        type="primary"
                      >
                        Proceed to checkout
                      </Button> 
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SaleAgreement);
