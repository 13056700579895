import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import QuickLinks from '../QuickLinks/QuickLinks';

class Culture extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/* page banners */}
                <section className="about-banners">
                    <img src={require('../../assets/images/page-banners/banner.png')} alt="About Us" />
                </section>
                {/* page banners */}

                <div className="inner-pages padding-40 top-left-wave light-bg">
                    <div className="container mx-auto">

                        <QuickLinks />

                        <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                <h3>Our Culture</h3>
                                <p>We are team of folks excited to help impove quality of life for  the Staging and Design community.  We've got plenty of innovative ideas on how automation and technology can be used to improve the Home Staging & Design industry.</p>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <img src={require('../../assets/images/about-us.webp')} alt="About Us" />
                            </Col>
                        </Row>
                    </div>
                </div>


            </React.Fragment >
        )
    }
}
export default withRouter(Culture)