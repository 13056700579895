import { Button, Col, Divider, Popconfirm, Row, Form, DatePicker, TimePicker } from 'antd';
import { DownOutlined, DownSquareOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons'
import React from 'react';
import { connect } from 'react-redux';
import { cancelOrder, getDeliveryDates, getOrderItems, getPickupDates, returnOrder, getPickupDatesWithZip } from '../../Actions/OrderActions';
import OrderItemView from '../MyCart/OrderSummary/OrderItemView';
import { CloseOutlined } from '@ant-design/icons';
import ModalBox from "../../Components/ModalBox/ModalBox";
import moment from "moment";
import '../MyCart/MyCart.scss';
// import Form from 'rc-field-form/es/Form';

class OrderCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            orderItems: [],
            orderIdList: [],
            showDropOffModal: false,
            date: '',
            time: '',
            dropOff: false,
            dates: [],
            selectedDate: '',
            preferredTiming: '',
            detailsLoading: false,
            paymentInfo: [],
            costDetails: []
        }
    }
    getOrderItems = (orderId) => {
        this.setState({ orderIdList: [...this.state.orderIdList, orderId] })
        this.setState({ detailsLoading: true })
        this.props.dispatch(getOrderItems(orderId)).then(order => {
            if (order)
                this.setState({ orderItems: order.orderHistory, paymentInfo: order.paymentInfo, costDetails: order.costDetails });
            else
                this.setState({ orderIdList: [] });
            this.setState({ detailsLoading: false })
        });
    };

    confirm = (id) => {
        this.props.dispatch(cancelOrder(id));
    };

    hideProductDetails = (id) => {
        this.setState({
            orderItems: this.state.orderItems.filter(item => item.ordeR_NO != id)
        });
        this.setState({ orderIdList: [] });
    };

    getFormattedDate = (date) => {
        const dateArr = date ? date.split('-') : ["1970", "01", "01"]
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
    }

    handleProductReturn = async (dropOff) => {
        const dates = dropOff ? await getPickupDates(this.props.order.pincode) : await getDeliveryDates(this.props.order.pincode)
        if (dates) {
            this.setState({
                dates
            })
        }
        this.setState({ showDropOffModal: true, selectedDate: '', preferredTiming: '', dropOff })
    }
    groupBy = (data, key) => {
        return data.reduce((acc, x) => {
            acc[x[key]] = [...(acc[x[key]] || []), x];
            return acc;
        }, {});
    }
    returnOrder = () => {
        const data = {
            "returnDate": this.state.selectedDate,
            "returntime": this.state.preferredTiming,
            "orderNo": this.props.order.ordeR_NO,
            "self": this.state.dropOff ? true : false
        };
        this.props.dispatch(returnOrder(data)).then(() => {
            this.setState({ showDropOffModal: false });
        })
    };
    onChange = (date, dateString) => {
        this.setState({ date: dateString });
    }
    onChangeTime = (value) => {
        const timeString = moment(value).format("HH:mm");
        this.setState({ time: timeString });
    }
    render() {

        const { order } = this.props;
        const originalOrder = { ...order };
        const OrderStatuses = {
            1: 'Pending Delivery',
            2: 'Delivered',
            3: 'Returned',
            4: 'Cancelled',
            5: 'Scheduled Return'
        }
        const { showDropOffModal } = this.state;
        const groupedItems = this.groupBy(this.state.orderItems, 'room');
        const stateTax = this.state.costDetails && this.state.costDetails.find(costItem => costItem.description === "State and County Tax");
        let ownerDiscount = this.state.costDetails && this.state.costDetails.find(costItem => costItem.description === "Owner Deduction");
        const discountPrice = this.state.costDetails && this.state.costDetails.find(costItem => costItem.description === "Trade Discount");
        const monthlyRent = this.state.costDetails && this.state.costDetails.find(costItem => costItem.description === "Monthly Rent");


        return <div className="card" id={order.ordeR_NO}>
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Row>
                        <Col lg={8}>
                            <div className="order-no">Order No. : <span>{order.ordeR_NO}</span></div>
                            {(order.ref_No && order.ref_No !== order.ordeR_NO) && <div className="order-no">Original Order: {order.ref_No}</div>}
                        </Col>
                        <Col lg={6}><div className="order-no">Total Items : <span>{order.totalQty}</span></div></Col>
                        <Col lg={10}>
                            {
                                order.orderStatus === 2 ?
                                    <div className="order-date">{order.orderStatusDesc === 'Renewed' ? 'Renewed Date' : 'Rental Date'} : <span className="price">{order.deliveryDate ? order.deliveryDate.split(' ')[0] : ''}</span></div>
                                    :
                                    <div className="order-date">{order.orderStatusDesc === 'Renewed' ? 'Renewed Date' : 'Delivery Date'} : <span className="price">{order.deliveryDate ? `${order.deliveryDate.split(' ')[0]} ${order.slottime}` : ''}</span></div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8}>
                            <div className="address-details">
                                <div className="order-no">
                                    <div className="label">Address Details :</div>
                                    <div className="content">
                                        <span>{order.address1}</span>
                                        <span>{order.address2}</span>
                                        <span>{order.city},&nbsp;{order.state} &nbsp;{order.pincode}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <Row>
                                <Col lg={12}>
                                    <div className="address-details">
                                        <div className="order-no">Order Value : <span>${parseFloat(order.ordervalue).toFixed(2)}</span></div>
                                    </div>
                                </Col>
                                {!order.returnDate && <Col lg={12}>
                                    <div className="address-details">
                                        <div className="order-date">Status : <span>{this.props.ordersHierarchy[order.ordeR_NO].length === 0 ? order.orderStatusDesc : 'Renewed'}{order.orderStatus === 5 ? ` (${order.returnStatus})` : ''}</span></div>
                                    </div>
                                </Col>}
                            </Row>
                            {order.returnDate && <Row>
                                <Col lg={24}>
                                    <br />
                                    <div className="order-date">Status: <span>{order.returnDate ? `Scheduled for pickup on ${originalOrder.returnDate ? originalOrder.returnDate.split(' ')[0] : ''} ${originalOrder.returnSlot}` : order.orderStatusDesc}{order.orderStatus === 5 ? ` (${order.returnStatus})` : ''}</span></div>
                                </Col>
                            </Row>}
                        </Col>
                        <Col lg={4}>
                            <div className="action-buttons">
                                {order.orderStatusDesc === 'Delivered' && !order.returnDate && <Button type="secondary" onClick={() => this.handleProductReturn(false)}>Schedule Return</Button>}
                                {order.orderStatusDesc === 'Delivered' && order.selfDropOff && <Button onClick={() => this.handleProductReturn(true)}>Schedule Drop-off</Button>}
                                {this.state.detailsLoading && <LoadingOutlined />}
                                <Button type="primary" style={this.state.orderIdList[0] !== undefined ? { display: 'none' } : { display: '' }} onClick={() => this.getOrderItems(order.ordeR_NO)}>View Order Details</Button>
                                {this.props.ordersHierarchy && this.props.ordersHierarchy[order.ordeR_NO] && this.props.ordersHierarchy[order.ordeR_NO].length > 0 && <Button style={{ fontSize: '14px' }} icon={this.state.expanded ? <UpOutlined /> : <DownOutlined />} onClick={() => this.setState({ expanded: !this.state.expanded })} size="large">{this.state.expanded ? 'Hide Renewed Orders' : 'View Renewed Orders'}</Button>}
                            </div>
                        </Col>
                    </Row>
                </Col>
                {
                    this.state.orderItems.length > 0 && <>
                        <Col style={{ border: '1px solid #ccc', margin: '1%', padding: '1%' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                            {this.state.paymentInfo && this.state.paymentInfo.length > 0 && this.state.paymentInfo.map(info => <div><h5><b>Mode of Payment:</b> <span>{info.cardType}</span></h5>{info.cardNumber && <h5><b>Card Number:&nbsp;</b><span>{info.cardNumber}</span></h5>}</div>)}
                            <CloseOutlined style={{ color: 'red' }} onClick={() => this.hideProductDetails(order.ordeR_NO)} className="close-button" />
                            <Divider />
                            {Object.entries(groupedItems).map(([groupName, items]) => {
                                return <div>
                                    <h1 style={{ color: 'purple', fontSize: '22px' }}>{(!groupName || groupName === 'null') ? 'No room Specified' : groupName}</h1>
                                    {items.map(item => <OrderItemView product={item} />)}
                                    <Divider />
                                </div>
                            })}
                        </Col>
                    </>}
                {this.state.orderItems.length > 0 && this.state.costDetails && this.state.costDetails.length > 0 && <div className="card-view" style={{ width: '100%' }}>
                    <div className="title">
                        <h3>Cost Details</h3>
                    </div>
                    <Divider />
                    <div className="rental-details">
                        {
                            this.state.costDetails.map(costItem => <Row style={{ marginBottom: '5px' }}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>{costItem.description}</Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} style={{ textAlign: 'right' }}><span style={{ fontFamily: "sans-serif", fontSize: '16px' }}>$ {parseFloat(costItem.amount).toFixed(2)}</span></Col>
                            </Row>)
                        }
                        {<div className="monthly-rental">Recurring Monthly Rent<span style={{ fontFamily: "sans-serif", fontSize: '16px' }}>$ {((parseFloat(monthlyRent.amount) + (stateTax ? parseFloat(stateTax.amount) : 0)) - parseFloat(discountPrice.amount) - (ownerDiscount ? parseFloat(ownerDiscount) : 0)).toFixed(2)}</span></div>
                        }
                    </div>
                </div>}
            </Row>
            {
                this.state.expanded && this.props.ordersHierarchy[order.ordeR_NO].map(order => <Row>
                    <Divider />
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Row>
                            <Col lg={8}>
                                <div className="order-no">Order No. : <span>{order.ordeR_NO}</span></div>
                            </Col>
                            <Col lg={6}><div className="order-no">Total Items : <span>{order.totalQty}</span></div></Col>
                            <Col lg={10}>
                                {
                                    <div className="order-date">{order.orderStatusDesc === 'Renewed' ? 'Rental Date' : (order.orderStatusDesc === 'Pending for Renewal' ? 'Rental Date' : 'Delivery Date')} : <span className="price">{order.deliveryDate ? `${order.deliveryDate.split(' ')[0]}` : ''}</span></div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8}>
                                <div className="address-details">
                                    <div className="order-no">Order Value : <span>${parseFloat(order.ordervalue).toFixed(2)}</span></div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="address-details">
                                    <div className="order-date">Status : <span>{originalOrder.returnDate && order.orderStatusDesc === 'Pending for Renewal' ? `Scheduled for pickup on ${originalOrder.returnDate ? originalOrder.returnDate.split(' ')[0] : ''} ${originalOrder.returnSlot}` : order.orderStatusDesc}{order.orderStatus === 5 ? ` (${order.returnStatus})` : ''}</span></div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>)
            }
            <ModalBox title={this.state.dropOff ? "Schedule self drop-off" : "Schedule Return"} width={700} destroyOnClose visible={showDropOffModal} onOk={this.returnOrder} onCancel={() => this.setState({ showDropOffModal: false })}>
                <div className="staging-details">
                    {
                        <div className="select-delivery-details">
                            <h5>Select Return Date</h5>
                            <div className="delivery-date-time">
                                {
                                    this.state.dates.map(({ date }) =>
                                        <div className="list" onClick={() => this.setState({ selectedDate: date })}>
                                            <div className={`date-time ${this.state.selectedDate === date ? 'selected' : ''}`}>{this.getFormattedDate(date)}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.selectedDate &&
                        <div className="select-delivery-details">
                            <h5>Select Time</h5>
                            <div className="delivery-date-time">
                                {
                                    this.state.dates.map(({ date, timings }) =>
                                        date === this.state.selectedDate && timings.map(slot => <div className="list" onClick={() => this.setState({ preferredTiming: slot.id })}>
                                            <div className={`date-time ${this.state.preferredTiming === slot.id ? 'selected' : ''}`}>{slot.slottime}</div>
                                        </div>)
                                    )
                                }
                            </div>
                        </div>
                    }
                </div>
            </ModalBox>
        </div>
    }
}

export default connect()(OrderCard);