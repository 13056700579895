import {getAxios} from './setAuthorizationToken';
import AppConfig from '../config';
import { WishListTypes } from './ActionType';

const API_BASE_URL = AppConfig.API_BASE_URL;

export const getWishListDetails = (zipCode) => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetWishList?ZipCode=${zipCode}`)
            .then((res) => {
                if (res.data && res.data.wishList) {
                    dispatch({ type: WishListTypes.SET_WISHLIST_ITEMS, payload: res.data.wishList })
                } else {
                    dispatch({ type: WishListTypes.SET_WISHLIST_ITEMS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting wish list data failed.' } })
            });
    }
}

export const removeItemFromWishList = (item, zipCode) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/RemoveItemFromWishList?matCode=${item}`)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: WishListTypes.REMOVE_ITEM_FROM_WISHLIST, payload: res.data })
                    dispatch(getWishListDetails(zipCode))
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item has been removed from wishlist' } })
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Item removing from wishlist is failed.' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Item removing from wishlist is failed.' } })
            });
    }
}

export const updateItemsInWishList = (model) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/SaveItemInWishList`, model)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: WishListTypes.UPDATE_ITEMS_IN_WISHLIST })
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item adding in wishlist.' } })
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Item adding in wishlist is failed.' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Item adding in wishlist is failed.' } })
            });
    }
}

export const getPopularMaterials = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetPopularMaterialInfo`)
            .then((res) => {
                if (res.data && res.data.materialList) {
                    dispatch({ type: WishListTypes.SET_POPULAR_ITEMS, payload: res.data.materialList })
                } else {
                    dispatch({ type: WishListTypes.SET_POPULAR_ITEMS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting popular items failed.' } })
            });
    }
}

export const getRecentViewedDetails = (zipCode) => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetRecentlyViewedItems?ZipCode=${zipCode}`)
            .then((res) => {
                if (res.data && res.data.recentlyViewedList) {
                    dispatch({ type: WishListTypes.SET_RECENT_VIEWED_ITEMS, payload: res.data.recentlyViewedList })
                } else {
                    dispatch({ type: WishListTypes.SET_RECENT_VIEWED_ITEMS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting recently viewed data failed.' } })
            });
    }
}

export const updateRecentViewedDetails = (model) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/UpdateItemInRecentlyViewed`, model)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: WishListTypes.UPDATE_RECENT_VIEWED_ITEMS, payload: res.data })
                    // dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item adding in recent viewed.' } })
                }
                // else {
                //     dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Item adding in recent viewed is failed.' } })
                // }
            })
        // .catch((err) => {
        //     dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Item adding in recent viewed is failed.' } })
        // });
    }
}

export const getInspirationMaterialLIst = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetInspMatTaggingList`)
            .then((res) => {
                if (res.data && res.data.taggingList) {
                    dispatch({ type: WishListTypes.SET_INSPIRATIONS_ITEMS, payload: res.data.taggingList })
                } else {
                    dispatch({ type: WishListTypes.SET_INSPIRATIONS_ITEMS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting recently viewed data failed.' } })
            });
    }
}

export const saveImageAnnotation = (model) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/SaveInspiration`, model)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Image saved successfully.' } });
                    dispatch(GetInspirationListData());
                }
                else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Failed.' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Failed.' } })
            });
    }
}

export const saveImage = (image) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/SaveInspirationImage`, image)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: WishListTypes.GET_IMAGE_DETAILS, payload: res.data })
                    // dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Image uploaded successfully.' } })
                }
                else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Failed.' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Failed.' } })
            });
    }
}

export const GetInspirationListData = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetInspirationList`)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: WishListTypes.SET_INSPIRATIONS_LIST, payload: res.data.inspirationList });
                } else {
                    dispatch({ type: WishListTypes.SET_INSPIRATIONS_LIST, payload: [] });
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting inspiration list failed.' } });
            });
    }
}

export const deleteInspiration = (id) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/RemoveInspiration?id=${id}`)
            .then((res) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Inspiration removed successfully.' } });
                dispatch(GetInspirationListData());
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Failed.' } });
            });
    }
}

export function addPositionData(data) {
    return function(dispatch) {
        return dispatch({ type: WishListTypes.SET_POSITION, payload: data })
    }
}

export const saveInspirationInSequence = (data) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/SaveInspirationOrder`, data)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Inspiration saved in sequence.' } })
                }
                else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Failed.' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Failed.' } })
            });
    }
}