import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from 'react-router-dom';
import Home from '../Screens/LandingPage/Home';
import HowWorks from '../Screens/HowWorks/HowWorks';
import DesignerServices from '../Screens/DesignerServices/DesignerServices';
import Inspirations from '../Screens/Inspirations/Inspirations';
import InspirationsDetailedPage from '../Screens/InspirationsDetailedPage/InspirationsDetailedPage';
import Bundles from  '../Screens/Bundles/Bundles';
import BundlesListing from '../Screens/BundlesListing/BundlesListing';
import BundlesDetailedPage from '../Screens/BundlesDetailedPage/BundlesDetailedPage';
import Furniture from '../Screens/Furniture/Furniture';
import FurnitureListing from '../Screens/FurnitureListing/FurnitureListing';
import FurnitureDetailedPage from '../Screens/FurnitureDetailedPage/FurnitureDetailedPage';
import Offers from '../Screens/Offers/Offers';
import AboutUs from '../Screens/AboutUs/AboutUs';
import Culture from '../Screens/Culture/Culture';
import OurCommitment from '../Screens/OurCommitment/OurCommitment';
import Career from '../Screens/Career/Career';
import MyCart from '../Screens/MyCart/MyCart';
import MyWishlist from '../Screens/MyWishlist/MyWishlist';
import OpenOrders from '../Screens/OpenOrders/OpenOrders';
import RecentlyViewed from '../Screens/RecentlyViewed/RecentlyViewed';
import PersonalDetails from '../Screens/PersonalDetails/PersonalDetails';
import EditPersonalDetails from '../Screens/PersonalDetails/EditPersonalDetails';
import LiveOrders from '../Screens/MyDashboard/LiveOrders/LiveOrders';
import OrderHistory from '../Screens/MyDashboard/OrderHistory/OrderHistory';
import MyInventory from '../Screens/MyDashboard/MyInventory/MyInventory';
import Payment from '../Screens/MyDashboard/Payment/Payment';
import UndeliveredOrders from '../Screens/MyDashboard/UndeliveredOrders/UndeliveredOrders';
import Layout from '../Layout/Layout';
import CreateInspirations from '../Screens/Inspirations/CreateInspirations';
import ManageInspirations from '../Screens/Inspirations/ManageInspirations';
import FAQs from '../Screens/FAQs/FAQs';
import ContactUs from '../Screens/ContactUs/ContactUs';
import TNC from '../Screens/FAQs/TNC';
import ReturnPolicy from '../Screens/FAQs/ReturnPolicy';
import PrivacyPolicy from '../Screens/FAQs/PrivacyPolicy';

export default function InnerRoutes (props) {
    const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  
//   render() {
        const {
            match: { url },
        } = props;
        return (
            <Layout {...props}>
                <Switch>
                    <Route exact path={`/`} component={Home} />
                    <Route exact path={`/home`} component={Home} />
                    {/* <Route exact path={`/how-it-works`} component={HowWorks} /> */}
                    <Route exact path={`/designer-services`} component={DesignerServices} />
                    <Route exact path={`/inspirations`} component={Inspirations} />
                    <Route exact path={`/manage-inspirations`} component={ManageInspirations} />
                    <Route exact path={`/create-inspirations`} component={CreateInspirations} />
                    <Route exact path={`/inspirations-detailed-page`} component={InspirationsDetailedPage} />
                    <Route exact path={`/bundles`} component={Bundles} />
                    <Route exact path={`/bundles-listing/:id/:name`} component={BundlesListing} />       
                    <Route exact path={`/bundles-detailed-page/:bundleid/:bundlename`} component={BundlesDetailedPage} />     
                    <Route exact path={`/furniture`} component={Furniture} />
                    <Route exact path={`/furniture-listing/:search`} component={FurnitureListing} />
                    <Route path={`/furniture-listing/:type/:groupid/:groupname`} component={FurnitureListing} />
                    {/* <Route exact path={`/furniture-listing`} component={FurnitureListing} /> */}
                    <Route exact path={`/furniture-detailed-page/:id/:name`} component={FurnitureDetailedPage} />
                    <Route exact path={`/offers`} component={Offers} />
                    <Route exact path={`/about-us`} component={AboutUs} />
                    <Route exact path={`/culture`} component={Culture} />
                    <Route exact path={`/our-commitment`} component={OurCommitment} />
                    <Route exact path={`/career`} component={Career} />
                    <Route exact path={`/my-cart`} component={MyCart} />
                    <Route exact path={`/my-cart/:address`} component={MyCart} />
                    <Route exact path={`/my-wishlist`} component={MyWishlist} />
                    <Route exact path={`/open-orders`} component={OpenOrders} />
                    <Route exact path={`/recently-viewed`} component={RecentlyViewed} />
                    <Route exact path={`/personal-details`} component={PersonalDetails} />
                    <Route exact path={`/edit-personal-details`} component={EditPersonalDetails} />
                    <Route exact path={`/live-orders`} component={LiveOrders} />
                    <Route exact path={`/order-history`} component={OrderHistory} />
                    <Route exact path={`/my-inventory`} component={MyInventory} />
                    <Route exact path={`/payment`} component={Payment} />
                    <Route exact path={`/undelivered-orders`} component={UndeliveredOrders} />
                    <Route exact path ={`/FAQs`} component = {FAQs}/>
                    <Route exact path ={`/terms-and-conditions`} component = {TNC}/>
                    <Route exact path ={`/return-policy`} component = {ReturnPolicy}/>
                    <Route exact path ={`/privacy-policy`} component = {PrivacyPolicy}/>
                    <Route exact path = {`/ContactUs`} component = {ContactUs}/>
                </Switch>
            </Layout>
        );
    }
// }
