import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './BundlesHome.scss';
import { Row, Col, Button } from 'antd';
import Slider from "react-slick";
import ItemsIncluded from './ItemsIncluded';
import { updateRecentViewedDetails } from '../../../Actions/WishListActions';

const Bundlesslidersettings = {
    arrows: true,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    responsive: [
        {
          breakpoint: 500,
          settings: {
            centerMode: false,
          }
        }
    ]
};

class BundlesHome extends React.Component {

    handleOpenViewDetails = (item) => {
        if (this.props.context) {
            let model = {
                "matCode": item.maT_CODE
            }
            this.props.dispatch(updateRecentViewedDetails(model));
        }
        this.props.history.push(`/bundles-detailed-page/${item.maT_CODE}/${item.maT_DESC}`)
    }

    render() {
        return (
            <React.Fragment>
                <section className="bundles-products">
                    <div className="section-title">
                        <h1>Bundles</h1>
                    </div>

                    <div className="bundles-products-container">
                        <Row align="center">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                <div className="bundles-slider">
                                    {/* <!--Slider Item starts--> */}
                                    <Slider {...Bundlesslidersettings}>
                                        {
                                            this.props.bundles.map(bundle => {
                                                return <div className="item">
                                                    <div className="details" style={{ height: '500px' }}>
                                                        <div className="image">
                                                        <img width="490" height="326" src={bundle.bundleBOM && bundle.bundleBOM[0] ? bundle.bundleBOM[0].imagePath : require('../../../assets/images/bundles/property-listings/furnished-room.webp')} alt="Prospect Velvet Loveseat Navy blue" />
                                                        </div>
                                                        <div className="content">
                                                            <div className="title">
                                                                <h3>{bundle.maT_DESC}</h3>
                                                                <p>{bundle.remark1}</p>
                                                            </div>
                                                            <div className="item-includes">
                                                                <h5>Items Included</h5>
                                                                <ItemsIncluded items={bundle.bundleBOM} />
                                                            </div>
                                                            <div className="bottom-section">
                                                                <div className="rent-details">
                                                                    <span className="rent">Rent</span>
                                                                    <span className="rent">$ {bundle.monthlyrent}/mon</span>
                                                                </div>
                                                                <div className="view-bundles">
                                                                    <Button onClick={() => this.handleOpenViewDetails(bundle)} className="btn btn-secondary">View Details</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </Slider>
                                    {/* <!--Slider Item ends--> */}
                                </div>

                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <div className="view-all">
                                    <Link onClick={() => { this.props.history.push('/bundles')}}>View All</Link>
                                </div>
                                <div className="caption">
                                    <p>Our furniture packages include luxurious chairs, sturdy desks, chic wardrobes, and comfortable beds. You will find pre-selected, tasteful furniture for your kitchen, living room, dining room, and Bedroom. </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    return {
        context: state.login.context
    }
}
export default connect(mapStateToProps)(withRouter(BundlesHome))