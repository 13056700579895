import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import ModalBox from '../../Components/ModalBox/ModalBox';
import SideNavigation from '../MyCart/SideNavigation/SideNavigation';
import QuickView from '../FurnitureListing/QuickView/QuickView';
import { connect } from 'react-redux';
import { getRecentViewedDetails } from '../../Actions/WishListActions'
import FurnitureItem from '../../Components/Furniture/FurnitureItem';

class RecentlyViewed extends React.Component {
    constructor() {
        super()
        this.state = {
            quickView: false
        }
    }

    componentDidMount() {
        const selectedLocation = this.props.userLocation ? this.props.userLocation.ziP_CODE : 0
        let isDatacontext = sessionStorage.getItem('itop-user-context') ? true : false
        if (isDatacontext === true) {
            this.props.dispatch(getRecentViewedDetails(selectedLocation))
        }
    }

    onCancel = () => {
        this.setState({ quickView: false });
    }
    render() {
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <section className="recently-viewed">
                            <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <SideNavigation context={this.props.context} />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                    <h2 className="margin-bottom20">Recently Viewed</h2>
                                    {/* Recently Viewed starts here */}

                                    <div className="recently-viewed-furnitures">
                                        {/* <h3 className="margin-bottom20">Today</h3> */}
                                        {
                                            !this.props.recentlyViewedItems.length ? "No items found" :
                                                <Row gutter={[10, 10]}>
                                                    {
                                                        this.props.recentlyViewedItems && this.props.recentlyViewedItems.map(recentItem =>
                                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                                <FurnitureItem item={recentItem} />
                                                            </Col>
                                                        )}
                                                    {/* <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                        <div className="furniture-grid">
                                                            <div className="details">
                                                                <div className="image">
                                                                    <img src={require("../../assets/images/furniture/listings/furniture-2.webp")} alt="" />
                                                                    <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                                </div>
                                                                <h4>Hugo 2 Seater Sofa - White</h4>
                                                                <div className="other-details">
                                                                    <div className="rent">
                                                                        <span className="title">Rent</span>
                                                                        <span className="price">$ 12/mon</span>
                                                                    </div>
                                                                    <div className="shipping">
                                                                        <span>ships in 4 days</span>
                                                                    </div>
                                                                    <div className="quick-view">
                                                                        <Button type="secondary" onClick={() => this.setState({ quickView: true })}>Quick View</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                        <div className="furniture-grid">
                                                            <div className="details">
                                                                <div className="image">
                                                                    <img src={require("../../assets/images/furniture/listings/furniture-3.webp")} alt="" />
                                                                    <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                                </div>
                                                                <h4>Hugo 4 Seater L shape Sofa</h4>
                                                                <div className="other-details">
                                                                    <div className="rent">
                                                                        <span className="title">Rent</span>
                                                                        <span className="price">$ 12/mon</span>
                                                                    </div>
                                                                    <div className="shipping">
                                                                        <span>ships in 4 days</span>
                                                                    </div>
                                                                    <div className="quick-view">
                                                                        <Button type="secondary" onClick={() => this.setState({ quickView: true })}>Quick View</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                        <div className="furniture-grid">
                                                            <div className="details">
                                                                <div className="image">
                                                                    <img src={require("../../assets/images/furniture/listings/furniture-3.webp")} alt="" />
                                                                    <Link className="favourite"><i className="fa fa-heart" /></Link>
                                                                </div>
                                                                <h4>Hugo 4 Seater L shape Sofa</h4>
                                                                <div className="other-details">
                                                                    <div className="rent">
                                                                        <span className="title">Rent</span>
                                                                        <span className="price">$ 12/mon</span>
                                                                    </div>
                                                                    <div className="shipping">
                                                                        <span>ships in 4 days</span>
                                                                    </div>
                                                                    <div className="quick-view">
                                                                        <Button type="secondary" onClick={() => this.setState({ quickView: true })}>Quick View</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col> */}
                                                </Row>
                                        }
                                        {/* Recently Viewed ends here */}
                                    </div>

                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
                {/* Quick view modal box */}
                {/* <ModalBox
                    visible={!!this.state.quickView}
                    width={854}
                    wrapClassName="main-search-area"
                    onCancel={this.onCancel}
                    footer={null}
                    destroyOnClose>
                    <QuickView />
                </ModalBox> */}
                {/* Quick view modal box ends */}
            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    return {
        recentlyViewedItems: state.wishlist.recentlyViewedItems,
        userLocation: state.users.userLocation,
        context: state.login.context
    }
}
export default connect(mapStateToProps)(withRouter(RecentlyViewed))