import React from 'react';
import { withRouter } from 'react-router';
// import './HowitWorks.scss';
import { Row, Col } from 'antd';

class StagingDepotCommitment extends React.Component {

    render() {
        return (
            <React.Fragment>
                 {/* The Staging Depot Commitment starts */}
                 <section className="staging-depot-comitment">
                    <div className="container mx-auto">
                        <div className="section-title">
                            <h2>The Staging Depot Commitment</h2>
                        </div>
                        <Row align="center">
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 7 }}>
                                <div className="custom-icon">
                                    <img src={require("../../../assets/images/commitment/vision.svg")} alt="" />
                                    <h3>Vision</h3>
                                    <p>Staging Depot will be the premier industry partner for  Interior Designers & Home Stagers.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 7 }}>
                                <div className="custom-icon">
                                    <img src={require("../../../assets/images/commitment/mission.svg")} alt="" />
                                    <h3>Mission</h3>
                                    <p>We use innovative web-based technology to help our clients grow. Through curated rental furnishings and home staging operations, to concierge receiving & delivery for designers, we make life easy for our customers.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 7 }}>
                                <div className="custom-icon">
                                    <img src={require("../../../assets/images/commitment/promise.svg")} alt="" />
                                    <h3>Promise</h3>
                                    <p>Integrity. Trust. Professionalism. You can rely on our staff to be reliable - clean-cut, on-time, and provide service with a smile.</p>
                                </div>
                            </Col>
                        </Row>
                        {/* icons end */}
                    </div>
                </section>
                {/* The Staging Depot Commitment ends */}
            </React.Fragment>
        )
    }
}
export default withRouter(StagingDepotCommitment)