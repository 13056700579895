import axios, { getAxios } from './setAuthorizationToken';
import AppConfig from '../config';
import { BundleTypes } from './ActionType';

const API_BASE_URL = AppConfig.API_BASE_URL;


export const getBundleListing = () => {
    return function(dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetBundleList`)
            .then((res) => {
                if (res.data && res.data.bundleItemList) {
                    dispatch({ type: BundleTypes.SET_BUNDLE_LIST, payload: res.data.bundleItemList })
                } else {
                  dispatch({ type: BundleTypes.SET_BUNDLE_LIST, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification:{ type: 'ERROR', message: 'Getting Bundle List failed.' } })
            });
    }
}

export const getBundleSubGroups = () => {
    return function(dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetBundleSubGroupList`)
            .then((res) => {
                if (res.data && res.data.bundleSubGroupList) {
                    dispatch({ type: BundleTypes.SET_BUNDLE_SUB_GROUPS, payload: res.data.bundleSubGroupList })
                } else {
                  dispatch({ type: BundleTypes.SET_BUNDLE_SUB_GROUPS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification:{ type: 'ERROR', message: 'Getting Bundle Subgroups failed.' } })
            });
    }
}

export const getBundleGroups = () => {
    return function(dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetBundleGroupList`)
            .then((res) => {
                if (res.data && res.data.bundleGroupList) {
                    dispatch({ type: BundleTypes.SET_BUNDLE_GROUPS, payload: res.data.bundleGroupList })
                } else {
                  dispatch({ type: BundleTypes.SET_BUNDLE_GROUPS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification:{ type: 'ERROR', message: 'Getting bundle groups failed.' } })
            });
    }
}

export const setProductAvailability = (data) => {
    return function (dispatch) {
        return dispatch({ type: BundleTypes.SET_AVAILABLE_TYPE, payload: data })
    }
}

export const resetAllFilters = () => {
    return function (dispatch) {
        return dispatch({ type: BundleTypes.RESET_FILTERS })
    }
}

export const addSubGroupFilter = (data) => {
    return function (dispatch) {
        return dispatch({ type: BundleTypes.ADD_SUB_GROUP, payload: data })
    }
}