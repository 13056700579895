import { WishListTypes } from '../Actions/ActionType';

const initialState = {
  wishListItems: [],
  recentlyViewedItems: [],
  popularItems: [],
  inspirationsItems: [],
  imageDetails: {},
  inspirationsList: []
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case WishListTypes.SET_WISHLIST_ITEMS: {
      return {
        ...state,
        wishListItems: action.payload
      }
    }

    case WishListTypes.SET_POPULAR_ITEMS: {
      return {
        ...state,
        popularItems: action.payload
      }
    }

    case WishListTypes.SET_RECENT_VIEWED_ITEMS: {
      return {
        ...state,
        recentlyViewedItems: action.payload
      }
    }

    case WishListTypes.SET_INSPIRATIONS_ITEMS: {
      return {
        ...state,
        inspirationsItems: action.payload
      }
    }

    case WishListTypes.GET_IMAGE_DETAILS: {
      return {
        ...state,
        imageDetails: action.payload
      }
    }

    case WishListTypes.SET_INSPIRATIONS_LIST: {
      return {
        ...state,
        inspirationsList: action.payload
      }
    }

    case WishListTypes.SET_POSITION: {
      return {
        ...state,
        inspirationsList: action.payload
      }
    }

    default: {
      return state;
    }
  }
}