import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col, Spin } from 'antd';
import { getFurnitureGroups } from '../../Actions/FurnitureActions';

class Furniture extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
        String.prototype.initCap = function () {
            return this.toLowerCase().replace(/(?:^|\b)[a-z]/g, function (m) {
               return m.toUpperCase();
            });
        };
    }
    componentDidMount() {
        if (this.props.furnitureGroups.length === 0) {
            this.setState({ loading: true });
            this.props.dispatch(getFurnitureGroups()).then(() => {
                this.setState({ loading: false });
            })
        }
    }

    setProductType = (id, name, type) => {
        this.props.history.push(`/furniture-listing/${type === '1' ? 'room' : 'furniture'}/${id}/${name}`);
    };

    render() {
        return (
            <React.Fragment>
                {/* page banners */}
                <section className="page-banners furniture">
                    <div className="container mx-auto">
                        <h1>Furniture</h1>
                        <p>Our Furniture Includes Luxurious Chairs, Sturdy Desks, Chic Wardrobes, And Comfortable Beds.</p>
                        <p>You Will Find Pre-Selected, Tasteful Furniture For Your Kitchen, Living Room, Dining Room, And Bedroom. </p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-wave">
                    <div className="container mx-auto">
                        <div className="choose-room-type type-1">
                            <h2>Choose by Room Type</h2>
                            <Row gutter={[12, 20]}>
                                {
                                    this.state.loading && <Spin tip="Please wait while we load the categories." />
                                }
                                {!this.state.loading && this.props.furnitureGroups.filter(x => x.grouP_ID === "1").length === 0 && <div className="no-product-available">
                                    <Row gutter={[8, 8]}>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <h3>No Groups Available !</h3>
                                            {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p> */}
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <img src={require('../../assets/images/icons/box.svg')} alt="No Products Available" />
                                        </Col>
                                    </Row>
                                </div>}
                                {
                                    this.props.furnitureGroups.filter(x => x.grouP_ID === "1").map(group => <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                        <Link>
                                            <div className="room-type" onClick={() => this.setProductType(group.matgrouP_CODE, group.description, group.grouP_ID)}>
                                                <div className="image">
                                                    <img src={group.imagePath} alt={group.description} />
                                                </div>
                                                <div className="details">
                                                    <div className="title">{group.description === 'DINING ROOM' ? 'Dining Room / Bar': group.description.initCap()}</div>
                                                    {/* <div className="total-bundles">156 items</div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>)
                                }
                            </Row>
                        </div>

                        <div className="choose-room-type type-1">
                            <h2>Choose by Furniture Type</h2>
                            <Row gutter={[12, 20]}>
                                {
                                    this.state.loading && <Spin tip="Please wait while we load the categories." />
                                }
                                {!this.state.loading && this.props.furnitureGroups.filter(x => x.grouP_ID === "2").length === 0 && <div className="no-product-available">
                                    <Row gutter={[8, 8]}>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <h3>No Groups Available !</h3>
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <img src={require('../../assets/images/icons/box.svg')} alt="No Products Available" />
                                        </Col>
                                    </Row>
                                </div>}
                                {
                                    this.props.furnitureGroups.filter(x => x.grouP_ID === "2").map(group => <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                        <Link>
                                            <div className="room-type" onClick={() => this.setProductType(group.matgrouP_CODE, group.description, group.grouP_ID)}>
                                                <div className="image">
                                                    <img  src={group.imagePath} alt={group.description}/>
                                                </div>
                                                <div className="details">
                                                    <div className="title">{group.description.initCap()}</div>
                                                    {/* <div className="total-bundles">156 items</div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>)
                                }
                            </Row>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        furnitureGroups: state.furniture.furnitureGroups
    }
}
export default connect(mapStateToProps)(withRouter(Furniture))