import React from 'react';
import { withRouter } from 'react-router';
import InputBox from '../../Components/InputBox/InputBox';
import { Button, Col, Row, Spin } from 'antd';
import './SelectLocation.scss';
import { getLocations, changeLocation } from '../../Actions/UserActions';
import { connect } from 'react-redux';

class SelectLocation extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            open: true,
            selectedZipCode: undefined,
            reason: '',
            searchByNameZip: '',
            locations: []
        }
    }
    componentDidMount() {
        if(this.props.locations.length === 0) {
            this.setState({ loading: true });
            this.props.dispatch(getLocations()).then(() => {
                this.setState({ loading: false, locations: this.props.locations });
            })
        } else {
            this.setState({ locations: this.props.locations })
        }
    }
    onChangeText = (value, type) => {
        this.setState({ 
            [type]: value, 
            locations: this.props.locations.filter(location => (location.ziP_DESC.toLowerCase().indexOf(value.toLowerCase()) >= 0 || location.ziP_CODE.toLowerCase().indexOf(value.toLowerCase()) >= 0)) 
        })
    }
    render() {
        const selectedZipCode = this.state.selectedZipCode ? this.state.selectedZipCode : (this.props.userLocation ? this.props.userLocation.ziP_CODE : undefined )
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <Spin tip="Loading Zipcodes. Please wait.." />
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <div className="select-location">
                    <div className="sub-title">
                        <p>Confirm staging location to help us serve you better!</p>
                    </div>
                    <div className="search-field">
                        <div className="form-fields">
                            <InputBox
                                placeholder="search by name or zipcode"
                                id="searchByNameZip"
                                value={this.state.searchByNameZip}
                                onChangeText={this.onChangeText}
                                type="text"
                                suffix={"fa fa-search"}
                            />
                        </div>
                    </div>
                    <div className="locations">
                        { this.state.locations.map(location => 
                            <div onClick={() => this.setState({ selectedZipCode: location.ziP_CODE })} className="location-list">
                                <div className={`details ${ location.ziP_CODE === selectedZipCode ? 'location-list-selected' : ''}`}>
                                    <div className="city">{location.ziP_DESC}</div>
                                    <div className="zip-code">{location.ziP_CODE}</div>
                                </div>
                            </div>
                        ) }
                        {this.state.locations.length === 0 && <div className="no-product-available">
                            <Row gutter={[8, 8]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                    <h3>Zip code not found !</h3>
                                    {/* <p>Looks like we are not providing service in specified location.</p> */}
                                    <p>We are open to expanding to surrounding areas.  So, please give us a call at 1-855-55DEPOT to see if we can help you</p>
                                </Col>
                            </Row>
                        </div>}
                    </div>
                    <div className="action-button">
                        <Button 
                            onClick={async () => { 
                                await this.props.dispatch(
                                    changeLocation(
                                        this.props.locations.find(location => location.ziP_CODE === selectedZipCode)
                                    )
                                );
                                this.props.handleClose();
                            }} 
                            type="primary" 
                            disabled={(this.props.userLocation ? this.props.userLocation.ziP_CODE === selectedZipCode : !selectedZipCode)}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    return {
        locations: state.users.locations,
        userLocation: state.users.userLocation
    }
}
export default connect(mapStateToProps)(withRouter(SelectLocation))