import { FurnitureTypes } from '../Actions/ActionType';
const initialState = {
  furnitureGroups: [],
  furnitureSubGroups: [],
  furnitureList: [],
  offerFurnitureList: [],
  productAvailable: false,
  myInventory: localStorage.getItem("filters-myInventory") ? localStorage.getItem("filters-myInventory") === "true" : false,
  depotInventory: localStorage.getItem("filters-depotInventory") ? localStorage.getItem("filters-depotInventory") === "true" : true,
  coopInventory: localStorage.getItem("filters-coopInventory") ? localStorage.getItem("filters-coopInventory") === "true" : true,
  priceRange: null,
  subGroupsMaster: [],
  subGroups: [],
  availableDate: '',
  duration: '',
  myInventoryList: [],
  alternateProducts: []
};

function getSubGroupsList(furniture, subGroupsMaster) {
  let subGroupIds = furniture.map(item=> item.matsubgrouP_CODE);
  let subgroups = subGroupsMaster.filter(sg => subGroupIds.includes(sg.matsubgrouP_CODE))
  return subgroups;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FurnitureTypes.SET_MY_INVENTORY: {
      return {
        ...state,
        myInventoryList: action.payload
      }
    }
    case FurnitureTypes.SET_ALTERNATE_PRODUCTS: {
      return {
        ...state,
        alternateProducts: action.payload
      }
    }
    case FurnitureTypes.SET_FURNITURE_GROUPS: {
      return {
        ...state,
        furnitureGroups: action.payload
      }
    }
    case FurnitureTypes.SET_FURNITURE_SUB_GROUPS: {
      return {
        ...state,
        subGroupsMaster: action.payload,
        furnitureSubGroups: action.payload
      }
    }
    case FurnitureTypes.SET_FURNITURE_LIST: {
      return {
        ...state,
        furnitureList: action.payload,
        furnitureSubGroups: getSubGroupsList(action.payload, state.subGroupsMaster)
      }
    }
    case FurnitureTypes.SET_FILTERS_BY_USER: {
      return action.hasInventory ? 
      {
        ...state,
        depotInventory: false,
        coopInventory: false,
        myInventory: true
      }
      :
      {
        ...state,
        depotInventory: true,
        coopInventory: false,
        myInventory: false
      }
    }
    case FurnitureTypes.SET_OFFER_FURNITURE_LIST: {
      return {
        ...state,
        offerFurnitureList: action.payload
      }
    }
    case FurnitureTypes.SET_AVAILABLE_TYPE: {
      if (action.payload.type === 'available') {
        return {
          ...state,
          productAvailable: action.payload.data
        }
      }
      else if (action.payload.type === 'myInventory') {
        localStorage.setItem("filters-myInventory", action.payload.data);
        return {
          ...state,
          myInventory: action.payload.data
        }
      }
      else if (action.payload.type === 'depotInventory') {
        localStorage.setItem("filters-depotInventory", action.payload.data);
        return {
          ...state,
          depotInventory: action.payload.data
        }
      }
      else if (action.payload.type === 'coopInventory') {
        localStorage.setItem("filters-coopInventory", action.payload.data);
        return {
          ...state,
          coopInventory: action.payload.data
        }
      }
      else if (action.payload.type === 'priceRange') {
        return {
          ...state,
          priceRange: action.payload.data
        }
      }
      else if (action.payload.type === 'date') {
        return {
          ...state,
          availableDate: action.payload.data
        }
      }
      else if (action.payload.type === 'duration') {
        return {
          ...state,
          duration: action.payload.data
        }
      }
    
    }

    case FurnitureTypes.RESET_FILTERS: {
      return {
        ...state,
        priceRange: null,
        productAvailable: false,
        myInventory: false,
        depotInventory: false,
        coopInventory: false,
        availableDate: '',
        subGroups: []
      }
    }

    case FurnitureTypes.ADD_SUB_GROUP: {
      const allId = [...state.subGroups]
      const existId = allId.includes(action.payload)
      if (existId === false) {
        return {
          ...state,
          subGroups: [...allId, action.payload]
        }
      }
      else {
        const index = allId.indexOf(action.payload);
        allId.splice(index, 1);
        return {
          ...state,
          subGroups: [...allId]
        }
      }
    }

    case FurnitureTypes.CLEAR_SUBGROUPS: {
      return {
        ...state,
        subGroups: []
      }
    }

    default: {
      return state;
    }
  }
}