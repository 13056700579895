import React from 'react';
import { withRouter } from 'react-router';
import FurnitureItem from '../../../Components/Furniture/FurnitureItem';
import Slider from "react-slick";

const Productslidersettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                centerMode: true,
                slidesToShow: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                centerMode: true,
                slidesToShow: 1
            }
        }
    ]
};
class Recommended extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="popularity-slider">
                    {/* <!--Slider Item starts--> */}
                    <Slider {...Productslidersettings}>
                    {
                        this.props.items.map(item => <FurnitureItem item={item}  />)
                    }
                    </Slider>
                    {/* <!--Slider Item ends--> */}
                </div>
            </React.Fragment >

        )
    }
}
export default withRouter(Recommended)