import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Row, Col, Spin } from "antd";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

class FAQs extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners furniture">
          <div className="container mx-auto">
            <h1>Return Policy</h1>
          </div>
        </section>
        {/* page banners */}

        <section className="inner-pages top-right-wave">
          <div className="container mx-auto" style={{ border: '1px solid #ccc', background: 'white', padding: '2%' }}>
            <h2>What is your return process?</h2>
            <p>
              To return Staging Depot or COOP furniture, please log in to
              website, click on order, and schedule return. This will prevent
              your order from renewing for an additional month. If you are a
              Staging Operations customer and returning your own items, you may
              schedule a self-dropoff.
            </p>
            <h3>Refund Process</h3>
            <p>
              For purchases using a credit card, your refund will be applied to
              the same credit card.
            </p>
            <h3>Refunds and Cancellations</h3>
            <p>
              The Lessee may terminate this Agreement by providing the Lessor
              with prior written notice as follows:
              <ul>
                <li>
                  The Lessee may terminate this Agreement if the Lessee provides
                  the Lessor with written notice of termination at least 5
                  business days prior to the Delivery Date. If the Lessee
                  terminates this Agreement as provided for in this subsection
                  (a), then all amounts paid by the Lessee to the Lessor
                  pursuant to this Agreement shall be returned.
                </li>
                <li>
                  The Lessee may terminate this Agreement if the Lessee provides
                  the Lessor with written notice of termination at least 3 to 4
                  business days prior to the Delivery Date. If the Lessee
                  terminates this Agreement in accordance with this subsection
                  (b), then the Lessee shall be charged 20% of the Rental Amount
                  for the Initial Term plus applicable taxes.
                </li>
                <li>
                  The Lessee may terminate this Agreement if the Lessee provides
                  the Lessor with written notice of termination at least 1 to 2
                  business days prior to the Delivery Date. If the Lessee
                  terminates this Agreement in accordance with this subsection
                  (c), then the Lessee shall be charged 50% of the Rental Amount
                  for the Initial Term plus applicable taxes.
                </li>
                <li>
                  The Lessee shall not be entitled to any refund, discount, or
                  abatement of any sort if the Lessee terminates this Agreement
                  prior to the completion of the Initial Term or any Renewal
                  Term unless the Lessor in its sole discretion agrees
                  otherwise.
                </li>
              </ul>
            </p>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
// function mapStateToProps(state) {
//     return {
//         furnitureGroups: state.furniture.furnitureGroups
//     }
// }
export default withRouter(FAQs);
