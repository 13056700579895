import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import BreadCrumbs from '../../Components/BreadCrumbs/BreadCrumbs';
import ProductSldier from '../BundlesDetailedPage/ProductSldier/ProductSlider';
import InputBox from '../../Components/InputBox/InputBox';
import GetRefund from '../BundlesDetailedPage/GetRefund/GetRefund';
import RecommendedProducts from '../BundlesDetailedPage/RecommendedProducts/RecommendedProducts';
import SimilarInspirations from './/SimilarInspirations/SimilarInspirations';
import '../BundlesDetailedPage/BundlesDetailedPage.scss';

class InspirationsDetailedPage extends React.Component {
    constructor() {
        super()
        this.state = {
            zipCode: '',
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>
                {/* page banners */}
                <section className="page-banners inspirations">
                    <div className="container mx-auto">
                        <h1>Inspirations</h1>
                        <p>Whether you're looking for bespoke luxury furniture in an Art Deco style or a beautifully handcrafted modern sofa, with our tailored selection of designer furniture from the world’s leading designer brands, we have a style to suit every home interior.</p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-bottom-wave light-bg">
                    <div className="container mx-auto">

                        <BreadCrumbs {...this.props} navigations={[{ name: 'Home', link: '/' }, { name: 'Inspirations', link: '/inspirations' }, { name: 'Luxa calm living decor', link: '/inspirations-detailed-page' }]} />

                        <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                <ProductSldier />
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2 }}>
                                <div className="detailed-view">
                                    <div className="title">
                                        <Row>
                                            <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                                <h2>Luxa calm living decor inspiration</h2>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                <p>Includes 12 items</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="price"><span className="get-look">Get entire look at </span> $ 270/m</div>
                                    <div className="overview">
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
                                    </div>
                                    <div className="check-availaility">
                                        <h3>Enter ZIP code to check if we deliver to your area</h3>
                                        <form>
                                            <div className="form-fields">
                                                <InputBox
                                                    placeholder="Enter Zipcode"
                                                    id="zipCode"
                                                    value={this.state.zipCode}
                                                    onChangeText={this.onChangeText}
                                                    type="text"
                                                    optional="true"
                                                />

                                                <Button type="link">Check Availability</Button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="action-button margin-top20">
                                        <Button type="secondary">Add to wishlist</Button>
                                        <Button type="primary">Add to cart</Button>
                                        <Button type="link">Check Availability Calendar</Button>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col xs={{ span: 24, order: 3 }} sm={{ span: 12, order: 3 }} md={{ span: 12, order: 4 }} lg={{ span: 12, order: 4 }}>
                                <GetRefund />
                                <GetRefund />
                            </Col> */}

                            {/* <Col xs={{ span: 24, order: 4 }} sm={{ span: 12, order: 4 }} md={{ span: 12, order: 3 }} lg={{ span: 12, order: 3 }}>
                                <RecommendedProducts />
                                <RecommendedProducts />
                                <RecommendedProducts />
                            </Col> */}

                            <Col xs={{ span: 24, order: 5 }} sm={{ span: 24, order: 5 }} md={{ span: 24, order: 5 }} lg={{ span: 24, order: 5 }}>
                                <div className="title margin-top40">
                                    <h4>Similar Inspirations</h4>
                                    <p>Save money and time and also save on shipping expenses by ordering the bundles.</p>
                                </div>
                                <SimilarInspirations />
                            </Col>

                        </Row>
                    </div>
                </section>


            </React.Fragment >
        )
    }
}
export default withRouter(InspirationsDetailedPage)