import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import './RecommendedProducts.scss';

class RecommendedProducts extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="recommended-products">
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                            <h4>Carter Occasional Chair Light Gray</h4>
                         </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                            <Link>More Details</Link>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <img src={require('../../../assets/images/bundles/intersection.webp')} alt="Carter Occasional Chair Light Gray" />
                         </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <div className="specfications">
                                <div className="material">
                                    <h5>Material</h5>
                                    <span>Fabric | Wood</span>
                                </div>
                                <div className="color">
                                    <h5>Color</h5>
                                    <span>Orange</span>
                                </div>
                                <div className="dimensions">
                                    <h5>Dimensions <span>(inches)</span></h5>
                                    <span>60W * 33.5H * 32.5D</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>

        )
    }
}
export default withRouter(RecommendedProducts)