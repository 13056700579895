import { getAxios } from './setAuthorizationToken';
import AppConfig from '../config';
import { CartTypes } from './ActionType';

const API_BASE_URL = AppConfig.API_BASE_URL;


export const saveMultipleCartItems = (cartItems) => {
    return getAxios().post(`${API_BASE_URL}/api/CustomerPortal/SaveMultiItemInCart`, {
        cartItems
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });
}

export const getZipCodeDetails = (zip) => {
    return getAxios().get(`${API_BASE_URL}/api/CustomerPortal/GetZipCodeState?ZipCode=${zip}`)
        .then((res) => {
            if (res.data && res.data.zipState && res.data.zipState.length > 0) {
                return res.data.zipState[0]
            }
        })
        .catch((err) => {
            return err;
        });
}

const getZipCode = () => {
    return sessionStorage.getItem("delivery-zipcode")
}

export const loadCartItems = (isPickup = false, zipcode = '') => {
    return function (dispatch) {

        dispatch({ type: CartTypes.GET_CART_ITEMS_LOADER })
        if (!zipcode) {
            zipcode = getZipCode();
        }
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetCartItem?isPickup=${isPickup}${zipcode ? `&ZIPCODE=${zipcode}` : ''}`)
            .then((res) => {
                if (res.data && res.data.myCartList) {
                    dispatch({ type: CartTypes.SET_CART_ITEMS, cartCost: res.data.myCartList.costDetails, payload: res.data.myCartList.cartList ? res.data.myCartList.cartList : [] })
                } else {
                    dispatch({ type: CartTypes.SET_CART_ITEMS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting Cart Item failed.' } })
            });
    }
}

export const addItemToTempCart = (item, quantity, room) => {
    return function (dispatch) {
        let tempCartItems = sessionStorage.getItem('CartItems');
        if (!tempCartItems) {
            tempCartItems = [];
        } else {
            tempCartItems = JSON.parse(tempCartItems);
        }
        const existed = tempCartItems.find(itm => itm.matCode === item.maT_CODE);
        if (existed) {
            tempCartItems = tempCartItems.map(itm => itm.matCode === item.maT_CODE ? { ...item, quantity: itm.quantity + quantity } : itm)
        } else {
            const tempItem = {
                groupName: item.matgrouP_DESC,
                imagePath: item.imagePath,
                matCode: item.maT_CODE,
                price: item.monthlyrent,
                productDesc: item.remark1,
                productName: item.maT_DESC,
                quantity,
                room,
                subGroupName: item.matsubgroup
            }
            tempCartItems.push(tempItem);
        }
        sessionStorage.setItem('CartItems', JSON.stringify(tempCartItems));
        dispatch({ type: CartTypes.SET_CART_ITEMS, payload: tempCartItems })
        dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item has been added to cart' } })
    }
}

export const updateItemToTempCart = (item, quantity, room) => {
    return function (dispatch) {
        let tempCartItems = sessionStorage.getItem('CartItems');
        if (!tempCartItems) {
            tempCartItems = [];
        } else {
            tempCartItems = JSON.parse(tempCartItems);
        }
        const existed = tempCartItems.find(itm => itm.matCode === item.matCode);
        if (existed) {
            tempCartItems = tempCartItems.map(itm => itm.matCode === item.matCode ? { ...item, quantity: quantity, room: room } : itm)
        } else {
            const tempItem = {
                groupName: item.matgrouP_DESC,
                imagePath: item.imagePath,
                matCode: item.matCode,
                price: item.monthlyrent,
                productDesc: item.remark1,
                productName: item.maT_DESC,
                quantity,
                room,
                subGroupName: item.matsubgroup
            }
            tempCartItems.push(tempItem);
        }
        sessionStorage.setItem('CartItems', JSON.stringify(tempCartItems));
        dispatch({ type: CartTypes.SET_CART_ITEMS, payload: tempCartItems })
        dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item has been updated to cart' } })
    }
}

export const removeItemFromTempCart = (itemId) => {
    return function (dispatch) {
        let tempCartItems = sessionStorage.getItem('CartItems');
        if (!tempCartItems) {
            return false;
        } else {
            tempCartItems = JSON.parse(tempCartItems);
        }
        tempCartItems = tempCartItems.filter(item => item.matCode !== itemId);
        sessionStorage.setItem('CartItems', JSON.stringify(tempCartItems));
        dispatch({ type: CartTypes.SET_CART_ITEMS, payload: tempCartItems })
        dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item has been removed from cart' } })
    }
}

export const addItemToCart = (item, forced = false) => {
    return function (dispatch) {
        dispatch({ type: CartTypes.ADD_ROOM, roomName: item.room })
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/SaveItemInCart`, item)
            .then((res) => {
                if (res && res.data) {
                    if (res.data?.status === 0) {
                        dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: res.data?.message } })
                    }
                    else {
                        dispatch({ type: CartTypes.ADD_ITEM_TO_CART, payload: res.data })
                        if (forced) {
                            dispatch(loadCartItems()).then(() => {
                                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item has been added to cart' } })
                            })
                        } else {
                            dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item has been added to cart' } })
                        }
                    }
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Add item(s) to cart has failed' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Add item(s) to cart has failed' } })
            });
    }
}

export const removeItemFromCart = (item, reload = true) => {
    return function (dispatch) {
        // dispatch({ type: CartTypes.REMOVE_ITEM_FROM_CART, itemCode: item.maT_CODE });
        // dispatch({ type: UtilsType.SET_NOTIFICATION, notification: { type: 'SUCCESS', message: 'Item has been removed from cart' } })
        // return true;
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/RemoveItemFromCart?matCode=${item}`)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: CartTypes.REMOVE_ITEM_FROM_CART, payload: res.data })
                    if (reload) {
                        dispatch(loadCartItems()).then(() => {
                            dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item has been removed from cart' } })
                        })
                    }
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Item removing from cart is failed.' } })
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Item removing from cart is failed.' } })
            });
    }
}

export const updateItemInCart = (item, zipcode, reload = true) => {
    return function (dispatch) {
        if (!zipcode) {
            zipcode = getZipCode();
        }
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/UpdateItemInCart`, item)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: CartTypes.UPDATE_ITEMS_IN_CART })
                    // getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetCartItem?isPickup=false${zipcode ? `&ZIPCODE=${zipcode}` : ''}`)
                    //     .then((response) => {
                    //         if (response.data && response.data.myCartList) {
                    //             dispatch({ type: CartTypes.SET_CART_ITEMS, cartCost: response.data.myCartList.costDetails, payload: response.data.myCartList.cartList ? response.data.myCartList.cartList : [] })
                    //         } else {
                    //             dispatch({ type: CartTypes.SET_CART_ITEMS, payload: [] })
                    //         }
                    //     })
                    //     .catch((err) => {
                    //         dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting Cart Item failed.' } })
                    //     });
                    // dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Quantity has been updated' } })
                    // return true

                    if (reload) {
                        dispatch(loadCartItems()).then(() => {
                            dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Item has been removed from cart' } })
                        })
                    }
                    return true
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Quantity could not be updated.' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Quantity could not be updated.' } })
            });
    }
}

export const addInspirationsToCart = (model) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/SaveInspirationToCart`, model)
            .then((res) => {
                if (res.data && res.data) {
                    return dispatch(loadCartItems()).then(() => {
                        dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Items have been added to cart.' } });
                    })
                }
                else {
                    return dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Add item(s) to cart has failed' } })
                }
            })
            .catch((err) => {
                return dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Add item(s) to cart has failed' } })
            });
    }
}

export const checkOfflineCart = () => {
    return function (dispatch) {
        let tempCartItems = sessionStorage.getItem('CartItems');
        if (tempCartItems) {
            tempCartItems = JSON.parse(tempCartItems);
            const items = tempCartItems.map(item => {
                return {
                    "matCode": item.matCode,
                    "quantity": item.quantity,
                    "room": item.room
                }
            });
            dispatch(addInspirationsToCart({ "inspirationProducts": items })).then(() => {
                sessionStorage.removeItem('CartItems');
            })
                .catch(err => {

                })
        }
    }
}