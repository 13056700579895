import { Button, Col, Row, Select } from "antd";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Annotation from "react-image-annotation";
import { PointSelector } from "react-image-annotation/lib/selectors";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  addPositionData, deleteInspiration, GetInspirationListData,
  getInspirationMaterialLIst,
  saveImageAnnotation, saveInspirationInSequence
} from "../../Actions/WishListActions";
import ModalBox from "../../Components/ModalBox/ModalBox";
import SelectBox from "../../Components/SelectBox/SelectBox";
import "./Inspirations.scss";

function renderContent({ annotation }) {
  const { geometry } = annotation;
  return (
    <div
      key={annotation.data.id}
      style={{
        background: "white",
        color: "black",
        padding: 8,
        position: "absolute",
        left: `${geometry.x}%`,
        top: `${geometry.y + geometry.height + 2}%`,
        width: "50%",
      }}
    >
      <Row>
        <Col span={10}>
          <img
            src={annotation.data && annotation.data.thumbnailImagePath}
            alt="Inspirations"
            style={{ height: 50, width: 50 }}
          />
        </Col>
        <Col span={14}>
          <div style={{ color: "#A14BCC", fontSize: 12 }}>
            {annotation.data && annotation.data.text}
          </div>
          <div style={{ fontWeight: "bold", fontSize: 13 }}>
            $ {annotation.data && annotation.data.monthlyrent}/month
          </div>
        </Col>
      </Row>
    </div>
  );
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
class Inspirations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: null,
      annotation: {},
      imageId: null,
      selectedData: null,
      showModal: false,
      annotations: [],
      deleteConfirmation: false,
      tempId: null,
      showPopup: false,
      activeAnnotations: [],
    };
  }

  onChange = (annotation) => {
    this.setState({ annotation });
    this.setState({ showPopup: true });
  };

  onCancel = () => {
    this.setState({ showPopup: false });
  };

  componentDidMount() {
    this.props.dispatch(GetInspirationListData());
    this.props.dispatch(getInspirationMaterialLIst());
  }

  getProductDetails = (id, name) => {
    this.props.history.push(`/furniture-detailed-page/${id}/${name}`);
  };

  removeSelection = (id) => {
    let data = this.state.selectedData.tags.filter((f, i) => i !== id);
    this.setState({
      selectedData: { ...this.state.selectedData, tags: data },
    });
  };

  onMouseOver = (id) => (e) => {
    this.setState({
      activeAnnotations: [...this.state.activeAnnotations, id],
    });
  };

  onMouseOut = (id) => (e) => {
    const index = this.state.activeAnnotations.indexOf(id);

    this.setState({
      activeAnnotations: [
        ...this.state.activeAnnotations.slice(0, index),
        ...this.state.activeAnnotations.slice(index + 1),
      ],
    });
  };

  activeAnnotationComparator = (a, b) => {
    return a.data.id === b;
  };

  onSubmit = (annotation) => {
    let getId = this.state.selectedData.tags.slice(-1).pop();
    const { geometry, data } = annotation;
    let parsedData = JSON.parse(data.text);

    let dataModel = {
      text: parsedData.maT_DESC,
      monthlyrent: parsedData.monthlyrent,
      thumbnailImagePath: parsedData.thumbnailImagePath,
      productId: parsedData.maT_CODE,
      id:
        getId === undefined || getId.data.id === undefined
          ? 1
          : getId.data.id + 1,
    };

    this.setState({
      annotation: {},
      selectedData: {
        ...this.state.selectedData,
        ...this.state.selectedData.tags.push({
          geometry: geometry,
          data: dataModel,
        }),
      },
    });
  };

  deleteInspirationItem = () => {
    this.props.dispatch(deleteInspiration(this.state.tempId));
    this.setState({ deleteConfirmation: false, tempId: null });
  };

  saveConfirmation = () => {
    let result = this.state.selectedData.tags.map(function (obj) {
      return {
        id: obj.data.id,
        x: obj.geometry.x,
        y: obj.geometry.y,
        productId:
          obj.data.productId === undefined
            ? obj.data.maT_CODE
            : obj.data.productId,
      };
    });
    let model = {
      imagedId: this.state.selectedData.imageId,
      tags: result,
    };
    this.props
      .dispatch(saveImageAnnotation(model))
      .then(() => this.setState({ showModal: false, showPopup: false }));
  };

  render() {
    const { Option } = Select;
    const { inspirationsList, inspirationsItems } = this.props;
    const {
      isHover,
      imageId,
      selectedData,
      showModal,
      showPopup,
      deleteConfirmation,
    } = this.state;

    this.onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = reorder(
        inspirationsList,
        result.source.index,
        result.destination.index
      );
      this.props.dispatch(addPositionData(items));
    };

    this.saveInspirationSequence = () => {
      let result =
        inspirationsList &&
        inspirationsList.map((m, i) => {
          return { imageID: m.imageId, seqNo: i + 1 };
        });
      let model = {
        inspirationSequence: result,
      };
      this.props.dispatch(saveInspirationInSequence(model));
    };

    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners inspirations">
          <div className="container mx-auto">
            <h1>Inspirations</h1>
            <p>
              Whether you're looking for bespoke luxury furniture in an Art Deco
              style or a beautifully handcrafted modern sofa, with our tailored
              selection of designer furniture from the world’s leading designer
              brands, we have a style to suit every home interior.
            </p>
          </div>
        </section>
        {/* page banners */}
        <section className="furniture-inspirations manage-inspirations">
          <div className="floating-buttons">
            <Button
              size="sm"
              type="primary"
              onClick={() => this.props.history.push("/create-inspirations")}
            >
              <i class="fa fa-plus" /> Add New Inspiration
            </Button>{" "}
            &emsp;
            <Button
              size="sm"
              variant="primary"
              onClick={this.saveInspirationSequence}
            >
              <i class="fa fa-save" /> Save Sequence
            </Button>
          </div>
          {/* <div className="container mx-auto">
                        <div className="inspirations-list"> */}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              droppableId="droppable"
              type="list"
              direction={"horizontal" || "vertical"}
            >
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <div className="container mx-auto">
                    <div className="inspirations-list">
                      {inspirationsList &&
                        inspirationsList.map((m, i) => (
                          <Draggable
                            draggableId={m.imageId.toString()}
                            index={i}
                            key={m.imageId}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className="items"
                                  onMouseOver={() =>
                                    this.setState({
                                      isHover: i,
                                      imageId: m.imageId,
                                    })
                                  }
                                  onMouseLeave={() =>
                                    this.setState({
                                      isHover: null,
                                      imageId: null,
                                    })
                                  }
                                >
                                  <div className="details">
                                    <div className="image">
                                      <img
                                        src={m.imagePath}
                                      />

                                      {isHover === i ? (
                                        <div className="hover-buttons">
                                          <Button
                                            size="sm"
                                            type="primary"
                                            onClick={() =>
                                              this.setState({
                                                showModal: true,
                                                selectedData: m,
                                              })
                                            }
                                          >
                                            <i class="fa fa-pencil" /> Edit
                                          </Button>
                                          <Button
                                            size="sm"
                                            type="danger"
                                            onClick={() =>
                                              this.setState({
                                                deleteConfirmation: true,
                                                tempId: m.imageId,
                                              })
                                            }
                                          >
                                            <i class="fa fa-trash-o" /> Delete
                                          </Button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </div>
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </section>

        <ModalBox
          title="Edit Inspiration"
          style={{ top: "20px" }}
          destroyOnClose
          width={1200}
          footer={null}
          visible={showModal}
          onOk={() => this.setState({ showModal: false })}
          onCancel={() => this.setState({ showModal: false })}
        >
          <Row className="edit-inspirations">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 6 }}>
              {selectedData && selectedData.tags.length !== 0 ? (
                <>
                  <div className="product-list-container">
                    {selectedData &&
                      selectedData.tags.map((m, i) => (
                        <p className="product-text">
                          <span
                            style={{ cursor: "pointer" }}
                            key={m.data.id}
                            onMouseOut={this.onMouseOut(m.data.id)}
                            oner={this.onMouseOver(m.data.id)}
                            onClick={() =>
                              this.getProductDetails(
                                m.data.productId === undefined
                                  ? m.data.maT_CODE
                                  : m.data.productId,
                                m.data.text
                              )
                            }
                          >
                            {m.data.text}
                          </span>{" "}
                          <span
                            onClick={() => this.removeSelection(i)}
                            className="delete-inspiration-item"
                          >
                            X
                          </span>{" "}
                        </p>
                      ))}
                  </div>
                  <Button
                    type="primary"
                    onClick={this.saveConfirmation}
                    style={{ marginTop: 15 }}
                  >
                    Update
                  </Button>
                </>
              ) : (
                <h3>No Annotations!</h3>
              )}
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 18 }}>
              <Annotation
                src={selectedData && selectedData.imagePath}
                className="annotation-image"
                alt="Invalid image"
                annotations={selectedData && selectedData.tags}
                type={PointSelector.TYPE}
                disableSelector={false}
                disableAnnotation={false}
                renderContent={renderContent}
                activeAnnotationComparator={this.activeAnnotationComparator}
                activeAnnotations={this.state.activeAnnotations}
                renderEditor={({ annotation: { geometry } }) => {
                  return (
                    <ModalBox
                      title="Select Product"
                      // content="test"
                      onCancel={this.onCancel}
                      visible={showPopup}
                      wrapClassName="main-search-area"
                      footer={null}
                      destroyOnClose
                    >
                      <div className="inspirations-product-select">
                        <SelectBox
                          style={{ width: '50%' }}
                          showSearch
                          placeholder={"Select an item"}
                          value={this.state.product}
                          options={inspirationsItems ? inspirationsItems.map(m => ({ label: m.maT_DESC, value: m.maT_CODE })) : []}
                          onChangeText={(product) => this.setState({ product })}
                          optional="true"
                        />
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <Button className="primary" onClick={this.onCancel}>
                          Cancel
                        </Button>{" "}
                        &emsp;
                        <Button
                          className="primary"
                          onClick={() => {
                            this.onSubmit({
                              geometry,
                              data: {
                                text: JSON.stringify(
                                  inspirationsItems.find(
                                    (pr) =>
                                      pr.maT_CODE ===
                                      this.state.product
                                  )
                                ),
                              },
                            })
                          }

                          }
                        >
                          Save
                        </Button>
                      </div>
                    </ModalBox>
                  );
                }}
                value={this.state.annotation}
                onChange={this.onChange}
              // onSubmit={this.onSubmit}
              />
            </Col>
          </Row>
        </ModalBox>

        <ModalBox
          title="Confirmation!"
          destroyOnClose
          visible={deleteConfirmation}
          onOk={this.deleteInspirationItem}
          onCancel={() => this.setState({ deleteConfirmation: false })}
        >
          <p>Are you sure you want to delete?</p>
        </ModalBox>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    inspirationsList: state.wishlist.inspirationsList,
    inspirationsItems: state.wishlist.inspirationsItems,
  };
}

export default connect(mapStateToProps)(withRouter(Inspirations));
