import { OrderTypes, AddressTypes } from '../Actions/ActionType';

const initialState = {
  orders: [],
  ordersHierarchy: {},
  openOrders: [],
  savedAddresses: [],
  getAllOpenOrders: {
    isFetching: false,
    orderData: [],
  },
  deliveryFee: [],
  customerDetails: {},
  selectedAddressId: '',
  promptOpenOrderSelection: false
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OrderTypes.SET_DELIVERY_FEE: {
      return {
        ...state,
        deliveryFee: action.payload
      }
    }
    case OrderTypes.SET_ORDERS: {
      const ordersHierarchy = {};
      if(action.payload && Array.isArray(action.payload)) {
        action.payload.map(order => {
          if (!ordersHierarchy[order.ordeR_NO]) {
            ordersHierarchy[order.ordeR_NO] = [];
          }
          if (order.ref_No && !ordersHierarchy[order.ref_No]) {
            ordersHierarchy[order.ref_No] = [];
          }
          if (order.ordeR_NO !== order.ref_No) {
            ordersHierarchy[order.ref_No].push(order);
          }
        })
      }
      return {
        ...state,
        orders: action.payload,
        ordersHierarchy
      }
    }
    case OrderTypes.SET_OPEN_ORDERS: {
      return {
        ...state,
        openOrders: action.payload
      }
    }
    case AddressTypes.SET_ADDRESS: {
      return {
        ...state,
        savedAddresses: action.payload
      }
    }

    case OrderTypes.GET_OPEN_ORDERS_LIST: {
      return {
        ...state,
        getAllOpenOrders: {
          ...state.getAllOpenOrders,
          isFetching: true,
        }
      }
    }

    case OrderTypes.PROMPT_OPEN_ORDERS_SELECTION: {
      return {
        ...state,
        promptOpenOrderSelection: action.payload
      }
    }

    case OrderTypes.SET_OPEN_ORDERS_LIST: {
      return {
        ...state,
        getAllOpenOrders: {
          ...state.getAllOpenOrders,
          isFetching: false,
          orderData: action.payload
        }
      }
    }

    case OrderTypes.GET_DETAILS: {
      return {
        ...state,
        customerDetails: action.payload
      }
    }
    
    case OrderTypes.SELECTED_ADDRESS_ID_FROM_CART: {
      return {
        ...state,
        selectedAddressId: action.payload
      }
    }

    default: {
      return state;
    }
  }
}