import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col, Spin } from 'antd';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


class FAQs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            QAs :[
                {
                heading : "Are the prices shown monthly prices?",
                panel : <div><p>All of Staging Depot Rental prices are for a 30 day rental period.							
                    Currently we are offering a fixed in-the-trade discount with a 1 month minimum.
                    If you commit to a 2 month rental, additional discounts are available.</p></div>
            },
            {
                heading : "Does Staging Depot deliver to my area?",
                panel : <div>
                    <p>We deliver to Charlotte, NC and the surrounding areas.													
                        Enter the zipcode of your staging location on the homepage (or when placing order) to confirm service in your area and to calculate delivery charge.													
                        There are several areas we are considering to open up for service, so please call us if your zipcode is not currently setup.</p>
                </div>
            },
            {
                heading : "How much is delivery?",
                panel : <div>
                    	<p>The delivery fee will be the same as the pickup fee, and both charged at time of the intial order based on $85/hr for 2 men and company truck																
	                        Delivery fee is calculated based on a combination of Drive Time (distance to/from staging location) and Moving Time(number of pieces).																
	                        Moving time is time to unrack furniture to w/h floor, wrap items, secure in truck, unload items from truck at location, unwrap, place in home.  And vice-versa for the return.																
	                        A typical job, say 3 rooms (Living Room, Bedroom, Dining Room) to a nearby location could range from $225-$425 each way based on $85/M for two mean in the company truck.
	                        <p><b>Special considerations:</b> Lack of parking, lots of stairs, or having to making many trips due to using an elevator will impact pricing. Please let us know ahead of time.</p>
                            <b>Disclaimer:</b> We will place furniture one time. Furniture must remain at to original location, no moving of inventory is allowed. 																	
	                        Moving of inventory without contacting Staging Depot represents a violation of the rental agreement and will incur additional fees.</p>
                </div>
            },
            {
                heading : "How soon can Staging Depot deliver?",
                panel : <div>
                    <p>
                    To allow for pulling of items, batching of orders and route planning, please plan on a 2 business day lead time from the time the order is booked (ie paid for) to delivery date.																
                    If you need next day delivery, you may contact us directly to determine if we can accommodate the request.																
                    Currently we plan on making 1 deliver/pickup in the AM, and 1 delivery/pickup in the PM M/W/F.																
                    We can quickly move to daily, and will scale up in accordance with demand.</p>
                </div>
            },
            {
                heading : "What does your delivery team do on-site?",
                panel : <div>
                    <p>Our delivery team will wear Staging Depot uniform, and carefully  place and arrange your rented pieces as per your direction ONE TIME .												
                        For beds, they will assemble.</p>
                </div>
            },
            {
                heading : "Are there things the deliver/pickup team won't do? Or things the stager might be liable for?",
                panel : <div>
                    <p>We do not currently hang artwork or pictures. The stager is responsible for having appropriate materials and will be responsible for improper installation.																				
                        Also, while we move items, we're not a moving company - please don't ask our guys to move homeowner items.  If you are in a pinch, reach out to the owner ahead of time if you have a need and we can try to help																				
                        if time allows.  Please let us know ahead of time how many stairs are involved so we can bring appropriate crew.																				
                        Lastly, if its an apartment or condo being staged, please give instructions on where to park. Try to get permission from landlord to avoid being responsible for parking tickets.</p>
                </div>
            },
            {
                heading : "Does Staging Depot have a minimum order requirement?",
                panel : <div>
                    <p>We do not, however given the drive time, it is generally not cost effective to rent only 1 or 2 items.									
	                    That said, if the items are small and can be delivered by 1 person then we'll adjust pricing.</p>
                </div>
            },
            {
                heading : "What forms of payment does Staging Depot accept?",
                panel : <div>
                    <p>We accept Visa and Mastercard. Payment is required at the time of web order to reserve furniture items and truck delivery window.																	
	                    Payments via Venmo are encouraged, but it is necessary to contact us via phone to finalize an order.  If not paying by credit card, we do still require a card on file for security.</p>
                </div>
            },
            {
                heading : "If we want to purchase the rented items, is that possible?",
                panel : <div>
                    <p>In the future, once supply chain issues are back to normal, we will look to stock appropriately to allow for sales of furniture.																
                        At the current time, many items are available to purchase, but please contact us prior toyour contact ending for buy-out prices if you are interested in purchasing the rented items.																
                        To set expectations, unless items are close to aging out, our prices may be higher than you may find online to cover time spent sourcing items as well as any freight/assembly.</p>
                </div>
            },
            {
                heading : "Can we pick up items instead of having them delivered to save money?",
                panel : <div>
                    <p>At Staging Depot, we pride ourselves in offering elegant furniture that is provided in like-new condition.  Due to damage liabilities, we do not allow for furniture to be picked up.																
                        Our delivery staff are insured and specially trained to protect our items, allowing us to keep rental prices low.																
                        If however, we are managing your inventory in our warehouse, then yes, you can always schedule a time to pick up items.</p>
                </div>
            },
            {
                heading : "Inflatable beds can be an inexpensive way to set up beds, but too many times deflate.  Are true mattresses available?",
                panel : <div>
                    <p>You will of course have the otpion to provide your own mattress & boxspring, but we offer the Bed Setup option as well.														
                        The Bed Setup consists of an 18" steel Boxspring, and 6" mattress for a total height of 24".  Tip: Some stagers reporting using 2 comforters gives ideal look.</p>
                </div>
            },
            {
                heading : "Do you rent beds or furniture for personal use or occupied stagings?",
                panel : <div>
                    <p>Due to hygiene and wear and tear, we only rent items for staging vacant homes. Items are to be used only for staging purposes, never for events or parties. Damages will result in maintenance charges.</p>
                </div>
            },
            {
                heading : "How far in advance can I place my orders?",
                panel : <div>
                    <p>We ask that you place your order online 3 to 7 days prior to your delivery date.  Payment for delivery, pickup, and 1st month rent are due at time of order to lock in the items and delivery date/time.																		
	                    You can reserve further in advance to guarantee items will be available, but charges will begin accruign in this case prior to delivery and you will need to call to make arrangements.</p>
                </div>
            },
            {
                heading : "Once items are in my cart, how long are they on hold for?",
                panel : <div>
                    <p>Currently, they are not put on hold until a paid order is placed. For this reason, you may quote a job, only to find out 3 days later the exact items are no longer available.															
	                    Our business plan calls for us to be able to support over 100 homes per month and we'll scale to meet the future demand to avoid these situations.</p>
                </div>
            },
            {
                heading : "Is there \"in the trade\" pricing? Are there discounts for multiple month rentals.",
                panel : <div>
                    <p>Absolutely - our business was created for real estate professionals - home stagers, designers, builders,and realtors.															
                        Once you've verified as in the trade and sign confidentiality agreement, we will share special pricing information. 															
                        <b>Note: </b>Our original pricing model called for decreases by month - but given most homes are selling now in less than 2 months, we've moved to a fixed fee model.</p>
                </div>
            },
            {
                heading : "What is the \"COOP\"?",
                panel : <div>
                    <p>Whereas Staging Depot inventory is new inventory that ages out within 2 years of use, COOP inventory is typically older and gently used. Proceeds from COOP inventory go back to the owner of the item.																			
                        In order to enter items in the COOP, you must be a "Staging Operations" customer - in other words, you pay to have us store your inventory at our warehouse, manage the items in our technology system, with																			
                        access to our dedicated team of trained movers.  Only our movers are authorized to move Staging Depot and COOP inventory, but Staging Operations clients are free to pickup/dropoff their own items.</p>
                </div>
            }
        ]
        }
        
    }


    render() {
        return (
            <React.Fragment>
                {/* page banners */}
                <section className="page-banners furniture">
                    <div className="container mx-auto">
                        <h1>FAQ’s</h1>
                
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-wave">
                    <div className="container mx-auto">
                        
                    <Accordion allowZeroExpanded>
    {this.state.QAs.map((item) => (
        <AccordionItem key={item.uuid}>
            <AccordionItemHeading>
                <AccordionItemButton>
                    {item.heading}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {item.panel}
            </AccordionItemPanel>
        </AccordionItem>
    ))}
</Accordion>     
                    </div>
                </section>
            </React.Fragment >
        )
    }
}
// function mapStateToProps(state) {
//     return {
//         furnitureGroups: state.furniture.furnitureGroups
//     }
// }
export default withRouter(FAQs)