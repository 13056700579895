import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import OffersSlider from './OffersSlider/OffersSlider';
import OffersForyou from './OffersForyou/OffersForyou';
import OffersonCard  from './OffersonCard/OffersonCard';
import './Offers.scss';
import { getOfferFurnitureListing } from '../../Actions/FurnitureActions';

class Offers extends React.Component {
    componentDidMount() {
        this.props.dispatch(getOfferFurnitureListing(this.props.userLocation ? this.props.userLocation.ziP_CODE : null));
    }
    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners offers ">
                    <div className="container mx-auto">
                        <h1>Offers</h1>
                        <p>Our Furniture Includes Luxurious Chairs, Sturdy Desks, Chic Wardrobes, And Comfortable Beds.</p>
                        <p>You Will Find Pre-Selected, Tasteful Furniture For Your Kitchen, Living Room, Dining Room, And Bedroom. </p>
                    </div>
                </section>
                {/* page banners */}

                <div className="inner-pages top-left-wave light-bg">
                    <div className="container mx-auto">
                        {/* <OffersSlider /> */}

                        <section className="offers-for-you" style={{ textAlign: 'center' }}>
                            <h2>Coming Soon</h2>
                            {/* {
                                this.props.furnitureList.length === 0 ?
                                <span>No offers for you at this moment</span>
                                :
                                <OffersForyou furniture={this.props.furnitureList} />
                            } */}
                        </section>

                        {/* <section className="special-deals">
                            <h2>Special Deals</h2>
                            <OffersForyou furniture={this.props.furnitureList} />
                        </section>

                        <section className="special-deals">
                            <h2>Offers On Cards</h2>
                            <OffersForyou furniture={this.props.furnitureList} />
                        </section> */}
                    </div>
                </div>


            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    return {
        furnitureList: state.furniture.offerFurnitureList,
        userLocation: state.users.userLocation
    }
}
export default connect(mapStateToProps)(withRouter(Offers))