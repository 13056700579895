import React from 'react';
import { connect } from 'react-redux';
import {
    Link
} from 'react-router-dom';
import { getBundleListing } from '../../Actions/BundleActions';
import { getTestimonials } from '../../Actions/UserActions';
import { getPopularMaterials } from '../../Actions/WishListActions';
import BundlesHome from './BundlesHome/BundlesHome';
import './Home.scss';
import HomeSlider from './HomeSlider/HomeSlider';
import HowitWorks from './HowitWorks/HowitWorks';
import PopularProducts from './PopularProducts/PopularProducts';
import RentYourFurnitures from './RentYourFurnitures/RentYourFurnitures';
import StagingDepotCommitment from './StagingDepotCommitment/StagingDepotCommitment';
import Testimonials from './Testimonials/Testimonials';

class Home extends React.Component {
    componentDidMount() {
        if (this.props.bundlesList.length === 0) {
            this.setState({ loading: true });
            this.props.dispatch(getBundleListing()).then(() => {
                this.setState({ loading: false });
            })
            const selectedLocation = this.props.userLocation ? this.props.userLocation.ziP_CODE : 0
            let isDatacontext = sessionStorage.getItem('itop-user-context') ? true : false
            if (isDatacontext === true) {
                this.props.dispatch(getPopularMaterials(selectedLocation))
            }
        }
        this.props.dispatch(getTestimonials());
    }
    render() {
        return (
            <React.Fragment>
                {/* main slider starts */}
                <HomeSlider />
                {/* main slider ends */}

                {/* home links section starts */}
                <section className="our-features">
                    <div className="container mx-auto">
                        <div className="features-list">
                            <div className="list">
                                <Link to="/furniture">
                                    <div className="details">
                                        <img src={require("../../assets/images/icons/furniture.svg")} alt="Furniture" />
                                        <p>Rental</p>
                                    </div>
                                </Link>
                            </div>
                            {/* list ends */}
                            
                            <div className="list">
                                <Link to="/inspirations">
                                    <div className="details">
                                        <img src={require("../../assets/images/icons/inspirations.svg")} alt="Inspirations" />
                                        <p>Inspirations</p>
                                    </div>
                                </Link>
                            </div>
                            {/* list ends */}
                            <div className="list">
                                <Link to="/bundles">
                                    <div className="details">
                                        <img src={require("../../assets/images/icons/bundles.svg")} alt="Bundles" />
                                        <p>Bundles</p>
                                    </div>
                                </Link>
                            </div>
                            {/* list ends */}
                            <div className="list">
                                <Link to="/designer-services">
                                    <div className="details">
                                        <img src={require("../../assets/images/icons/designer-service.svg")} alt="Designer Service" />
                                        <p>Designer Services</p>
                                    </div>
                                </Link>
                            </div>
                            {/* list ends */}
                            <div className="list">
                                <Link to="/offers">
                                    <div className="details">
                                        <img src={require("../../assets/images/icons/offers.svg")} alt="Offers" />
                                        <p>Offers</p>
                                    </div>
                                </Link>
                            </div>
                            {/* list ends */}

                            { !this.props.context && <div className="list">
                                <Link to="/about-us">
                                    <div className="details">
                                        <img src={require("../../assets/images/icons/about-us.svg")} alt="About us" />
                                        <p>About us</p>
                                    </div>
                                </Link>
                            </div> }
                            {/* list ends */}
                            { this.props.context && <div className="list">
                                <Link to="/live-orders">
                                    <div className="details">
                                        <img src={require("../../assets/images/icons/furniture.svg")} alt="Furniture" />
                                        <p>My Dashboard</p>
                                    </div>
                                </Link>
                            </div> }
                        </div>
                    </div>
                </section>

                {/* how it works starts*/}
                <div id="how-it-works">
                    <HowitWorks />
                </div>
                {/* how it works ends*/}

                {/* PopularProducts starts */}
                <PopularProducts products={this.props.popularItems} />
                {/* Popular Products ends */}

                {/* Bundles starts */}
                {/* <BundlesHome bundles={this.props.bundlesList} /> */}
                {/* Bundles starts */}

                {/* RentYourFurnitures starts */}
                <RentYourFurnitures />
                {/* RentYourFurnitures ends */}

                {/* Testimonials starts */}
                <div id="testimonials">
                    <Testimonials testimonials={this.props.testimonials} />
                </div>
                {/* Testimonials ends */}

                {/* StagingDepotCommitment starts */}
                <StagingDepotCommitment />
                {/* StagingDepotCommitment ends here */}

            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
      profile: state.login.profile,
      context: state.login.context,
      bundlesList: state.bundles.bundlesList,
      popularItems: state.wishlist.popularItems,
      userLocation: state.users.userLocation,
      testimonials: state.users.testimonials
    }
}
  
export default connect(mapStateToProps)(Home)