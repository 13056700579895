import { UtilsType } from '../Actions/ActionType';

const initialState = {
  notification: undefined
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UtilsType.SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.notification
      }
    }
    default: {
      return state;
    }
  }
}