import React from "react";
import CreditCardInput from "react-credit-card-input";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { LoadingOutlined, PlusOutlined, SaveFilled } from "@ant-design/icons";
import { Button, Col, Divider, Row } from "antd";
import Cards from "./Cards";
import { deleteCard, getCards, saveNewCard } from "../../Actions/CardActions";

class CardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card: false,
      cards: [],
      cardLoading: false,
      newCard: {
        cardNumber: "",
        expDate: "",
        cvV2CVC2: "",
      },
      selectedCard: undefined,
    };
  }

  componentDidMount() {
    this.setState({ cardLoading: true });
    let isDatacontext = sessionStorage.getItem("itop-user-context")
      ? true
      : false;
    if (isDatacontext === true){
      this.props.dispatch(getCards()).then(() => {
        this.setState({ cardLoading: false });
      });
    }
  }

  handleCardDetailsChange = (event, field) => {
    this.setState({
      newCard: { ...this.state.newCard, [field]: event.target.value },
    });
  };

  saveCard = () => {
    this.setState({ cardLoading: true });

    this.props.dispatch(saveNewCard(this.state.newCard)).then((id) => {
      this.setState({
        cardLoading: false,
        selectedCard: id,
        card: false,
        newCard: {
          cardNumber: "",
          expDate: "",
          cvV2CVC2: "",
        },
      });
    });
  };

  deleteCard = (id) => {
    this.setState({ cardLoading: true });
    this.props.dispatch(deleteCard(id)).then(() => {
      this.setState({ cardLoading: false, selectedCard: undefined });
    });
  };

  selectCard = (id) => {
    this.setState({ selectedCard: id });
  };

  render() {
    return (
      <div>
        {!this.props.allowSelect && (
          <h2 className="margin-bottom10">Card Details</h2>
        )}
        <div className="address-details card margin-bottom20">
          <div>
            {this.state.cardLoading && <LoadingOutlined />}
            <Button
              className="margin-top20"
              type="link"
              onClick={() => this.setState({ card: true })}
            >
              <PlusOutlined /> Add New Card
            </Button>
            { this.props.allowSelect && this.props.customerInfo && this.props.customerInfo.isPayLater && <div className="action-buttpm text-right">
              <Button
                type="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  this.props.handleSave(null);
                }}
              >
                Pay Later
              </Button>
            </div> }
            {this.state.card === true ? (
              <div className="credit-card">
                <CreditCardInput
                  cardNumberInputProps={{
                    value: this.state.newCard.cardNumber,
                    onChange: (val) =>
                      this.handleCardDetailsChange(val, "cardNumber"),
                  }}
                  cardExpiryInputProps={{
                    value: this.state.newCard.expDate,
                    onChange: (val) =>
                      this.handleCardDetailsChange(val, "expDate"),
                  }}
                  cardCVCInputProps={{
                    value: this.state.newCard.cvV2CVC2,
                    onChange: (val) =>
                      this.handleCardDetailsChange(val, "cvV2CVC2"),
                  }}
                  fieldClassName="input"
                />
                <div className="edit-delete-action">
                  {!this.state.cardLoading &&
                    this.state.newCard.cardNumber &&
                    this.state.newCard.expDate &&
                    this.state.newCard.cvV2CVC2 && (
                      <SaveFilled
                        onClick={this.saveCard}
                        title="Save Card"
                        style={{
                          fontSize: "25px",
                          cursor: "pointer",
                          color: "#522e6c",
                          margin: "10px",
                        }}
                      />
                    )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {this.props.allowSelect && <Divider>OR</Divider>}
          {this.props.allowSelect && (
            <h4 className="margin-bottom10">
              Please choose the card and proceed to payment
            </h4>
          )}
          {this.props.cards && this.props.cards.length === 0 && (
            <h4>No saved cards found.</h4>
          )}
          <Row>
            {this.props.cards.map((card) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 18 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}
                xxl={{ span: 12 }}
              >
                <div
                  onClick={() => this.selectCard(card.id)}
                  style={{
                    margin: "3px",
                    border:
                      this.state.selectedCard === card.id
                        ? "2px solid #522e6c"
                        : "",
                    cursor: "pointer",
                  }}
                >
                  <Cards card={card} handleDelete={this.deleteCard} />
                </div>
              </Col>
            ))}
          </Row>
          {this.props.allowSelect && (
            <div className="action-buttpm text-right">
              <Button
                type="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  this.props.handleSave(this.state.selectedCard);
                }}
                disabled={!this.state.selectedCard}
              >
                Review Order
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    cards: state.users.cards,
    customerInfo: state.users.customerInfo,
  };
}
// export default withRouter(PersonalDetails)
export default connect(mapStateToProps)(withRouter(CardDetails));
