import React from 'react';
import { withRouter } from 'react-router';
import './HowitWorks.scss';
import { Row, Col } from 'antd';

class HowitWorks extends React.Component {

    render() {
        return (
            <React.Fragment>
                <section className="how-it-works">
                    <div className="container mx-auto">
                        <div className="section-title">
                            <h1>How it Works</h1>
                        </div>

                        <div className="working-steps">
                            <Row align="center">
                                <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                    <div className="steps">
                                        <img style={{ width: '100px', height: '80px' }} src={require("../../../assets/images/rent-furniture/cottage_black1.png")} alt="Rent" />
                                        <h3>Rent</h3>
                                        <p>Browse & select items from our website</p>
                                    </div>
                                </Col>
                                <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                    <div className="steps">
                                        <img style={{ width: '100px', height: '80px' }} src={require("../../../assets/images/how-it-works/shipping.svg")} alt="We Deliver" />
                                        <h3>We Deliver</h3>
                                        <p>We deliver on scheduled date & time,  place your items (and handle pickup too)</p>
                                    </div>
                                </Col>
                                <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
                                    <div className="steps">
                                        <img style={{ width: '100px', height: '80px' }} src={require("../../../assets/images/how-it-works/home.svg")} alt="You Stage" />
                                        <h3>You Stage</h3>
                                        <p>Meet us on install day and accessorize with your decorative items</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default withRouter(HowitWorks)