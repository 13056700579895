import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Dropdown, Input, Menu, message, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { UtilsType } from '../Actions/ActionType';
import { getFurnitureListing, getFurnitureSubGroups } from '../Actions/FurnitureActions';
import { saveTestimonial } from '../Actions/UserActions';
import mainlogo from '../assets/images/staging-depot.png';
import ModalBox from '../Components/ModalBox/ModalBox';
import SelectLocation from '../Screens/SelectLocation/SelectLocation';
import NewTestimonial from '../Screens/SigninSignup/NewTestimonial';
import SigninSignup from '../Screens/SigninSignup/SigninSignup';
import './Header.scss';


export let setHeader = false
class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
            openMenu: false,
            signinsignup: false,
            headerView: false,
            searchText: '',
            name: '',
            quickView: false
        };
    }
    togglemenu = () => {
        const x = document.getElementById("mobile-menu");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps.notification) {
            this.openNotification(newProps.notification)
        }
    }
    openNotification = (notification) => {
        if (notification.type === 'ERROR')
            message.error(notification.message);
        else
            message.success(notification.message)

        setTimeout(() => {
            this.props.dispatch({ type: UtilsType.SET_NOTIFICATION, notification: undefined })
        }, 1)
    };
    onCancel = (stateVar) => {
        this.setState({ [stateVar]: false });
    }
    userLogout = () => {
        sessionStorage.removeItem('itop-profile');
        sessionStorage.removeItem('itop-user-context');
        window.location.href = "/";
    }
    handleSearch = (event) => {
        if (event.key === "Enter") {
            if (this.state.searchText && this.state.searchText.length > 3) {
                const zipCode = this.props.userLocation ? this.props.userLocation.ziP_CODE : null;
                const furnitureCall = this.props.dispatch(getFurnitureListing(this.state.searchText, zipCode));
                const subGroupsCall = this.props.dispatch(getFurnitureSubGroups());
                Promise.all([furnitureCall, subGroupsCall]).then(() => {
                    this.props.history.push(`/furniture-listing/${this.state.searchText}`);
                });
            } else {
                this.props.dispatch({ type: UtilsType.SET_NOTIFICATION, notification: { type: "ERROR", message: "Please enter at least 4 characters" } })
            }
        }
    }
    // onCancel = () => {
    //     this.setState({ quickView: false });
    // }
    saveTestimonial = (testimonial) => {
        this.props.dispatch(saveTestimonial(testimonial)).then(result => {
            this.onCancel('quickView');
        })
    }
    handleOpenQuickView = () => {
        this.setState({ quickView: true })
    }
    render() {
        const { location } = this.props;
        const menu = (
            <Menu>
                <Menu.Item key="0">
                    <Link to="/personal-details">Personal Details</Link>
                </Menu.Item>
                <Menu.Item key="0">
                    <Link to="/live-orders">My Dashboard</Link>
                </Menu.Item>
                {
                    this.props.customerInfo && this.props.customerInfo.hasInventory && <Menu.Item key="2">
                        <Link to="/my-inventory">My Inventory</Link>
                    </Menu.Item>
                }
                {
                    this.props.customerInfo && this.props.customerInfo.isAdmin && <Menu.Item key="3">
                        <Link to="/manage-inspirations">Manage Inspirations</Link>
                    </Menu.Item>
                }

                <Menu.Item key="4" onClick={() => this.handleOpenQuickView()}>
                    <span>Feedback</span>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="5" onClick={() => this.userLogout()}>Signout</Menu.Item>
            </Menu>
        );

        return (
            <React.Fragment>
                <div className={`main-hearder transparent-hearder ${location.pathname !== '/' ? 'normal-page' : 'sticky-nav'}`}>
                    <div className="container mx-auto">
                        <div className="top-header">
                            <Row>
                                <Col xs={{ span: 16, order: 1 }} sm={{ span: 16, order: 1 }} md={{ span: 8, order: 1 }} lg={{ span: 12, order: 1 }}>
                                    <div className="left-section">
                                        <div className="logo">
                                            <Link to="/"><img src={mainlogo} alt="Staging Depot" /> </Link>
                                        </div>
                                        <div className="select-location" >
                                            <Link to="/" onClick={() => this.setState({ open: true })}><i className="fa fa-map-marker" /><span>{this.props.userLocation ? this.props.userLocation.ziP_DESC : 'Staging Location'}</span></Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={{ span: 24, order: 3 }} sm={{ span: 16, order: 3 }} md={{ span: 6, order: 3 }} lg={{ span: 7, order: 2 }}>
                                    <div className="right-navigation">
                                        <div className="search">
                                            <Input
                                                placeholder="Can search by color / size / style or product"
                                                id="search-keyword"
                                                onKeyUp={this.handleSearch}
                                                value={this.state.searchText}
                                                onChange={(event) => this.setState({ searchText: event.target.value })}
                                                suffix={<SearchOutlined />}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={{ span: 8, order: 2 }} sm={{ span: 8, order: 2 }} md={{ span: 6, order: 2 }} lg={{ span: 2, order: 3 }}>
                                    <div className="cart-holder">
                                        {<Badge count={this.props.cartItemsCount ? this.props.cartItemsCount : ""}>
                                            <div>
                                                <div className="shopping-cart">
                                                    <Link to="/my-cart"><i className="flaticon-shopping-cart" /></Link>
                                                </div>
                                            </div>
                                        </Badge>}

                                        <div id="hamburger" className={this.state.openMenu ? "menuopened" : "mobile-menubar"} onClick={() => this.setState({ openMenu: !this.state.openMenu })}></div>
                                    </div>
                                </Col>
                                <Col xs={{ span: 24, order: 4 }} sm={{ span: 8, order: 4 }} md={{ span: 4, order: 4 }} lg={{ span: 3, order: 4 }}>
                                    {
                                        !this.props.context && !this.state.signinsignup && <div className="signin-signup">
                                            <Button onClick={() => this.setState({ signinsignup: true })} type="primary">Sign in / Sign up</Button>
                                        </div>
                                    }
                                    {
                                        this.props.profile &&
                                        <div className="profile" style={{ marginLeft: '2%' }}>
                                            <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']} arrow>
                                                <div className="profile-details">
                                                    <i className="flaticon-user-2" />
                                                    <DownOutlined />
                                                </div>
                                            </Dropdown>
                                        </div>
                                    }

                                </Col>
                            </Row>
                        </div>
                        {/* bottom header starts */}
                        {
                            // location.pathname == '/' &&
                            <div id="mobile-menu" className={this.state.openMenu ? "bottom-header opened" : "bottom-header"}>
                                <div className="menu-container">
                                <ul>
                                    <li>
                                        <Link to="/furniture">Rental</Link>
                                    </li>
                                    <li>
                                        <Link to="/inspirations">Inspirations</Link>
                                    </li>
                                    <li>
                                        <Link to="/bundles">Bundles</Link>
                                    </li>
                                    <li>
                                        <Link to="/designer-services">Designer Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/offers">Offers</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">About us</Link>
                                    </li>
                                    {this.props.context &&
                                        <li>
                                            <Link to="/live-orders">My Dashboard</Link>
                                        </li>
                                    }
                                </ul>
                                </div>
                            </div>
                        }
                        {/* bottom header ends */}

                    </div >
                </div >
                <ModalBox
                    title="Charlotte Area"
                    content="test"
                    visible={this.state.open}
                    width={800}
                    wrapClassName="main-search-area"
                    onCancel={() => this.onCancel('open')}
                    footer={null}
                    destroyOnClose>
                    <SelectLocation handleClose={() => this.setState({ open: false })} />
                </ModalBox>

                <ModalBox
                    content="test"
                    visible={!!this.state.signinsignup}
                    width={900}
                    onCancel={() => this.onCancel('signinsignup')}
                    footer={null}
                    destroyOnClose>
                    <SigninSignup handleClose={() => this.setState({ signinsignup: false })} />
                </ModalBox>
                <ModalBox
                    visible={this.state.quickView}
                    width={854}
                    wrapClassName="main-search-area"
                    onCancel={() => this.onCancel('quickView')}
                    footer={null}
                    destroyOnClose>
                    <NewTestimonial handleSave={this.saveTestimonial} handleClose={this.onCancel} />
                </ModalBox>
            </React.Fragment >
        );
    }
}
function mapStateToProps(state) {
    return {
        userLocation: state.users.userLocation,
        cartItemsCount: state.cart.cartItems.length,
        notification: state.utils.notification,
        context: state.login.context,
        customerInfo: state.users.customerInfo
    }
}
export default connect(mapStateToProps)(withRouter(Header))