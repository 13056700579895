import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button, Collapse } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './OpenOrders.scss';
import SideNavigation from '../MyCart/SideNavigation/SideNavigation';
import OrderDetails from './OrderDetails';
import { getOpenOrderList, removeOpenOrder } from '../../Actions/OrderActions';
import { connect } from 'react-redux';

const { Panel } = Collapse;

class OpenOrders extends React.Component {

    componentDidMount() {
        let isDatacontext = sessionStorage.getItem('itop-user-context') ? true : false
        if (isDatacontext === true) {
            this.props.dispatch(getOpenOrderList())
        }
    }

    handleDeleteOpenOrder = (orderNo) => {
        this.props.dispatch(removeOpenOrder(orderNo))
    }

    getFormattedDate = (date) => {
        const dateArr = date ? date.split('-') : ["1970","01","01"]
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
    }

    genExtra = (item) => {
        const total = item.costDetails && item.costDetails.find(cost => cost.isTotal === "1");
        const totalCost = total ? total.amount : 0;
        return <div className="action-items" style={{ marginTop: '10%'}}
            onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
            }}
        >
            <div className="total-items">
                <span>${totalCost}</span>
            </div>
            <div className="clone-and-delete">
                <Button type="link" onClick={() => this.handleDeleteOpenOrder(item.ordeR_NO)}><i className="flaticon-delete-1" /></Button>
            </div>
        </div>
    };

    // getFormattedAddress = (address) => {
    //     return <div>
    //                 <p>{address.address1}</p>
    //                 <p>{address.address2}</p>
    //                 <p>{address.city}, {address.state}&nbsp;&nbsp;{address.pincode}</p>
    //             </div>
    // }
    getFormattedDate = (date) => {
        const dateArr = date ? date.split('-') : ["1970","01","01"]
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
    }
    render() {

        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <section className="open-orders">
                            <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <SideNavigation context={this.props.context} />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                    <h2 className="margin-bottom20">Draft Orders</h2>
                                    {
                                        !this.props.allOpenOrders.length ? "No draft orders found" :
                                            <div className="open-orders-list">
                                                <Collapse
                                                    defaultActiveKey={['1']}
                                                    expandIconPosition='right'
                                                    expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
                                                    ghost
                                                >
                                                    {
                                                        this.props.allOpenOrders && this.props.allOpenOrders.map((list, index) =>
                                                            <Panel header={
                                                                <Row style={{ width: '75%', float: 'left'}}>
                                                                    <Col style={{ width: '50%', float: 'left' }}>
                                                                        <div>{list.addressDetails?.address1}</div>
                                                                        <div>{list.addressDetails?.address2}</div>
                                                                        <div>{list.addressDetails?.city}&nbsp;&nbsp; {list.addressDetails?.state}&nbsp;&nbsp;{list.addressDetails?.pincode}</div>
                                                                    </Col>
                                                                    <Col style={{ marginTop: '3%', fontSize: '15px'}}>{list.createdon ? this.getFormattedDate(list.createdon) : ''}</Col>
                                                                </Row>} 
                                                                key={index + 1} 
                                                                extra={this.genExtra(list)}
                                                            >
                                                                <OrderDetails cartItems={this.props.cartItems} data={list} costDetails={list.costDetails} />
                                                            </Panel>
                                                        )
                                                    }
                                                </Collapse>
                                            </div>
                                    }
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    return {
        allOpenOrders: state.orders.getAllOpenOrders.orderData,
        openordersLoaded: state.orders.getAllOpenOrders.isFetching,
        cartItems: state.cart.cartItems,
        context: state.login.context
    }
}
export default connect(mapStateToProps)(withRouter(OpenOrders))