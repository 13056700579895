import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import QuickLinks from '../QuickLinks/QuickLinks';

class AboutUs extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/* page banners */}
                <section className="about-banners">
                    <img src={require('../../assets/images/page-banners/banner.png')} alt="About Us" />
                </section>
                {/* page banners */}

                <div className="inner-pages padding-40 top-left-wave light-bg">
                    <div className="container mx-auto">

                        <QuickLinks />

                        <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                <p>We currently help support the local Designer community with Receiving & Delivery  to survive the pandemic, which also helps us keep up on trends.</p>
                                <p>That said, Staging Depot was founded primarily to help stagers, designers, builders, real estate agent clients to cost efficiently stage and sell their properties using rental furniture.</p>
                                <h3>We are transparent</h3> 
                                <p>What you see is what you get. No hidden fees, we lay everything out. You can start shopping right away, and figuring out what you need, and the only time we take your information, is when you are ready to order. We aim to use technology to make our site easy to use - order, schedule, pay..done.  We offer a reasonable monthly rental and make life easy. All of our items are curated with staging in mind, and our business plan calls for us to acquire new pieces and room packages every month so you will be able to find a variety . We are constantly looking for new collection ideas. If you have something you want, please let us know. We will try to get them for you! Please keep coming back for new looks and collections.</p>
                                <p>We are priced with staging in mind, not a lease to own situation - you are not bound to 6 months at a time to get a special deal. However, if that is what you need to stage a model home, or a higher end home, we can work with you on pricing for that. Best of all, we are a white glove service. We are tailoring our unique experience with staging in mind. You will order online, have it delivered to your customer’s property, and we will place the items in the home.</p>
                                <p>You get to do what you love, accessorize and help client’s sell homes.</p>
                                <h3>New to vacant staging?</h3>
                                <p>Staging Depot allows you to stage without having to commit to spending major capital to acquire items. More importantly, you can avoid what are the more significant ongoing warehousing costs No need to rent or buy trucks, nor deal with the stress of movers not showing up or damaging items. No need for extra insurance. We've got it covered. The big rental companies talk a good game, but often aren't in sync with the Staging Community. We are here to make things better. We took on the challenge using VNA pallet rack design and invested in a state of the art wire guided furniture picker platform.  We also invested in custom software allowing us to make this happen.</p>
                                <p>This also means for those with inventory, use us when low on inventory to stage more homes without committing to purchase. Better yet, consider getting out of the warehousing business by using us for Staging Operations! With our inventory management,  we store your items and handle the scanning & tagging. You can instantly see online what you have available. Stop worrying about losing time constantly rearranging items to get to the back of  a storage unit to find a piece, and the extra wear & tear dealing with old items that are broken or not longer in style. Better yet, monetize your sunk costs by letting others rent. It's totally your choice to make items available or not in the COOP.  Any by joining Staging Operations, you get access to our scheduling system with access to our movers.</p>
                                <p>Lastly, we partner with Men's Shelter and Women’s shelter. Our gently used items will be recycled and retired, to those who are transitioning from homeless to homeowner.</p>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <img src={require('../../assets/images/about-us.webp')} alt="About Us" />
                            </Col>
                        </Row>
                    </div>
                </div>


            </React.Fragment >
        )
    }
}
export default withRouter(AboutUs)