import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Divider, Checkbox, Slider, Button, Input, InputNumber, Switch } from 'antd';
import DateBox from '../../../Components/DateBox/DateBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import { connect } from 'react-redux';
import { setProductAvailability, resetAllFilters, addSubGroupFilter } from '../../../Actions/BundleActions';
import moment from 'moment';

const marks = {
    1: '$ 1',
    500: '$ 500'
};
class Filter extends React.Component {

    constructor() {
        super()
        this.state = {
            fromDate: '',
            durations: [],
            defaultActiveKey: [],
            symbolsArr: ["e", "E", "+", "-", "."]
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    getProductFilter = (e, type) => {
        if (type === 'available') {
            this.props.dispatch(setProductAvailability({ data: e.target.checked, type: type }));
        }
        else {
            this.props.dispatch(setProductAvailability({ data: e, type: type }));
        };
    };

    handleQuantityChange = (value, type) => {
        const isInteger = /^[0-9]+$/;
        if (value === '' || isInteger.test(value)) {
            if (value !== null) {
                this.props.dispatch(setProductAvailability({ data: value, type: type }));
            }
        }
        else {
            this.props.dispatch(setProductAvailability({ data: '', type: type }));
        };
    };

    disabledDate = (current) => {
        const minDate = moment().subtract(1, 'days');
        return (minDate.isAfter(current));
    };

    render() {

        const { allFilters } = this.props;

        return (
            <React.Fragment>
                <div className="Filter">
                    <Row className="filter-heading">
                        <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <h1><i className="fa fa-filter" /> Filters</h1>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <Button type="secondary" onClick={() => this.props.dispatch(resetAllFilters())}>Reset</Button>
                        </Col>
                    </Row>
                    {/* filter heading ends */}

                    <Divider />

                    <div className="filter-sections">
                        <h4>View By Availability</h4>
                        {/* <p>Look For Items Available During This Period (Your Rental Duration)</p>
                        <div className="form-fields">
                            <DateBox
                                label="From"
                                // placeholder="12/08/20"
                                id="fromDate"
                                // value={allFilters.availableDate}
                                onChangeText={(e) => this.getProductFilter(e, 'date')}
                                optional="true"
                                disabledDate={this.disabledDate}
                            />
                        </div> */}
                        {/* <div className="form-fields"> */}
                            {/* <SelectBox
                                label="Durations"
                                id="durations"
                                value={this.state.durations}
                                options={this.state.durations}
                                onChangeText={this.onChangeText}
                                optional="true"
                            /> */}

                            {/* <label>Durations</label>
                            <InputNumber
                                min={1}
                                parser={(value) => {
                                    return value.slice(0, 2)
                                }}
                                value={allFilters.duration}
                                onChange={(e) => this.handleQuantityChange(e, 'duration')}
                                onKeyPress={e => this.state.symbolsArr.includes(e.key) && e.preventDefault()}
                            />
                        </div> */}
                        <div className="form-fields">
                            <label>
                                <Checkbox style={{ float: 'left', margin: '3px' }} checked={allFilters.productAvailable} onChange={(e) => this.getProductFilter(e, 'available')} />
                                <span>Show Only Available Products</span>
                            </label>
                        </div>
                    </div>
                    <Divider dashed />
                    <div className="filter-sections margin-bottom20">
                        <div className="options-type">
                            { this.props.customerInfo && this.props.customerInfo.hasInventory && <div className="list-item">
                                <label>My Inventory <Switch size="small" checked={allFilters.myInventory} onChange={(e) => this.getProductFilter(e, 'myInventory')} /></label>
                                <p>See my items managed by Staging Depot.</p>
                            </div> }
                            <div className="list-item">
                                <label>Depot Inventory <Switch checked={allFilters.depotInventory} onChange={(e) => this.getProductFilter(e, 'depotInventory')} size="small" /></label>
                                <p>See inventory owned by Staging Depot.</p>
                            </div>
                            <div className="list-item">
                                <label>COOP Inventory <Switch checked={allFilters.coopInventory} onChange={(e) => this.getProductFilter(e, 'coopInventory')} size="small" /></label>
                                <p>See inventory from other Stagers managed by Staging Depot.</p>
                            </div>
                        </div>
                    </div>
                    <Divider dashed />
                    <div className="filter-sections">
                        <h4>Price Range</h4>
                        <div className="price-range">
                            <Slider
                                marks={marks}
                                min={0}
                                max={3000}
                                value={allFilters.priceRange ? allFilters.priceRange : 500}
                                onChange={(e) => this.getProductFilter(e, 'priceRange')}
                            />
                        </div>
                    </div>

                    <Divider dashed />
{/* 
                    <div className="filter-sections">
                        <h4>Choose Bundle</h4>
                        <Button type="secondary">Standard Bundle</Button>
                        <Button type="secondary">Customize Bundle</Button>
                    </div>

                    <Divider dashed /> */}

                    {/* <div className="filter-sections">
                        <h4>Choose Apartment Type</h4>
                        <div className="options-type">
                            <div className="options-list">
                                <Checkbox>Studio</Checkbox>
                                <Checkbox>1 Bed Room</Checkbox>
                                <Checkbox>2 Bed Room</Checkbox>
                            </div>
                        </div>
                    </div>

                    <Divider /> */}

                    <div className="filter-sections">
                        <h4>Choose Room Type</h4>
                        <div className="options-type">
                            <div className="options-list">
                                {
                                    this.props.bundlesGroups && this.props.bundlesGroups.map(bundle => (
                                        <Checkbox value={bundle.matgrouP_CODE} checked={this.props.selectedGroup === bundle.matgrouP_CODE} onClick={() => this.props.handleRedirect(bundle)}>{bundle.description}</Checkbox>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment >
        )
    }
}
// export default withRouter(Filter)

function mapStateToProps(state) {
    return {
        allFilters: state.bundles
    };
};

export default connect(mapStateToProps)(withRouter(Filter));