import React from 'react';
import { updateItemsInWishList, updateRecentViewedDetails , removeItemFromWishList} from '../../Actions/WishListActions';
import { connect } from 'react-redux';
import { HeartFilled, HeartOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Tooltip } from 'antd';
import QuickView from '../../Screens/FurnitureListing/QuickView/QuickView';
import ModalBox from '../ModalBox/ModalBox';
import Text from 'antd/lib/typography/Text';

class FurnitureItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quickView: false,
            quickViewProduct: undefined,
        }
    }
    onCancel = () => {
        this.setState({ quickView: false, quickViewProduct: undefined });
    }
    handleOpenQuickView = (item) => {
        this.setState({ quickView: true, quickViewProduct: item })
        let model = {
            "matCode": item.maT_CODE
        }
        if (this.props.context) {
            this.props.dispatch(updateRecentViewedDetails(model));
        }
    }

    handleDeleteItem = (item) => {
        const selectedLocation = this.props.userLocation ? this.props.userLocation.ziP_CODE : 0
        this.props.dispatch(removeItemFromWishList(item, selectedLocation))
    }


    render() {
        const { item, wishListItems , isDelete } = this.props;
        const wishList = wishListItems.map(item => item.maT_CODE);
        return <div className="furniture-grid">
            <div className="details">
                <div style={{ display : 'flex', justifyContent : 'flex-end' }}>
                    <Link className="favourite" onClick={() => this.handleDeleteItem(item.maT_CODE)}>
                    {isDelete ? <Tooltip title={"Remove from wishlist"} arrow><DeleteOutlined style={{ color: '#522e6c' }} /></Tooltip> : ""}
                    </Link>
                </div>
                <div className="image">
                    <img src={item.thumbnailImagePath ? item.thumbnailImagePath : item.imagePath} alt="" />
                    <Link className="favourite" onClick={() => this.props.dispatch(updateItemsInWishList({ "matCode": item.maT_CODE }))}>
                        {wishList.includes(item.maT_CODE) ? <HeartFilled style={{ color: '#522e6c' }} /> : <HeartOutlined style={{ color: '#ccc' }} />}
                    </Link>
                </div>
                <h4 title={item.maT_DESC}>{item.maT_DESC}</h4>
                <div className="other-details">
                    <Row>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <div className="rent">
                                <span className="title">Rent</span>
                                {/* { item.originalmonthlyrent !== 0 && <Text delete><span className="price">$ {item.originalmonthlyrent}/mon</span></Text>} */}
                                <span className={item.originalmonthlyrent ? 'sale-price' : 'price'}>$&nbsp;{item.monthlyrent}/mon</span>
                            </div>
                        </Col>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            {
                                !item.showavailabilty &&
                                <div className="shipping">
                                    <span style={{ color: 'red' }}>Out of stock</span>
                                </div>
                                // <div className="shipping">
                                //     <span>ships in {item.shiP_IN_DAYS} days</span>
                                // </div>
                            }
                        </Col>
                    </Row>
                    <div className="quick-view">
                        {/* <Button type="secondary" onClick={() => this.setState({ quickView: true, quickViewProduct: item })} >Quick View</Button> */}
                        <Button type="secondary" onClick={() => this.handleOpenQuickView(item)} >Quick View</Button>
                    </div>
                </div>
            </div>
            {/* Quick view modal box */}
            <ModalBox
                visible={this.state.quickView}
                width={854}
                wrapClassName="main-search-area"
                onCancel={this.onCancel}
                footer={null}
                destroyOnClose>
                <QuickView product={this.state.quickViewProduct} handleClose={this.onCancel} />
            </ModalBox>
            {/* Quick view modal box ends */}
        </div>
    }
}
function mapStateToProps(state) {
    return {
        wishListItems: state.wishlist.wishListItems,
        context: state.login.context,
        userLocation: state.users.userLocation,
    }
}
export default connect(mapStateToProps)(FurnitureItem)