import { combineReducers } from 'redux';
import users from './UserReducer';
import login from './LoginReducer';
import furniture from './FurnitureReducer';
import cart from './CartReducer';
import utils from './UtilsReducer'
import orders from './OrderReducer'
import bundles from './BundlesReducer';
import wishlist from './WishListReducer';

export default combineReducers({
  users,
  login,
  furniture,
  cart,
  utils,
  orders,
  bundles,
  wishlist
});
