import { Col, Row, Divider } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class OrderItemView extends React.Component {
    showProduct = () => {
        if (isNaN(this.props.product.maT_CODE)) {
            this.props.history.push(`/bundles-detailed-page/${this.props.product.maT_CODE}/${this.props.product.mat_Desc}`)
        } else {
            this.props.history.push(`/furniture-detailed-page/${this.props.product.maT_CODE}/${this.props.product.mat_Desc}`)
        }
    }
    render() {
        return (
            <React.Fragment>
               
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                        {/* <h2>{this.props.product.mat_Desc}</h2> */}
                    </Col>
                </Row>
                <div className="orders-containers">
                    <div className="ordered-items">
                        <Row gutter={[20, 20]}>
                            
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <Link onClick={this.showProduct}>
                                    <img src={this.props.product.imagePath} width="180" height="200" alt={this.props.product.productDesc} />
                                </Link>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 14 }} lg={{ span: 14 }}>
                                <Link onClick={this.showProduct}>
                                    <h2>{this.props.product.mat_Desc}</h2>
                                </Link>
                                <p>{this.props.product.remark1}</p>
                                <div className="specfications">
                                    <div className="rent-price">
                                        <h5>Rent</h5>
                                        {/* <span>${this.props.product.monthlyrent + 12 }/mon</span> */}
                                        <span>${this.props.product.price}/mon</span>
                                    </div>
                                    {/* <div className="color">
                                        <h5>Color</h5>
                                        <span>{this.props.product.remark3}</span>
                                    </div> */}
                                    {/* <div className="room">
                                        <h5>Room</h5>
                                        <span>{this.props.product.room}</span>
                                    </div> */}
                                    <div className="quantity">
                                        <h5>Quantity</h5>
                                        <span>{this.props.product.qty}</span>
                                    </div>
                                    <div className="room">
                                        <h5>Category</h5>
                                        <span>{this.props.product.matgrouP_DESC}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* list ends here */}
                </div>
            </React.Fragment >
        )
    }
}
export default connect()(withRouter(OrderItemView))