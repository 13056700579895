import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Row, Col, Spin } from "antd";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

class FAQs extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners furniture">
          <div className="container mx-auto">
            <h1>Privacy Policy</h1>
          </div>
        </section>
        {/* page banners */}

        <section className="inner-pages top-right-wave">
          <div
            className="container mx-auto"
            style={{
              border: "1px solid #ccc",
              background: "white",
              padding: "2%",
            }}
          >
            <h3>1. Categories of Personal Information We Collect</h3>
            <p>
              We collect and maintain different types of personal information
              that you and/or your employer provide to us on a voluntary basis.
              This may include:
              <ul>
                <li>
                  Personal information such as given name(s), preferred name(s),
                  gender, and dates such as birthday.
                </li>
                <li>
                  Contact information such as address, telephone/mobile number,
                  email address.
                </li>
                <li>
                  Employment information such as role/title, name of current
                  employer, occupation, work address, work telephone, work email
                  address, fax number.
                </li>
                <li>
                  Product and Service-related information concerning the
                  Products and Services that we provide to you or programs that
                  we offer.
                </li>
                <li>
                  Location information for the purpose of locating a place that
                  you may be searching for in your area.
                </li>
                <li>
                  Technical identifiers that may include internet protocol (IP)
                  addresses, browser type, internet service provider (ISP),
                  referring/exit pages, the files viewed on our site (e.g., HTML
                  pages, graphics, etc.), operating system, date/time stamp,
                  and/or clickstream data to analyze trends in the aggregate and
                  administer the site.
                </li>
                <li>
                  For parties with whom we do business, credit, and financial
                  information such as your payment methods and preferences, and
                  billing and credit history, where applicable.
                </li>
                <li>
                  Business relationship information, including information
                  related to your agreements, preferences, advisors, and
                  decision-makers, feed-back and information requested by or
                  provided to you.
                </li>
              </ul>
            </p>
            <p>
              If you email us a question or request, we may use your email
              address to process your request and respond to your question.
            </p>
            <p>
              If you reach out to us via phone, we may record the call for
              internal purposes only, and will collect your name, email address,
              phone number, and any information necessary to provide customer
              service, potentially including your employer and or payment
              information.
            </p>
            <h3>2. When and Where We Collect Personal Information</h3>
            <p>
              Directly from you or your employer to provide requested services.
            </p>
            <p>
              When you complete webforms on Staging Depot Websites to request
              additional information about Staging Depot’s products and
              services.
            </p>
            <p>When you call our Customer Service.</p>
            <p>
              When you visit a furniture rental showroom or furniture outlet and
              sign up for text messages or email communications.
            </p>
            <p>
              When you visit our Websites, including your interactions with our
              services, we collect things like URL clickstreams (the path you
              take through our site), products/services viewed, errors, how long
              you stay on our pages, what you do on those pages, how often, and
              other actions you may take.
            </p>
            <p>
              We use cookies or similar technologies to analyze trends,
              administer the Website, track users’ movements around the Website,
              and to gather demographic information about our user base as a
              whole. We use cookies to identify you as a user of the Website, to
              permit your access to the Website Services and to monitor your use
              of the Website. Cookies are text files, stored in your Web
              browser, that contain information about you relative to your use
              of the Website. If you configure your Web browser to disable or
              block cookies, you will be unable to use certain Website Services.
            </p>
            <p>
              You can opt-out of this interest-based advertising at any time –
              to do so: click here. Please note you may continue to receive
              generic ads.
            </p>
            <p>
              We may receive your information from other sources, such as from
              referrals or from third parties. If you believe that one of your
              contacts has provided us with your personal information and you
              would like to request not to be contacted or to be removed from
              our database, please contact us a Privacy@stagingdepot.com and
              include the Staging Depot domain at issue.
            </p>
            <h3>3. How & Why We Use Personal Information</h3>
            <p>
              Data protection law means that we can use your data for certain
              reasons and where we have a legal basis to do so. Here are the
              reasons for which we process your data:
              <ul>
                <li>
                  Delivering Services: Performing our contractual obligations to
                  provide Staging Depot Services to you. Legal basis: Contract
                </li>
                <li>
                  Customer Support: Responding to questions and solving issues
                  via phone or email. Legal basis for this data usage: Contract
                </li>
                <li>
                  Marketing Purposes: Sending you emails and messages about new
                  features, products and services, offers and content we think
                  you may be interested in. Legal basis for this data usage:
                  Legitimate Interest or Consent; with the ability to easily
                  unsubscribe
                </li>
                <li>
                  Running & Improving our Website and Services: Managing your
                  requests, login, and authentication, remembering your
                  settings, hosting and back-end infrastructure, testing
                  features, managing landing pages, and identifying fraud. Legal
                  basis: Contract or Legitimate Interests
                </li>
                <li>
                  The Lessee may terminate this Agreement if the Lessee provides
                  the Lessor with written notice of termination at least 3 to 4
                  business days prior to the Delivery Date. If the Lessee
                  terminates this Agreement in accordance with this subsection
                  (b), then the Lessee shall be charged 20% of the Rental Amount
                  for the Initial Term plus applicable taxes.
                </li>
                <li>
                  The Lessee may terminate this Agreement if the Lessee provides
                  the Lessor with written notice of termination at least 1 to 2
                  business days prior to the Delivery Date. If the Lessee
                  terminates this Agreement in accordance with this subsection
                  (c), then the Lessee shall be charged 50% of the Rental Amount
                  for the Initial Term plus applicable taxes.
                </li>
                <li>
                  The Lessee shall not be entitled to any refund, discount, or
                  abatement of any sort if the Lessee terminates this Agreement
                  prior to the completion of the Initial Term or any Renewal
                  Term unless the Lessor in its sole discretion agrees
                  otherwise.
                </li>
              </ul>
            </p>
            <h4>What does each legal basis mean?</h4>
            <p>
              <p>
                <strong>Contract:</strong> Processing your data is necessary
                when fulfilling a product or service transaction/contract, you
                have requests or because we need specific information before
                entering a transaction/contract.
              </p>
              <p>
                <strong>Consent:</strong> You have given clear consent for us to
                process your personal data for a specific purpose. You can
                choose to withdraw your consent anytime using specific
                unsubscribe/op-out tools provided to enable you to withdraw
                consent, like an email unsubscribe link.
              </p>
              <p>
                <strong>Legitimate interests:</strong> Processing your data is
                necessary for our legitimate interests or the legitimate
                interests of a third party, provided those interests are not
                outweighed by your rights and interests. These legitimate
                interests are:
                <ul>
                  <li>
                    Gaining insights from your behavior on our websites and
                    within our services sites so we can better serve you.
                  </li>
                  <li>
                    Delivering, developing, and improving the Staging Depot
                    services.
                  </li>
                  <li>
                    Enabling us to enhance, customize or modify our services and
                    marketing communications based on your profile and interests
                  </li>
                  <li>Determining whether marketing campaigns are effective</li>
                  <li>Enhancing data security</li>
                </ul>
                In each case, these legitimate interests are only valid if they
                are not outweighed by your rights and interests
              </p>
            </p>
            <h3>
              4. How We Share Personal Information & Categories of Companies We
              Share Personal Information With
            </h3>
            <p>
              We may share your information with third parties who provide
              Services on our behalf to help with our business activities. These
              companies are authorized to use your personal information only as
              necessary to provide these Services to us and use measures to
              protect the confidentiality and security of your personal
              information.
            </p>
            <p>
              These services may include:
              <ul>
                <li>
                  Providing our relocation services, fulfilling orders, and
                  delivering packages
                </li>
                <li>Payment processing</li>
                <li>Providing customer service</li>
                <li>Sending marketing communications</li>
                <li>Conducting research and analysis</li>
              </ul>
              We may share your information with third-party business partners,
              for instance, for the purpose of enhancing our Products and
              Services. If you do not want us to share your personal information
              with these companies, contact us at{" "}
              <a href="mailto:privacy@stagingdepot.com">
                Privacy@stagingdepot.com
              </a>{" "}
              and include the Staging Depot domain at issue.
            </p>
            <p>
              We may share your geo-location data with third parties for the
              purpose of them serving you ads for places (such as restaurants)
              in your area. If you do not wish to allow us to share your
              information in this manner, please opt-out by contacting us at{" "}
              <a href="mailto:nocontact@stagingdepot.com">
                nocontact@stagingdepot.com
              </a>{" "}
              and include the Staging Depot domain at issue.
            </p>
            <p>
              We may disclose your information in response to a subpoena or
              similar investigative demand, a court order, or a request for
              cooperation from law enforcement or other government agency; to
              establish or exercise our legal rights; to defend against legal
              claims; in connection with a substantial corporate transaction,
              such as the possible sale of our business, a divestiture, merger,
              consolidation, or asset sale, or in the unlikely event of
              bankruptcy; or as otherwise required by law.
            </p>
            <h3>Removal from Staging Depot's Marketing Email Communication</h3>
            <p>
              Should you wish to stop receiving emails from Staging Depot
              Business Services, you may remove yourself from receiving
              marketing email communications by emailing{" "}
              <a href="mailto:nocontact@stagingdepot.com">
                nocontact@stagingdepot.com
              </a>
            </p>
            <p>
              You may also contact us in the following ways:
              <ul>
                <li>Phone: 855-55DEPOT (553-3768)</li>
                <li>Mail: PO Box 782, Rockwell, NC 28138</li>
              </ul>
            </p>
            <h3>6. Security</h3>
            <p>
              We use industry-standard security methods such as firewalls,
              encryption, and system access controls. However, as no computer or
              network-based product exists that can provide "perfect security",
              we cannot guarantee that the steps we have taken to secure your
              information will prevent our systems from being compromised and
              your information from being disclosed.
            </p>
            <p>
              We may disclose your information in response to a subpoena or
              similar investigative demand, a court order, or a request for
              cooperation from law enforcement or other government agency; to
              establish or exercise our legal rights; to defend against legal
              claims; in connection with a substantial corporate transaction,
              such as the possible sale of our business, a divestiture, merger,
              consolidation, or asset sale, or in the unlikely event of
              bankruptcy; or as otherwise required by law.
            </p>
            <h3>
              5. Removal from Staging Depot's Marketing Email Communication
            </h3>
            <p>
              Should you wish to stop receiving emails from Staging Depot
              Business Services, you may remove yourself from receiving
              marketing email communications by emailing
              <a href="mailto:nocontact@stagingdepot.com">
                nocontact@stagingdepot.com
              </a>
            </p>
            <p>
              You may also contact us in the following ways:
              <ul>
                <li>Phone: 855-55DEPOT (553-3768)</li>
                <li>Mail: PO Box 782, Rockwell, NC 28138</li>
              </ul>
            </p>
            <h3>6. Security</h3>
            <p>
              We use industry-standard security methods such as firewalls,
              encryption, and system access controls. However, as no computer or
              network-based product exists that can provide "perfect security",
              we cannot guarantee that the steps we have taken to secure your
              information will prevent our systems from being compromised and
              your information from being disclosed.
            </p>
            <p>
              If you have any questions about the security of your personal
              information, you can contact us at{" "}
              <a href="mailto:Privacy@stagingdepot.com">
                Privacy@stagingdepot.com
              </a>
              . Please note our reply or any requests for additional information
              will come from{" "}
              <a href="mailto:Privacy@stagingdepot.com">
                Privacy@stagingdepot.com
              </a>
              .
            </p>
            <h3>7. Modifying Information</h3>
            <p>
              Upon request Staging Depot will provide you with information about
              whether we hold any of your personal information. You may add,
              access, correct, or request deletion of your personal information
              by emailing{" "}
              <a href="mailto:Privacy@stagingdepot.com">
                Privacy@stagingdepot.com
              </a>{" "}
              and include the Staging Depot domain at issue. We will respond to
              your request within a reasonable timeframe. Please note our reply
              or any requests for additional information will come from
              Privacy@stagingdepot.com.
            </p>
            <p>
              In certain circumstances we may be required by law to retain your
              personal information or may need to retain your personal
              information to continue providing a service.
            </p>
            <h3>8. Links to Other Websites</h3>
            <p>
              The Website may contain links to Websites that are not affiliated
              with Staging Depot and that may or may not have similar practices
              in place to protect the privacy of information that you supply. We
              encourage you to review the privacy statements of each of the
              sites that are linked to or accessed from the Website so that you
              will be aware of how each visited site collects, uses and
              distributes such information.
            </p>
            <h3>9. GDPR Individual Rights</h3>
            <p>
              Please note: To protect your privacy, we may ask for additional
              information to verify your identity in order for us to respond to
              your request.
            </p>
            <p>
              Access: You have the right to request disclosure of the personal
              information we hold, and to receive additional details regarding
              the personal information the business collects and its use
              purposes, including any third parties with which it shares
              information. Much of this information is presented in the above
              sections of the Privacy Notice, as well.
            </p>
            <p>
              Delete: You have the right to request that we erase your personal
              information and for us to direct the same of any third-party
              service providers processing your data on our behalf.
            </p>
            <p>
              Portability: You have the right to request that any information
              electronically held be returned in a format permitting its
              transfer to another organization or service.
            </p>
            <p>
              Non-Discrimination: We will not discriminate against you for
              exercising any of your CCPA rights. Agent. You may designate an
              agent to submit privacy requests on your behalf, but for your
              protection, we may need to verify your identity directly with you
              before fulfilling certain requests. We need your agent’s contact
              information, and your agent needs to have your information ready
              when submitting the request.
            </p>
            <p>
              Financial Incentive Programs. We make various offers and financial
              incentives to our customers. The terms of any such offer or
              financial incentive will be presented to you at the time you
              receive our offer. The nature and value of any such offer or
              financial incentive may differ depending on what information we
              collect and use about you. We do not assign a monetary value to
              the data that we collect and strive to only use that information
              to further our business in accordance with our Privacy Policy; to
              the extent that we are required to assign a monetary value to your
              personal information, it is equal to the value of the discount or
              financial incentive that we have provided to you. You may withdraw
              from receiving any of our offers or financial incentives by
              contacting us by calling us at 855.55DEPOT, or by unsubscribing to
              our email marketing by clicking "Unsubscribe" link in any e-mail
              from us. See “5. Removal from Staging Depot's "Opt-in" Email List”
              in our Privacy Policy for more information.
            </p>
            <p>
              WE DO NOT SELL, DISCLOSE, SHARE OR TRANSFER DATA FOR THE
              COMMERCIAL BENEFIT OF ANY PARTY. PERSONAL DATA OR THE BENEFIT OF
              ITS USE IS NEVER PROVIDED IN COMPENSATION FOR SERVICES.
            </p>
            <h3>10. Updates to the Policy</h3>
            <p>
              We may update the Privacy Policy at any time and amendments will
              be effective when posted. If we make any material changes, we will
              notify you by email (sent to the e-mail address specified in your
              account) or by means of a notice on this Website prior to the
              change becoming effective. You should therefore periodically visit
              this page to review the current Privacy Policy, so that you are
              aware of any such revisions.
            </p>
            <h3>11. Contact Us</h3>
            <p>
              If you have questions or concerns about this Privacy Policy,
              please contact us through the information below:
            </p>
            <p>
              Mail:
              <br />
              Staging Depot, LLC <br />
              PO Box 782 <br />
              Rockwell,NC 28138 <br />
              Phone: 1-855-55D-EPOT (3-3768) <br />
              Email:privacy@stagingdepot.com
            </p>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
// function mapStateToProps(state) {
//     return {
//         furnitureGroups: state.furniture.furnitureGroups
//     }
// }
export default withRouter(FAQs);
