import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './BreadCrumbs.scss';
class Breadcrump extends Component {

  render() {

    return (
      <React.Fragment>
        {/* staging-breadcrumb starts */}
        <div className="staging-breadcrumb">
          <div className="center-content">
            <div className="page-navigations">
              <span>
                {this.props.navigations.map(val => {
                  return <Link to={val.link}>{val.name}</Link>
                })}
              </span>
            </div>
          </div>
        </div>
        {/* staging-breadcrumb ends */}
      </React.Fragment >
    );
  }
}

export default withRouter(Breadcrump);
