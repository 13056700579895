import axios from 'axios';
import AppConfig from '../config';
import { LoginTypes } from './ActionType';
import { loadCartItems } from './CartActions';
import { getOpenOrderList } from './OrderActions';
import { setAuthorizationToken, getAxios } from './setAuthorizationToken';

const AUTH_BASE_URL = AppConfig.AUTH_BASE_URL;


export const getSocialLoginUrls = () => {
    return function(dispatch) {
        return axios.get(`${AUTH_BASE_URL}/Api/Auth/Login`)
            .then((res) => {
                if (res.data && Array.isArray(res.data)) {
                    dispatch({ type: LoginTypes.SET_SOCIAL_LOGIN_URLS, payload: res.data })
                } else {
                  dispatch({ type: LoginTypes.SET_SOCIAL_LOGIN_URLS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', error: true, message: 'Getting Login Details failed.' })
            });
    }
}

export const apiLogin = (provider, authEndpoint, idToken, profile) => {
    return function(dispatch) {
        return axios.post(`${AUTH_BASE_URL}/api/auth/${authEndpoint}?provider=${provider}&idToken=${idToken}`)
            .then((res) => {
                if (res.data && res.data.token) {
                    setAuthorizationToken(res.data.token)
                    dispatch({ type: LoginTypes.SET_USER_CONTEXT, profile, payload: res.data });
                    return true;
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', error: true, message: `${provider} login failed` });
                    return false;
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', error: true, message: err.message });
                return false;
            });
    }
}

export const userSignup = (userObj) => {
    return function(dispatch) {
        return axios.post(`${AUTH_BASE_URL}/Api/Auth/register-customer`, userObj)
            .then((res) => {
                if (res.data && res.data.error) {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: res.data.message } });
                    return false;
                } else if (res.data && res.data.userId) {
                    return res.data.userId;
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: "Request OTP failed." } });
                    return false;
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: err.message } });
                return false;
            });
    }
}

export const requestOTP = (phoneNumber) => {
    return function(dispatch) {
        return axios.get(`${AUTH_BASE_URL}/Api/Auth/otp-request?phoneNumber=${phoneNumber}`)
            .then((res) => {
                if (res.data && res.data.error) {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: res.data.message } });
                    return false;
                } else {
                    return true
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: err.message } });
                return false;
            });
    }
}

export const loginWithOTP = (phoneNumber, code) => {
    return function(dispatch) {
        return axios.post(`${AUTH_BASE_URL}/api/auth/otp-login?phoneNumber=${phoneNumber}&code=${code}`)
            .then((res) => {
                if (res.data && res.data.token) {
                    setAuthorizationToken(res.data.token);
                    if(window.location.hash.indexOf('order-history' >= 0)) {
                        window.location.reload();
                    } else {
                        dispatch(getOpenOrderList(true));
                    }
                    dispatch(loadCartItems(false));
                    dispatch({ type: LoginTypes.SET_USER_CONTEXT, profile: {}, payload: res.data });
                    return true;
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: `Invalid OTP.` } });
                    return false;
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: err.message } });
                return false;
            });
    }
}

export const verifyEmail = (model) => {
    return function(dispatch) {
        return axios.post(`${AUTH_BASE_URL}/api/Auth/check-email`,model)
        .then((res) => {
            if (res.data && res.data.error) {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: res.data.message } });
                return res.data;
            } 
            else {
                return res.data;
            }
        })
        .catch(err => {
            dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: err.message } });
            return false;
        })
    }
}

export const verifyMobile = (model) => {
    return function(dispatch) {
        return axios.post(`${AUTH_BASE_URL}/api/Auth/check-mobile`,model)
        .then((res) => {
            if(res.data && res.data.error){
                // dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: res.data.message } });
                return res.data;
            } else {
                return res.data;
            }
        })
        .catch(err => {
            dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: err.message } });
            return false;
        })
    }
}


export const checkUserEmail = (model) => {
    return function(dispatch) {
        return axios.post(`${AUTH_BASE_URL}/api/Auth/check-useremail`,model)
        .then((res) => {
            if(res.data && res.data.error){
                return res.data
            } else {
                return res.data
            }
        })
        .catch(err => {
            dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: err.message } })
            return false;
        })
    }
}
