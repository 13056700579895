import React, { useState } from 'react';
import { withRouter } from 'react-router';
import './Inspirations.scss';
import { connect } from 'react-redux';
import { GetInspirationListData } from '../../Actions/WishListActions';
import Annotation from 'react-image-annotation';
import { Button, Col, Row, Input, Modal, Tooltip } from "antd";
import {
    PointSelector
} from 'react-image-annotation/lib/selectors';
import ModalBox from "../../Components/ModalBox/ModalBox";
import { addItemToCart, addInspirationsToCart, addItemToTempCart } from '../../Actions/CartActions';
import { getFurnitureInfo } from '../../Actions/FurnitureActions';
import SelectBox from '../../Components/SelectBox/SelectBox';
import InputBox from '../../Components/InputBox/InputBox';

function renderContent({ annotation }) {
    const { geometry } = annotation;
    return (
        <div
            key={annotation.data.id}
            style={{
                background: 'white',
                color: 'black',
                padding: 8,
                position: 'absolute',
                left: `${geometry.x}%`,
                top: `${geometry.y + geometry.height + 2}%`,
                // width: '50%'
            }}
        >
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }}>
                    <img src={annotation.data && annotation.data.thumbnailImagePath} alt="Inspirations" style={{ height: 50, width: 50 }} />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 24 }}>
                    <div style={{ color: '#A14BCC', fontSize: 12 }}>{annotation.data && annotation.data.text}</div>
                    <div style={{ fontWeight: 'bold', fontSize: 13 }}>$ {annotation.data && annotation.data.monthlyrent}/month</div>
                </Col>
            </Row>
        </div>
    )
};
class Inspirations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inspirationItems: [],
            isHover: null,
            imageId: null,
            selectedData: null,
            showModal: false,
            showQuantityModal: false,
            showRoomModal: false,
            tempProductId: null,
            productQuantity: 1,
            tempId: null,
            activeAnnotations: [],
            room: '',
            newroom: '',
            quantity: 1
        };
    };

    nextPath(path) {
        this.props.history.push(path);
    };

    componentDidMount() {
        this.props.dispatch(GetInspirationListData());
    };

    getProductDetails = (id, name) => {
        window.open(`/#/furniture-detailed-page/${id}/${name}`);
    };

    onMouseOver = (id) => e => {
        this.setState({
            activeAnnotations: [
                ...this.state.activeAnnotations,
                id
            ]
        })
    };

    onMouseOut = (id) => e => {
        const index = this.state.activeAnnotations.indexOf(id)

        this.setState({
            activeAnnotations: [
                ...this.state.activeAnnotations.slice(0, index),
                ...this.state.activeAnnotations.slice(index + 1)
            ]
        })
    };
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    activeAnnotationComparator = (a, b) => {
        return a.data.id === b
    };

    openToCardInspirationItem = (id) => {
        this.setState({ showQuantityModal: true, tempProductId: id });
    };
    openToCardInspirationItems = () => {
        this.setState({ showRoomModal: true });
    };

    addToCardInspirationItem = () => {
        const model = {
            "matCode": this.state.tempProductId,
            "quantity": this.state.productQuantity,
            "room": this.state.room === 'Neeeew' ? this.state.newroom : this.state.room

        };
        this.setState({ productQuantity: 1, showQuantityModal: false });
        if (this.props.context) {
            this.props.dispatch(addItemToCart(model, true));
        } else {
            this.props.dispatch(addItemToTempCart(this.state.inspirationItems[this.state.tempProductId], model.quantity, "Inspirations"));
        }
    };
    addRoomToInspirationsCart = () => {
        this.openToCardInspirationItems();
    }
    addAllInspirationsToCart = () => {
        const quantities = {};
        this.state.selectedData && this.state.selectedData.tags.map(function (obj) {
            if (quantities[obj.data.maT_CODE]) {
                quantities[obj.data.maT_CODE] += 1;
            } else {
                quantities[obj.data.maT_CODE] = 1;
            }
        });
        const result = Object.keys(quantities).map(key => {
            return { matCode: key, quantity: quantities[key], room: this.state.room === 'Neeeew' ? this.state.newroom : this.state.room };
        });
        this.setState({ showRoomModal: false });
        if (this.props.context) {
            let model = {
                "inspirationProducts": result

            };
            this.props.dispatch(addInspirationsToCart(model));
        } else {
            result.forEach(item => {
                this.props.dispatch(addItemToTempCart(this.state.inspirationItems[item.matCode], item.quantity, item.room));
            })
        }
        this.setState({ showModal: false })
    };

    handleQuickView = (m) => {
        let matCodes = [];
        if (m && m.tags) {
            matCodes = m.tags.map(tag => tag.data.maT_CODE);
        }
        getFurnitureInfo(matCodes.join(',')).then(items => {
            const inspirationItems = {};
            items && items.forEach(item => inspirationItems[item.maT_CODE] = item);
            this.setState({ selectedData: m, inspirationItems, showModal: true })
        })
    }

    render() {

        const { inspirationsList } = this.props;
        const { isHover, imageId, selectedData, showModal, showQuantityModal, showRoomModal, tempProductId, productQuantity } = this.state;

        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners inspirations">
                    <div className="container mx-auto">
                        <h1>Inspirations</h1>
                        <p>Click on Quick View to get an itemized list of furniture, which you can add to cart.</p>
                    </div>
                </section>
                {/* page banners */}

                <section className="furniture-inspirations">
                    <div className="container mx-auto">
                        <div className="inspirations-list">
                            {inspirationsList.length !== 0 ?
                                inspirationsList && inspirationsList.map((m, i) => (
                                    <div className="items" onMouseOver={() => this.setState({ isHover: i, imageId: m.imageId })} onMouseLeave={() => this.setState({ isHover: null, imageId: null })}>
                                        <div className="details">
                                            <div className="image">
                                                <img src={m.thumbNailPath} style={{ height: 360 }} />

                                                {isHover === i ? <>
                                                    <Button size="sm" variant="primary" onClick={() => this.handleQuickView(m)} style={{ position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Quick View</Button>
                                                </> : ''}
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : <h3 style={{ fontWeight: 500 }}>No Inspirations Available!</h3>}
                        </div>
                    </div>
                </section>

                <ModalBox title="Inspirations Details" style={{ top: '20px' }} width={1200} destroyOnClose footer={null} visible={showModal} onOk={() => this.setState({ showModal: false })} onCancel={() => this.setState({ showModal: false })}>
                    <Row className="inspirations-modal">
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }}>
                            <div className="product-list-container">
                                {/* {selectedData && selectedData.tags.map(m => <p className="product-text"><span style={{ cursor: 'pointer' }} key={m.data.id} onMouseOut={this.onMouseOut(m.data.id)} onMouseOver={this.onMouseOver(m.data.id)} onClick={() => this.getProductDetails(m.data.maT_CODE, m.data.text)}>{m.data.text}</span> <span onClick={() => this.openToCardInspirationItem(m.data.maT_CODE)} className="add-to-cart"><Tooltip placement="right" title='Add to cart'><i className="flaticon-shopping-cart" /></Tooltip></span></p>)} */}
                                {selectedData && selectedData.tags.map(m => <p className="product-text" onClick={() => this.openToCardInspirationItem(m.data.maT_CODE)}><span style={{ cursor: 'pointer' }} key={m.data.id} onMouseOut={this.onMouseOut(m.data.id)} onMouseOver={this.onMouseOver(m.data.id)} >{m.data.text}</span> <span onClick={() => this.openToCardInspirationItem(m.data.maT_CODE)} className="add-to-cart"><Tooltip placement="right" title='Add to cart'><i className="flaticon-shopping-cart" /></Tooltip></span></p>)}
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 18 }}>
                            <Button type="primary" onClick={this.addRoomToInspirationsCart} className="add-inspiration"><i className="flaticon-shopping-cart" /> Add Inspiration To Cart</Button>
                            <Annotation
                                src={selectedData && selectedData.imagePath}
                                alt='Invalid image'
                                className="annotation-image"
                                annotations={selectedData && selectedData.tags}
                                type={PointSelector.TYPE}
                                disableSelector={true}
                                disableAnnotation={true}
                                disableEditor={true}
                                disableOverlay={true}
                                renderContent={renderContent}
                                activeAnnotationComparator={this.activeAnnotationComparator}
                                activeAnnotations={this.state.activeAnnotations}
                            />
                        </Col>
                    </Row>
                </ModalBox>

                <ModalBox title="Select Quantity" width={350} destroyOnClose visible={showQuantityModal} onOk={this.addToCardInspirationItem} onCancel={() => this.setState({ showQuantityModal: false, productQuantity: 1 })}>
                    <Input type='number' value={productQuantity} onChange={(e) => this.setState({ productQuantity: e.target.value })} />
                    <div className="list">
                        <SelectBox
                            showSearch={false}
                            label="Room"
                            id="room"
                            placeholder={"Choose"}
                            value={this.state.room}
                            options={this.props.cartRooms.map(room => ({ label: room, value: room })).concat([{ label: 'Add New Room', value: 'Neeeew' }])}
                            onChangeText={this.onChangeText}
                            optional="true"
                        />
                    </div>
                    {
                        this.state.room === 'Neeeew' &&
                        <div className="select-room">
                            <div className="form-fields">
                                <InputBox
                                    label="New Room name"
                                    id="newroom"
                                    value={this.state.newroom}
                                    onChangeText={this.onChangeText}
                                />
                            </div>
                        </div>
                    }
                </ModalBox>
                <ModalBox title="Select Room" width={350} destroyOnClose visible={showRoomModal} onOk={this.addAllInspirationsToCart} onCancel={() => this.setState({ showRoomModal: false, productQuantity: 1 })}>
                    <div className="list">
                        <SelectBox
                            showSearch={false}
                            label="Room"
                            id="room"
                            placeholder={"Choose"}
                            value={this.state.room}
                            options={this.props.cartRooms.map(room => ({ label: room, value: room })).concat([{ label: 'Add New Room', value: 'Neeeew' }])}
                            onChangeText={this.onChangeText}
                            optional="true"
                        />
                    </div>
                    {
                        this.state.room === 'Neeeew' &&
                        <div className="select-room">
                            <div className="form-fields">
                                <InputBox
                                    label="New Room name"
                                    id="newroom"
                                    value={this.state.newroom}
                                    onChangeText={this.onChangeText}
                                />
                            </div>
                        </div>
                    }
                </ModalBox>

            </React.Fragment>
        )
    }
};

function mapStateToProps(state) {
    return {
        inspirationsList: state.wishlist.inspirationsList,
        context: state.login.context,
        cartRooms: state.cart.cartRooms
    }
};

export default connect(mapStateToProps)(withRouter(Inspirations));
