import React from 'react';
import { withRouter } from 'react-router';
import Slider from "react-slick";
import './SimilarProducts.scss';

const SimilarProductssettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                centerMode: true,
                slidesToShow: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                centerMode: true,
                slidesToShow: 1
            }
        }
    ]
};

class SimilarProducts extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="similar-products">
                    <Slider {...SimilarProductssettings}>

                        <div className="item">
                            <img src={require('../../../assets/images/bundles/similar-products/product-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/similar-products/product-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/similar-products/product-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/similar-products/product-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/similar-products/product-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/similar-products/product-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/similar-products/product-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/similar-products/product-1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/bundles/similar-products/product-1.webp')} alt="" />
                        </div>

                    </Slider>
                </div>
            </React.Fragment>

        )
    }
}
export default withRouter(SimilarProducts)