import { Button, Col, Row, Select } from "antd";
import React from "react";
import { connect } from 'react-redux';
import Annotation from 'react-image-annotation';
import {
  PointSelector
} from 'react-image-annotation/lib/selectors';
import { withRouter } from "react-router";
import ModalBox from "../../Components/ModalBox/ModalBox";
import SelectBox from "../../Components/SelectBox/SelectBox";
import { getInspirationMaterialLIst, saveImageAnnotation, saveImage } from '../../Actions/WishListActions';
import "./Inspirations.scss";

function renderContent({ annotation }) {
  const { geometry } = annotation;
  return (
    <div
      key={annotation.data.id}
      style={{
        background: 'white',
        color: 'black',
        padding: 8,
        position: 'absolute',
        left: `${geometry.x}%`,
        top: `${geometry.y + geometry.height + 2}%`,
        width: '20%'
      }}
    >
      <Row>
        <Col span={10}>
          <img src={annotation.data && annotation.data.image} alt="Inspirations" style={{ height: 50, width: 50 }} />
        </Col>
        <Col span={14}>
          <div style={{ color: '#A14BCC', fontSize: 12 }}>{annotation.data && annotation.data.text}</div>
          <div style={{ fontWeight: 'bold', fontSize: 13 }}>$ {annotation.data && annotation.data.rent}/month</div>
        </Col>
      </Row>
    </div>
  )
};
class CreateInspirations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: undefined,
      arearsMap: { name: "generated", areas: [] },
      annotations: [],
      annotation: {},
      modelData: [],
      showPopup: false,
      saveConfirmation: false,
      activeAnnotations: []
    };
  };

  nextPath(path) {
    this.props.history.push(path);
  }
  onChange = (annotation) => {
    this.setState({ annotation })
    this.setState({ showPopup: true, product: false });
  };

  componentDidMount() {
    this.props.dispatch(getInspirationMaterialLIst());
  };

  onSubmit = (annotation) => {
    let getId = this.state.modelData.slice(-1).pop();
    const { geometry, data } = annotation;
    let parsedData = JSON.parse(data.text);

    this.setState({
      // annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        id: getId === undefined ? 1 : (getId.id + 1),
        data: {
          // ...data,
          text: parsedData.maT_DESC,
          rent: parsedData.monthlyrent,
          image: parsedData.thumbnailImagePath,
          id: getId === undefined ? 1 : (getId.id + 1)
        }
      })
    })

    // let getId = this.state.modelData.slice(-1).pop();

    let dt = { id: getId === undefined ? 1 : (getId.id + 1), x: geometry.x, y: geometry.y, productId: this.state.product };

    this.setState({
      modelData: [...this.state.modelData, dt]
    });
    this.setState({ showPopup: false });
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleFileRead = async (event) => {
    let formData = new FormData();
    formData.append("File", event.target.files[0]);

    if (event.target.files[0] && event.target.files[0].type.includes("image")) {
      this.props.dispatch(saveImage(formData));
      const file = event.target.files[0];
      const imageUrl = await this.convertBase64(file);
      this.setState({ imageUrl });
    }
    else {
      this.props.dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Selected wrong format.' } });
    }
  };

  removeSelection = (id) => {
    let data = this.state.modelData.filter(f => f.id !== id);
    let ann = this.state.annotations.filter(a => a.id !== id);

    this.setState({
      modelData: data,
      annotations: ann
    });

  };

  onCancel = () => {
    this.setState({ showPopup: false })
  };

  onMouseOver = (id) => e => {
    this.setState({
      activeAnnotations: [
        ...this.state.activeAnnotations,
        id
      ]
    })
  };

  onMouseOut = (id) => e => {
    const index = this.state.activeAnnotations.indexOf(id)

    this.setState({
      activeAnnotations: [
        ...this.state.activeAnnotations.slice(0, index),
        ...this.state.activeAnnotations.slice(index + 1)
      ]
    })
  };

  activeAnnotationComparator = (a, b) => {
    return a.data.id === b
  };

  saveImage = () => {
    // let result = this.state.modelData.map(function (obj) {
    //   return { x: obj.x, y: obj.y, productId: obj.productId };
    // });
    let model = {
      imagedId: this.props.imageDetails.inspirationId,
      tags: this.state.modelData
    };
    this.props.dispatch(saveImageAnnotation(model));
    this.props.history.push('/inspirations');
  };

  getProductDetails = (id, name) => {
    this.props.history.push(`/furniture-detailed-page/${id}/${name}`);
  };

  openSaveConfirmation = () => {
    this.setState({ saveConfirmation: true });
  };

  render() {

    const { Option } = Select;
    let { inspirationsItems } = this.props;
    const { saveConfirmation } = this.state;
    inspirationsItems = inspirationsItems.sort((a,b) => (a.maT_DESC > b.maT_DESC) ? 1 : ((b.maT_DESC > a.maT_DESC) ? -1 : 0))
    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners inspirations">
          <div className="container mx-auto">
            <h1>Inspirations</h1>
          </div>
        </section>
        {/* page banners */}

        <section className="create-inspirations furniture-inspirations">
          <div className="container mx-auto">
          <Button size="sm" type="primary" className="inspiration-list-btn" onClick={() => this.props.history.push('/manage-inspirations')}><i class="fa fa-arrow-left" /> Inspirations List</Button>

            <div className="inspirations-list">
              <Row style={{ width: "100%" }}>
                <Col
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 24, order: 1 }}
                  lg={{ span: 24, order: 1 }}
                >
                  {/* <Row>
                    <input
                      id="originalFileName"
                      type="file"
                      required
                      label="Upload Image"
                      name="originalFileName"
                      onChange={(e) => this.handleFileRead(e)}
                    />
                  </Row> */}
                  <Row>
                    <Col
                      style={{ border: "1px solid #ccc" }}
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 10 }}
                      lg={{ span: 10 }}
                    >
                      <div style={{ padding: 10 }}>
                        <input
                          id="originalFileName"
                          type="file"
                          required
                          // accept="image/*"
                          label="Upload Image"
                          name="originalFileName"
                          onChange={(e) => this.handleFileRead(e)}
                          style={{ margin: 50 }}
                        />
                      </div>

                      <div className="create-product-list-container" style={this.state.modelData.length !== 0 ? { border: '1px solid #ccc', padding: 5, margin: 10 } : {}}>
                        {this.state.modelData.map(m => (
                          <p className="create-inspiration-products">{inspirationsItems.map(f => f.maT_CODE === m.productId ? <span key={m.id} onMouseOut={this.onMouseOut(m.id)} onMouseOver={this.onMouseOver(m.id)} onClick={() => this.getProductDetails(m.productId, f.maT_DESC)} style={{ cursor: 'pointer' }}>{f.maT_DESC}</span> : '')} <span onClick={() => this.removeSelection(m.id)} className="delete-inspiration-item">X</span></p>
                        ))}
                      </div>

                      {this.state.modelData.length !== 0 ?
                        <Button onClick={this.openSaveConfirmation} style={{ marginLeft: 10 }}>Save</Button>
                        : ''}
                    </Col>
                    <Col
                      style={{ border: "1px solid #ccc", padding: 10 }}
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 14 }}
                      lg={{ span: 14 }}
                    >
                      {/* {this.state.imageUrl && (
                        <ImageMapper
                          onClick={(area) => this.handleClick(area)}
                          onImageClick={evt => this.clickedOutside(evt)}
                          width={500}
                          imgWidth={500}
                          src={this.state.imageUrl}
                          map={this.state.arearsMap}
                        />
                      )} */}
                      {
                        this.state.imageUrl ?
                          <Annotation
                            src={this.state.imageUrl}
                            style={{ height: 500 }}
                            alt='Invalid image'
                            annotations={this.state.annotations}
                            type={PointSelector.TYPE}
                            disableSelector={false}
                            disableAnnotation={false}
                            renderContent={renderContent}
                            activeAnnotationComparator={this.activeAnnotationComparator}
                            activeAnnotations={this.state.activeAnnotations}
                            renderEditor={({ annotation: { geometry } }) => {
                              return <ModalBox
                                title="Select Product"
                                style={{ top: '20px' }}
                                width={1200}
                                height="700px"
                                onCancel={this.onCancel}
                                visible={this.state.showPopup}
                                wrapClassName="main-search-area"
                                footer={null}
                                destroyOnClose>
                              <div className="create-inspirations">
                               <SelectBox
                                    showSearch
                                    placeholder={"Choose"}
                                    value={this.state.product}
                                    options={inspirationsItems ? inspirationsItems.map(m => ({ label: m.maT_DESC, value: m.maT_CODE })) : []}
                                    onChangeText={(product) => this.setState({ product })}
                                    optional="true"
                                />
                                <div style={{ marginTop: 10 }}>
                                  <Button className="primary" onClick={this.onCancel}>Cancel</Button> &emsp;
                                  <Button className="primary" onClick={() => this.onSubmit({ geometry, data: { text: JSON.stringify(inspirationsItems.find(pr => pr.maT_CODE === this.state.product)) } })}>Save</Button>
                                </div>
                              </div>
                              </ModalBox>
                            }}
                            value={this.state.annotation}
                            onChange={this.onChange}
                          // onSubmit={this.onSubmit}
                          />
                          :
                          <img src={require('../../assets/images/no_preview.png')} alt="No Image Uploaded!" className="no-inspirations-content" />
                          // <h3 className="no-inspirations-content">No Image Uploaded!</h3>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </section>

        <ModalBox title="Confirmation" destroyOnClose visible={saveConfirmation} onOk={this.saveImage} onCancel={() => this.setState({ saveConfirmation: false })}>
          <p>Please confirm all annotations are correct! Do you want to save inspirations?</p>
        </ModalBox>

      </React.Fragment>
    );
  }
};

// export default withRouter(CreateInspirations);

function mapStateToProps(state) {
  return {
    inspirationsItems: state.wishlist.inspirationsItems,
    imageDetails: state.wishlist.imageDetails
  }
};

export default connect(mapStateToProps)(withRouter(CreateInspirations));
