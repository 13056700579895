import React from 'react';
import { withRouter } from 'react-router';
import { Button, Calendar, Divider } from 'antd';
import DateBox from '../../../Components/DateBox/DateBox';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import { getPickupDates } from '../../../Actions/OrderActions';

class Pickup extends React.Component {
    constructor() {
        super()
        this.state = {
            pickupDate: '',
            pickupTime : '',
            dates: []
        }
    }
    getFormattedDate = (date) => {
        const dateArr = date ? date.split('-') : ["1970","01","01"]
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
    }
    componentDidMount = async() => {
        const dates = await getPickupDates()
        if (dates) {
            this.setState({
                dates
            })
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    handleSave = () => {
        const selectedDate = this.state.dates.find(({ date }) => date === this.state.pickupDate)
        let selectedTime = selectedDate ? selectedDate.timings.find(time => time.id === this.state.pickupTime) : { slottime: '' };
        this.props.handleSave(this.getFormattedDate(this.state.pickupDate), this.state.pickupTime, selectedTime.slottime);
    }
    render() {
        return (
            <React.Fragment>
                <div className="staging-details">

                    <div className="calendar">
                    {
                        <div className="select-delivery-details">
                            <h5>Select Pickup Date</h5>
                            <div className="delivery-date-time">
                                {
                                    this.state.dates.map(({ date }) => 
                                    <div className="list" onClick={() => this.setState({ pickupDate: date })}>
                                        <div className={`date-time ${this.state.pickupDate === date ? 'selected' : ''}`}>{this.getFormattedDate(date)}</div>
                                    </div>
                                    )
                                }
                            </div>
                        </div> 
                    }
                    {
                        this.state.pickupDate  &&
                        <div className="select-delivery-details">
                            <h5>Select Time</h5>
                            <div className="delivery-date-time">
                                {
                                    this.state.dates.map(({ date, timings }) => 
                                        date === this.state.pickupDate && timings.map(slot => <div className="list" onClick={() => this.setState({ pickupTime: slot.id })}>
                                            <div className={`date-time ${this.state.pickupTime === slot.id ? 'selected' : ''}`}>{slot.slottime}</div>
                                        </div> )
                                    )
                                }
                            </div>
                        </div>
                    }
                    </div>
                    <Divider />    
                    <div className="action-buttpm text-right">
                        <Button 
                            type="primary" 
                            onClick={() => { 
                                this.handleSave();         
                            }} 
                            disabled={!this.state.pickupDate || !this.state.pickupTime}
                        >
                            Choose Payment Method
                        </Button>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(Pickup)