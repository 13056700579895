import React from 'react';
import { withRouter } from 'react-router';
import Slider from "react-slick";
import { Row, Col } from 'antd';
import './OffersSldier.scss';

const OffersSlidersettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};

class OffersSlider extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/* <!--Main Slider section starts--> */}
                <section className="offers-slider">
                    {/* <!--Slider Item starts--> */}
                    <Slider {...OffersSlidersettings}>

                        <div className="item">
                            <Row justify="center">
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                    <div className="offer-caption">
                                        <h3>NEW</h3>
                                        <h2>collection</h2>
                                        <h1>Sale</h1>
                                    </div>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 14 }} lg={{ span: 14 }}>
                                    <div className="offer-image">
                                        <img src={require('../../../assets/images/offers/offers.webp')} alt="" />
                                    </div>
                                </Col>
                            </Row>
                            <div className="promo-code">
                                <Row justify="center">
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <div className="code">Promocode : <span>STAGING50</span></div>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <div className="validity">Valid till - <span>31st July, 2020</span></div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {/* <!--Slider Item ends--> */}

                        <div className="item">
                            <Row justify="center">
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                    <div className="offer-caption">
                                        <h3>NEW</h3>
                                        <h2>collection</h2>
                                        <h1>Sale</h1>
                                    </div>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 14 }} lg={{ span: 14 }}>
                                    <div className="offer-image">
                                        <img src={require('../../../assets/images/offers/offers.webp')} alt="" />
                                    </div>
                                </Col>
                            </Row>
                            <div className="promo-code">
                                <Row justify="center">
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <div className="code">Promocode : <span>STAGING50</span></div>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <div className="validity">Valid till - <span>31st July, 2020</span></div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {/* <!--Slider Item ends--> */}

                    </Slider>
                </section>
            </React.Fragment>

        )
    }
}
export default withRouter(OffersSlider)