import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import './HomeSlider.scss';
import { Row, Col,Popover } from 'antd';

const Mainslidersettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};

class HomeSlider extends React.Component {

    onLinkClick = () => {
        document.getElementById('staging').scrollIntoView();
    };
    renderLearnMoreInfo = () => {
        return <div>
            <ul>
                <li>Use Staging Depot instead of getting into a mountain of debt buying your own inventory.  Avoid the bigger ongoing cost of storage space,insurance and the headaches of dealing with wear&nbsp;&&nbsp;tear and not knowing where things are, or what is available. Reduce the stress of trying to find & schedule reliable & trustworthy movers or having to get a rental truck.</li>
                <li>If you've already taken the plunge,no problem.Use us when you are short on inventory,or better yet, please consider to let us
                    manage your items and provide Staging Operations.</li>
            </ul>        
        </div>
    }
    renderStagingOperationsInfo = () =>{
        return <div>
            <ul>
            <li>Focus on growing your staging business rather than managing logistics of a furniture warehouse.</li>							
            <li>Simplify your life by letting us be your arms & legs handling the dirty work!</li>
            <li>We can store & manage your existing furniture to provide online inventory management.</li>
            <li>Plus, you can choose to monetize your existing assets by renting to others.</li>
            </ul>
        </div>
    }
    render() {
        return (
            <React.Fragment>
                {/* <!--Main Slider section starts--> */}
                <div className="stagingdepot-banner">
                    <div className="stagingdepot-banner-center">
                        <div className="slider-content">
                            {/* <!--Slider Item starts--> */}
                            <Slider {...Mainslidersettings}>

                                <div className="item curated-furnture">
                                    <Row justify="center">
                                        <Col span={24}>
                                            <div className="content">
                                                <div className="center-content">
                                                    <div className="slider-caption">
                                                        {/* <h2>Curated rental furniture to suit your needs...</h2>
                                                        <p>Beautiful designs. Great Prices. Quick & easy order process. We handle all the logistics.</p> */}
                                                        <h2>Curated rental furniture for staging vacant homes...</h2>
                                                        <p>Best Prices, Best Quality & Best Service</p>
                                                        <div className="action-buttons">
                                                        <Popover
                                                content={this.renderLearnMoreInfo}
                                                trigger="hover">
                                            <Link to="/furniture" className="btn btn-primary">Get Started</Link>
                                            </Popover>
                                                        </div>
                                                    </div>
                                                    <div className="positionBottom">
                                                        <div className="Btn-Align">
                                                            <Link to="/designer-services" className="btn btn-primary BtnWidth">Receiving & Delivery</Link>
                                                        </div>
                                                        <div className="Btn-Align">
                                                        <Popover
                                                content={this.renderStagingOperationsInfo}
                                                trigger="hover">
                                                <Link onClick={this.onLinkClick} className="btn btn-primary BtnWidth">Staging Operations</Link>
                                            </Popover>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {/* <!--Slider Item ends--> */}



                            </Slider>


                        </div>
                    </div>
                </div>
                {/* <!--Main Slider section ends--> */}
            </React.Fragment>

        )
    }
}
export default withRouter(HomeSlider)