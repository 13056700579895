import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import './GetRefund.scss';

class GetRefund extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="get-refund">
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                            <h4>Get $50 Refund on Flexible Scheduling</h4>
                            <p>We’d love to hear from you! Let us know what you think of our services and if we can be of any assistance.</p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                            <Link>Learn More</Link>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>

        )
    }
}
export default withRouter(GetRefund)