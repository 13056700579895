import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux'
import './BundlesListing.scss';
import { Row, Col, Spin } from 'antd';
import BreadCrumbs from '../../Components/BreadCrumbs/BreadCrumbs';
import Listings from './Listings/Listings';
import Filter from './Filter/Filter';
import moment from 'moment';
import { getBundleGroups, getBundleListing, getBundleSubGroups } from '../../Actions/BundleActions';

class BundlesListing extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            bundlesList: []
        }
    }
    componentDidMount() {
        this.setState({ loading: true });
        const bundlesCall = this.props.dispatch(getBundleListing());
        const subGroupsCall = this.props.dispatch(getBundleSubGroups());
        const groupsCall = this.props.dispatch(getBundleGroups())
        Promise.all([bundlesCall, subGroupsCall, groupsCall]).then(() => {
            this.setState({ loading: false, bundleList: this.props.bundlesList });
        });
    }
    render() {
        const { allFilters, customerInfo } = this.props;
        const userId = customerInfo && customerInfo.user_ID ? customerInfo.user_ID.toString() : undefined;
        let categorryType = this.props.bundlesList.filter(f => f.matgrouP_CODE === this.props.match.params.id);

        let data = [];
        let filteredData = categorryType.filter(f => moment(new Date(allFilters.availableDate)).isAfter(new Date(f.showavaldate)) || (f.showavailabilty === 1 && allFilters.productAvailable) || (allFilters.subGroups.includes(f.matgrouP_DESC)) || (f.monthlyrent <= allFilters.priceRange));

        data.push(...filteredData);

        data = (allFilters.subGroups.length !== 0 || allFilters.productAvailable || allFilters.priceRange !== null) ? filteredData : allFilters.availableDate !== '' && allFilters.availableDate !== null ? filteredData : categorryType
        data = data.filter(f => {
            if(allFilters.depotInventory && f.coop !== 'Y') {
                return true;
            } 
            if(allFilters.coopInventory && f.coop === 'Y') {
                return true;
            }
            if(allFilters.myInventory && userId && f.owneR_ID === userId) {
                return true;
            }
            return false;
        })
        return (
            <React.Fragment>
                {/* page banners */}
                <section className="page-banners bundles">
                    <div className="container mx-auto">
                        <h1>Bundles</h1>
                        <p>Whether you're looking for bespoke luxury furniture in an Art Deco style or a beautifully handcrafted modern sofa, with our tailored selection of designer furniture from the world’s leading designer brands, we have a style to suit every home interior.</p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-wave">
                    <div className="container mx-auto">

                        <BreadCrumbs {...this.props} navigations={[{ name: 'Home', link: '/' }, { name: 'Bundles', link: '/bundles' }, { name: 'Choose by Room Type', link: '/bundles' }, { name: this.props.match.params.name }]} />
                       
                        <Row gutter={[12, 20]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <Filter handleRedirect={({ matgrouP_CODE, description }) => this.props.history.push(`/bundles-listing/${matgrouP_CODE}/${description}`)} selectedGroup={this.props.match.params.id} bundlesGroups={this.props.bundlesGroups} />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 17 }} lg={{ span: 17 }}>
                                {
                                    this.state.loading && <Spin tip={"You are going to see Awesome furniture bundles. Please wait.."} />
                                }
                                {!this.state.loading && data.length === 0 && <div className="no-product-available">
                                    <Row gutter={[8, 8]}>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <h3>No bundles available!</h3>
                                            <span>Let us know if you'd like us to create any bundles for you.</span>
                                            {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p> */}
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <img src={require('../../assets/images/icons/box.svg')} alt="No bundles available" />
                                        </Col>
                                    </Row>
                                </div>}
                                {
                                    // this.props.bundlesList.map(bundle => 
                                    data.map(bundle => 
                                        <Listings item={bundle} />
                                    )
                                }
                            </Col>
                        </Row>
                    </div>
                </section>


            </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        bundlesList: state.bundles.bundlesList,
        allFilters: state.bundles,
        furnitureType: state.furniture.furnitureType,
        bundlesGroups: state.bundles.bundlesGroups,
        customerInfo: state.users.customerInfo
    }
}
export default connect(mapStateToProps)(withRouter(BundlesListing));